import axios from 'axios'

class Http {
	constructor() {
		// this.store=store
		this.axios = axios.create({
			// baseURL: 'http://localhost:3040/api/'
			baseURL: 'https://dev.finbro.tech/api/'
		})
	}

	test() {
		alert("test")
	}

	post(url, data, cb) {
		if (localStorage.token) {
			data.token = localStorage.token
		}
		this.axios.post(url, data)
			.then((res) => {
				cb(res.data)
			})
			.catch((error) => {
				console.log(error)
				if (error) {
					cb("error")
				}
			})
	}
	noTokenPost(url, data, cb) {
		this.axios.post(url, data)
			.then((res) => {
				cb(res.data)
			})
			.catch((error) => {
				console.log(error)
				if (error) {
					cb("error")
				}
			})
	}
}

export const http = Http