<template>
	<a-space :size="25">
		<a-statistic
				v-for="(type, key) in params" :key="key"
				valueStyle="font-size: 12px;"
				:title="type"
				:value="values[key]"/>
	</a-space>
</template>

<script>
export default {
	name: "InformText",
	props: ['params', 'values']
}
</script>

<style scoped>

</style>