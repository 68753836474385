<template>
  <a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
    <a-date-picker @change="changInput" v-model:value="valueInput" style="width: 100%" :style="styleDisplay"/>
  </a-form-item>

</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";
import dayjs from "dayjs";
import moment from 'moment';

export default {
  name: "input-date",
  props: [ 'value', 'name', 'id', 'display' ],
  mixins: [ ChangInputMixins ],
  data(){
    return {
      dateFormat: 'DD-MM-YYYY',
      valueInput: ''
    }
  },
  methods: {
    // changInput(){
    // 	this.$emit('changInput', this.valueInput)
    // }
  },
  mounted() {
      if (this.value!='') {
        let formatDate=moment(this.value).format('DD-MM-YYYY')
        this.valueInput = dayjs(formatDate, this.dateFormat)
      }
  },
}
</script>

<style scoped>

</style>