<template>
	<TitlePage title="Офферы" subtitle="" :border="true">
		<template v-slot:title_right>
			<a-button type="primary" @click="$router.push(`/offer/new-offer`)">
				Добавить оффер
			</a-button>
		</template>
		<FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
			category: true
		}" />
		<!--			<FiltersHeader :filter-value="filter" @changeFilter="changeFilter"/>-->
	</TitlePage>

	<a-table :columns="table.columns" :data-source="table.data" :loading="loading.table" :pagination="pagination"
		size="middle">
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'title'">
				{{ record.title }}
			</template>
			<!--			<template v-else-if="column.key === 'active'">-->
			<!--				<span>-->
			<!--					<a-tag-->
			<!--							:color="record.active ? 'green' : 'red'"-->
			<!--					>-->
			<!--						{{ record.active ? 'online' : 'offline' }}-->
			<!--					</a-tag>-->
			<!--				</span>-->
			<!--			</template>-->
			<template v-else-if="column.key === 'control'">
				<span>
					<a @click="$router.push(`/offer/${record.id}`)">Редактировать</a>
					<!--							<a-divider type="vertical"/>-->
					<!--							<a v-if="record.active === true" @click="stopOffers(record.id)">Остановить</a>-->
					<!--							<a v-if="record.active === false" @click="stopOffers(record.id)">Запустить</a>-->
					<a-divider type="vertical" />
					<!--							<a>Удалить</a>-->
					<a-popconfirm title="Удалить оффер?" @confirm="deleteOffers(record.id)">
						<a>Удалить</a>
					</a-popconfirm>
				</span>
			</template>
		</template>
	</a-table>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import FiltersHeader from "@/components/ui/FiltersHeader.vue";

export default {
	name: "OffersAdmin",
	components: {
		FiltersHeader,
		TitlePage
	},
	data() {
		this.$http.post("/offers/offerstypes",
			{},
			(data) => {
				let options = []
				data.forEach((item) => {
					options.push({
						// Тут сервер загружает оффера уже с названиями вместо id, поэтому и в фильтрах только названия
						value: item.value,
						title: item.value
					})
				})
				this.filter.category.options = options
			})
		return {
			pagination: {
				pageSize: 100
			},
			table: {
				columns: [
					{
						title: 'Название оффера',
						dataIndex: 'title',
						key: 'title',
					},
					// {
					// 	title: 'Активность',
					// 	dataIndex: 'active',
					// 	key: 'active',
					// },
					{
						title: 'Тип',
						dataIndex: 'type',
						key: 'type',
					}, {
						title: 'Цел. действие',
						dataIndex: 'action',
						key: 'action',
					}, {
						title: 'Кол. полей',
						dataIndex: 'fields',
						key: 'fields',
					}, {
						title: 'Управление',
						key: 'control',
					},
				],
				data: [],
			},
			typeOffers: [],
			filter: {
				category: {
					value: [],
					options: [
						{ value: '1', title: '112' },
						{ value: 'w', title: '2' },
						{ value: '3', title: '3' },
						{ value: '4', title: '4' },
						{ value: '5', title: '5' },
					]
				}
			},
			loading: {
				table: true
			}
		}
	},
	methods: {
		// stopOffers(id) {
		// 	this.$http.post("/offers/startstop",
		// 		{
		// 			offer: this.table.data.find((offer) => offer.id === id)
		// 		},
		// 		(res) => {
		// 			this.table.data.forEach((offer,) => {
		// 				if (offer.id == id) {
		// 					offer.active = res
		// 				}
		// 			})
		// 		})
		// },
		deleteOffers(id) {
			this.$http.post("/offers/delete",
				{
					offer_id: id
				},
				(res) => {
					// console.log(res);
					if (res === 'Done') {
						this.table.data.splice(this.table.data.findIndex((offer) => offer.id === id), 1)
					}
				})
		},
		renderOffers() {
			this.table.data.forEach((offer) => {
				let type = this.typeOffers.find((type) => String(type.key) === offer.type)
				if (type) offer.type = type.value
			})
		},
		loadOffers() {
			this.$http.post("/offers/offerstypes",
				{},
				(res) => {
					// console.log(res, '          ========');
					this.typeOffers = res

					this.$http.post("/offers/adminselect",
						{},
						(data) => {
							if (data && data !== 'error') {
								this.table.data = data
								if (this.filter.category.value.length > 0) {
									let newOffer = []
									this.table.data.forEach((offer) => {
										this.filter.category.value.forEach((item) => {
											if (item === offer.type) {
												newOffer.push(offer)
											}
										})
									})
									this.table.data = newOffer
								}
								this.loading.table = false
								this.renderOffers()
							}
						})
				})
		},
		changeFilter(filter) {
			this.filter = filter
			this.loadOffers()
		},
	},
	mounted() {
		this.$http.post("/offers/offerstypes",
			{},
			(res) => {
				// console.log(res, '          ========');
				this.typeOffers = res

				this.$http.post("/offers/adminselect",
					{},
					(data) => {
						if (data && data !== 'error') {
							this.table.data = data
							this.loading.table = false
							this.renderOffers()
						}
					})
			})
	}
}
</script>

<style scoped></style>