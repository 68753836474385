<template>
	<div class="center_content">
		<a-spin :spinning="form_wait">
			<div class="form_login">
				<div class="form_login_title">ФинБро.Коллцентр</div>
				<div class="error_valid_message">{{ dangerMessage }}</div>
				<a-form
						layout="horizontal"
				>
					<a-form-item>
						<a-input v-model:value="formState.user" placeholder="Логин">
							<template #prefix>
								<UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
							</template>
						</a-input>
					</a-form-item>
					<a-form-item>
						<a-input v-model:value="formState.password" type="password" placeholder="Пароль">
							<template #prefix>
								<LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
							</template>
						</a-input>
					</a-form-item>
					<a-form-item :style="{ marginBottom: '0' }">
						<a-row type="flex" justify="space-between">
							<a-col>
								<a-button
										type="primary"
										html-type="submit"
										:disabled="formState.user === '' || formState.password === ''"
										class="form_login_btn"
										@click="authUser()"
								>
									Войти
								</a-button>
							</a-col>
						</a-row>
					</a-form-item>
				</a-form>
			</div>
		</a-spin>
	</div>

</template>


<script>
import {UserOutlined, LockOutlined} from '@ant-design/icons-vue';
// import {defineComponent} from 'vue';
import {mapActions} from "vuex";

export default {
	components: {
		UserOutlined,
		LockOutlined,
	},
	data() {
		return {
			// user: '',
			// password: '',
			formState: {
				user: '',
				password: '',
			},
			form_wait: false,
			dangerMessage: ''
		}
	},

	methods: {
		...mapActions({loginUser: 'user/loginUser'}),
		authUser() {
			if (this.formState.user && this.formState.password.length) {
				this.form_wait = true
				this.$http.post("/auth/login",
					{
						login: this.formState.user,
						pass: this.formState.password
					},
					(data) => {
						this.form_wait = false
						// console.log(data)
						if (!data) {
							this.isDanger = true
							this.dangerMessage = "Логин или пароль не верные"
						} else {
							this.loginUser(data)
							if (data.role === 'admin') {
								this.$router.push({path: "/operators"});
							} else if (data.role === 'head_admin') {
								this.$router.push({path: "/admins"});
							} else {
								this.$router.push("/");
							}


							this.$store.dispatch('offers/loadingOffers')
							this.$store.dispatch('questions/loadingQuestions')

							// this.$store.dispatch('call/connectSipJs')

							// запросы на оффера и actions
							// this.$http.post("/offers/all",
							//     {},
							//     (data) => {
							//       let offers=JSON.stringify(data)
							//       localStorage.offers = offers
							//     })
							this.$http.post("/flow/actions",
								{},
								(data) => {
									let actions = JSON.stringify(data)
									localStorage.actions = actions
								})
							// this.$http.post("/offers/questselect",
							//     {},
							//     (data) => {
							//       let questions=JSON.stringify(data)
							//       localStorage.questions = questions
							//     })
						}
					}
				)
			}
		},
	},
	mounted() {
		if (this.$store.state.user.isLogin) {
			this.$router.push("/");
		}
	}
};
</script>

<style scoped>
.center_content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_login {
    padding: 25px;
    max-width: 300px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.form_login_btn {
    margin-right: auto;
}

.form_login_title {
    width: 100%;
    text-align: center;
    margin-bottom: -3px;
}

.error_valid_message {
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #FF4D4F;
    height: 20px;
}
</style>