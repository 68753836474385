<template>
	<template v-if="!viewProfile">
		<TitlePage title="Операторы" subtitle="" :border="true">
			<template v-slot:title_right>
				<a-button type="primary" @click="popup.addOperator = true">
					Добавить оператора
				</a-button>
			</template>
			<!--			<FiltersHeader :filter-value="filter" @changeFilter="changeFilter"/>-->
		</TitlePage>


		<a-table :columns="columns" :data-source="data" :loading="loading.table" :pagination="pagination" size="small">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'phone'">
					{{ record.name }}
				</template>
				<template v-else-if="column.key === 'active'">
					<span>
						<a-tag :color="record.active ? 'green' : 'red'">
							{{ record.active ? 'online' : 'offline' }}
						</a-tag>
					</span>
				</template>
				<template v-else-if="column.key === 'tags'">
					<span>
						<a-tag v-for="tag in record.tags" :key="tag"
							:color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'">
							{{ tag.toLowerCase() }}
						</a-tag>
					</span>
				</template>
				<template v-else-if="column.key === 'control'">
					<!-- <span>
							<a>Статистика</a>
							<a-divider type="vertical"/>
							<a @click="renderProfile(record.id)">Профиль</a>
						</span> -->
					<span>
						<a-popconfirm title="Удалить оператора?" @confirm="deleteOperator(record.id)">
							<a>Удалить</a>
						</a-popconfirm>

						<a-divider type="vertical" />
						<a @click="renderProfile(record.id)">Редактировать профиль</a>
					</span>
				</template>
			</template>
		</a-table>
	</template>

	<template v-else>
		<TitlePage :title="dataProfile.name" subtitle="" :backPage="() => { this.viewProfile = false }" :border="true">
		</TitlePage>
		<div class="profile_operator">
			<a-form class="number_inform_form" layout="vertical" style="max-width: 400px">
				<a-form-item label="ФИО">
					<a-input v-model:value="dataProfile.name" />
				</a-form-item>
				<a-form-item label="Логин">
					<a-input v-model:value="dataProfile.profile.login" />
				</a-form-item>
				<a-form-item label="Пароль">
					<a-input v-model:value="dataProfile.profile.pass" />
				</a-form-item>
				<a-form-item>
					<a-button type="primary" @click="saveProfile">Сохранить</a-button>
				</a-form-item>
			</a-form>
		</div>
	</template>

	<BasicModal title="Добавить нового оператора" :visible="popup.addOperator" @closePopup="closePopup()"
		:callback="createOperator" :btn-ok="'Добавить'">
		<a-form class="number_inform_form" layout="vertical">
			<a-form-item label="ФИО">
				<a-input v-model:value="addNewOperator.name" />
			</a-form-item>

			<a-form-item label="Логин">
				<a-input v-model:value="addNewOperator.profile.login" />
			</a-form-item>
			<a-form-item label="Пароль">
				<a-input v-model:value="addNewOperator.profile.pass" />
			</a-form-item>

			<!--			<a-checkbox v-model:checked="addNewOperator.checkbox_one">Чекбокс</a-checkbox>-->
			<!--			<a-checkbox style="margin: 0 0 20px 0"-->
			<!--					v-model:checked="addNewOperator.checkbox_two">-->
			<!--				Чекбос дизеблет-->
			<!--			</a-checkbox>-->

			<!--			<a-form-item label="Дата рождения">-->
			<!--				<a-date-picker v-model:value="addNewOperator.dateOfBirth" :format="dateFormat"/>-->
			<!--			</a-form-item>-->

			<!--			<a-form-item label="Селект">-->
			<!--				<a-select-->
			<!--						v-model:value="addNewOperator.select_one.value"-->
			<!--						show-search-->
			<!--						placeholder="Select"-->
			<!--						:options="addNewOperator.select_one.options"-->
			<!--				>-->
			<!--				</a-select>-->
			<!--			</a-form-item>-->
		</a-form>
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
// import FiltersHeader from "@/components/ui/FiltersHeader";
import BasicModal from "@/components/modal/BasicModal";
import { notification } from 'ant-design-vue';
export default {
	name: "OperatorsAdmin",
	components: {
		TitlePage,
		// FiltersHeader,
		BasicModal,
	},
	data() {

		return {
			pagination: {
				currentPage: 1,
				total: 0,
				pageSize: 100
			},

			loading: {
				table: true
			},
			viewProfile: false,
			dataProfile: {},
			addNewOperator: {
				name: '',
				profile: {
					login: '',
					pass: ''
				}
				// firstName: '',
				// lastName: '',
				// dateOfBirth: '',
				// checkbox_one: true,
				// checkbox_two: false,
				// select_one: {
				// 	value: 'jack',
				// 	options: [
				// 		{
				// 			value: 'jack',
				// 			label: 'Jack',
				// 		},
				// 		{
				// 			value: 'lucy',
				// 			label: 'Lucy',
				// 		},
				// 		{
				// 			value: 'tom',
				// 			label: 'Tom',
				// 		},
				// 	]
				// },
			},
			dateFormat: 'DD/MM/YYYY',
			popup: {
				addOperator: false
			},
			filter: {
				rangeDate: [],
				select: {
					value: '',
					options: [
						{ value: '1' },
						{ value: '2' },
						{ value: '3' },
						{ value: '4' },
					]
				},
				multiselect: {
					value: [],
					options: [
						{ value: '1', title: '1' },
						{ value: 'w', title: '2' },
						{ value: '3', title: '3' },
						{ value: '4', title: '4' },
						{ value: '5', title: '5' },
					]
				}
			},

			columns: [
				{
					title: 'ФИО',
					dataIndex: 'name',
					key: 'name',
					width: '900px'
				}, {
					title: 'Активность',
					dataIndex: 'active',
					key: 'active',
				}, {
					title: 'Управление',
					key: 'control',
				},
			],
			data: [],
		}
	},
	methods: {
		changeFilter(filter) {
			this.filter = filter
		},
		renderProfile(id) {
			// получаем данные выбраного профиля по ид, пока импровизация
			this.dataProfile = this.data.find(item => item.id === id)
			this.viewProfile = true
		},
		createOperator() {
			this.$http.post("/operators/register",
				{
					data: this.addNewOperator
				},
				() => {
					this.getAllOperators()
				})
			this.closePopup()
			// this.$http.post("/operators/all",
			// 	{},
			// 	(data) => {
			// 		this.data = data
			// 		this.loading.table = false
			// 	})
		},
		getAllOperators() {
			this.$http.post("/operators/all",
				{},
				(data) => {
					this.data = data
					this.loading.table = false
				})
		},
		closePopup() {
			this.popup.addOperator = false
		},
		deleteOperator(id) {
			this.dataProfile = this.data.find(item => item.id === id)
			let dataProf = this.dataProfile;

			this.$http.post("/operators/deleteUser",
				{ dataProf: dataProf },
				(res) => {
					if (res === 'Done') {
						this.data.splice(this.data.findIndex((item) => item.id === id), 1)
					}
				})

		},
		saveProfile() {
			let dataProfileId = this.dataProfile.id
			let name = this.dataProfile.name
			let login = this.dataProfile.profile.login
			let pass = this.dataProfile.profile.pass
			// console.log(pass)
			this.$http.post("/operators/saveProfile",
				{ name: name, login: login, pass: pass, id: dataProfileId },
				(data) => {
					if (data === 'Done') {
						notification.open({
							message: 'Данные успешно обновлены',
							description: '',
							duration: 2,
							placement: 'topRight'
						});
					} else if (data === 'PassDone') {
						notification.open({
							message: 'Пароль успешно обновлен',
							description: '',
							duration: 2,
							placement: 'topRight'
						})
					} else if (data === 'False') {
						notification.open({
							message: 'Пароль должен содержать 7 или более символов символов',
							description: '',
							duration: 2,
							placement: 'topRight',
							style: {
								backgroundColor: 'rgb(224, 55, 55)'
							}
						})
					}
				}
			)
		}
	},

	mounted() {
		this.getAllOperators()

	}
}
</script>

<style scoped>
.profile_operator {
	background: #FFFFFF;
	border-radius: 2px;
	padding: 15px;
	color: rgb(224, 55, 55);
}
</style>