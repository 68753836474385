<template>
	<a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
		<a-select
				@change="changInput"
				v-model:value="valueInput"
				mode="multiple"
				style="width: 100%"
				placeholder="Выберите нужные варианты"
				max-tag-count="responsive"
				:options="options"
				:disabled="disabled"
		></a-select>
	</a-form-item>
</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
	name: "input-tree-select",
	props: ['value', 'options', 'name', 'id', 'display', 'disabled'],
	mixins:[ ChangInputMixins ],
	data() {
		return {
			valueInput: [],
			optionsSelect: []
		}
	},
	methods: {
		// changInput() {
		// 	this.$emit('changInput', this.valueSelect)
		// }
	},
	mounted() {
		this.valueInput = this.value ? [...this.value] : []
		this.optionsSelect = [...this.options]
	},
	watch: {
		options(val) {
			this.optionsSelect = [...val]
		}
	}
}
</script>

<style scoped>

</style>