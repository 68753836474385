<template>
	<a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
		<a-slider @afterChange="changInput" v-model:value="valueInput" :max="optionsSelect.max"
				:min="optionsSelect.min" range
				:style="styleDisplay"
		/>
	</a-form-item>

</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
	name: "input-range-number",
	props: ['value', 'options', 'name', 'id', 'display'],
	mixins: [ChangInputMixins],
	data() {
		return {
			valueInput: [],
			optionsSelect: {
				min: 0,
				max: 100
			}
		}
	},
	methods: {
		// changInput() {
		// 	this.$emit('changInput', this.valueSelect)
		// }
	},
	mounted() {
		this.valueInput = this.value ? this.value : [this.options.min, this.options.min]
		this.optionsSelect = this.options
	},
	watch: {
		options(val) {
			this.optionsSelect = val
		}
	}
}
</script>

<style scoped>

</style>