<template>
	<TitlePage title="Скрипты звонков" subtitle="" :border="true">
		<template v-slot:title_right>
			<a-button type="primary" @click="popup.newScenario = true">
				Создать скрипт звонка
			</a-button>
		</template>
	</TitlePage>

	<a-table :columns="scenario.columns" :data-source="scenario.data" :loading="loading.scenario" :pagination="pagination"
		size="middle">
		<template #bodyCell="{ column, record }">

			<template v-if="column.key === 'scenario'">
				<span>
					<a-tag v-for="scenario in record.scenario" :key="scenario" :color="'green'">
						{{ scenario.toLowerCase() }}
					</a-tag>
				</span>
			</template>
			<template v-else-if="column.key === 'operation'">
				<span>
					<a @click="changeScenario(record.id)">Редактировать</a>
					<a-divider type="vertical" />
					<a-popconfirm title="Удалить скрипт?" @confirm="deleteScenario(record.id)">
						<a>Удалить</a>
					</a-popconfirm>
				</span>
			</template>
		</template>
	</a-table>


	<BasicModal title="База лидов" :visible="popup.newScenario" @closePopup="closePopup()" :btn-ok="'Сохранить'"
		:callback="callbackPopup">
		<a-form layout="vertical">
			<a-form-item label="Название">
				<a-input v-model:value="newScenario.name" />
			</a-form-item>


			<a-form-item label="Текст скрипта">
				<a-textarea v-model:value="newScenario.text" :rows="7" />
			</a-form-item>

		</a-form>
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import BasicModal from "@/components/modal/BasicModal";

export default {
	name: "ScriptsCall",
	components: {
		TitlePage,
		BasicModal,
		// InboxOutlined,
	},
	data() {
		this.$http.post("/scenarios/texts",
			{},
			(data) => {
				this.scenario.data = data.texts
			})
		return {
			pagination: {
				total: 100,
				pageSize: 100
			},
			changeIdx: -1,
			popup: {
				newScenario: false
			},
			loading: {
				scenario: false
			},
			// validPopup: {
			// 	scenario: false
			// },
			newScenario: {
				name: '',
				text: '',
			},
			scenario: {
				columns: [
					{
						title: 'Название',
						dataIndex: 'name',
						key: 'name',
						width: 200,
					},
					{
						title: 'Дата создания',
						dataIndex: 'date',
						key: 'date',
						width: 230
					},
					{
						title: 'Сценарии',
						dataIndex: 'scenario',
						key: 'scenario',
						width: 500
					},
					{
						title: 'Действия',
						dataIndex: 'operation',
						key: 'operation',
						width: 230
					}
				],
				data: [
					{
						id: '123123',
						name: 'Холодный',
						date: '29.01.2023',
						scenario: ['подкидыш', 'робот'],
					},
					{
						id: '1123123',
						name: 'Робот сцена "драсте"',
						date: '05.03.2023',
						scenario: ['оператор'],
					},
					{
						id: '124323',
						name: 'Срочно!!',
						date: '10.01.2023',
						scenario: ['оператор срочно'],
					},
				]
			},
			typeScenario: ['На оператора', 'Через робота'],
			scenarioRobot: ['телики', 'тест', 'мега обзвон'],
			baseLids: ['подводная-база', 'холодная', 'теплая']
		}
	},
	methods: {
		// startScenario(id) {
		// this.$http.post("/scenarios/startstop",
		// 	{
		// 		scenario: this.scenario.data.find((scenario) => scenario.id === id)
		// 	},
		// 	(res) => {
		// 		this.scenario.data.forEach((scenario,) => {
		// 			if (scenario.id == id) {
		// 				scenario.active = res
		// 			}
		// 		})
		// 	})
		// },
		closePopup() {
			// console.log('закрыть попап')
			this.newScenario.name = ''
			this.newScenario.text = ''
			this.popup.newScenario = false
		},
		deleteScenario(idScenario) {

			this.$http.post("/scenarios/textdelete",
				{
					id: idScenario
				},
				(res) => {
					if (res === 'Done') {
						this.scenario.data.splice(this.scenario.data.findIndex((scenario) => scenario.key === idScenario), 1)
					}
				})
		},
		// changeSelectItem(val) {
		// 	this.newScenario.base = val
		// },
		changeScenario(id) {
			this.changeIdx = this.scenario.data.findIndex(field => field.id === id)
			let field = this.scenario.data[this.changeIdx]

			this.newScenario.name = field.name
			this.newScenario.text = field.text

			this.popup.newScenario = true
		},
		callbackPopup() {
			// console.log('callbackPopup')
			if (this.newScenario.name && this.newScenario.text) {
				let dataChange = {
					id: !(this.changeIdx >= 0) ? Date.now() : this.scenario.data[this.changeIdx].id,
					date: !(this.changeIdx >= 0) ? new Date().toLocaleDateString('ru-RU', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					}) : this.scenario.data[this.changeIdx].date,
					name: this.newScenario.name,
					text: this.newScenario.text,
					//scenario: this.scenario.data[this.changeIdx].scenario
				}

				if (this.newScenario.name && this.newScenario.text && !(this.changeIdx >= 0)) {
					this.scenario.data.push(dataChange)
					// тут изменяем
				} else if (this.newScenario.name && this.newScenario.text && this.changeIdx >= -1) {
					this.scenario.data[this.changeIdx] = dataChange
					// тут сохраняем новый
				}
				this.changeIdx = -1
				this.$http.post("/scenarios/textinsert",
					{
						data: dataChange
					},
					() => {
						this.$http.post("/scenarios/texts",
							{},
							(data) => {
								this.scenario.data = data.texts
							})
					})
				this.closePopup()
			}

		},
	}
}
</script>

<style scoped></style>