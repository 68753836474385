import { http } from '@/axios/http'
import router from "@/router/router";

let $http = new http();


export const UserModule = {
	namespaced: true,

	state: () => ({
		user: {},
		isLogin: true,
		token: '',
		receiveCalls: false,
		online: false,
		// selected: [],
		// check_policy: false
		awaitLoad: {
			auth: false,
		}
	}),
	getters: {
		isLogin(state) {
			return state.isLogin
		},
		userName(state) {
			return state.user.name
		},
		getReceiveCallsUser(state) {
			return state.receiveCalls
		},
		getOnline(state) {
			return state.online
		},
		getRole(state) {
			return state.user.role
		},
		getIdUser(state) {
			return state.user.id
		},
		getServiceUser(state) {
			return state.user.service
		}
	},
	mutations: {
		dataUser(state, data) {
			localStorage.token = data.token
			// state.user = data.user_data
			// console.log(data, '                      =======================================================================================================')
			state.user = data
			state.token = data.token
			state.isLogin = true
			state.online = Boolean(data.active)
		},
		logoutUser(state) {
			localStorage.removeItem("token");
			state.user = {}
			state.token = ''
			state.isLogin = false
			router.push("/login");
		},
		receiveCalls(state, value) {
			state.receiveCalls = value
			// это можно в базе не держать
			// $http.post("/operators/receiveset",
			// 	{
			// 		value:value
			// 	},
			// 	() => {
			// 	})
		},
		online(state, value) {
			state.online = value
			$http.post("/operators/onlineset",
				{
					value: value
				},
				() => {
				})
		},
		// awaitLoad(state, {value, load}){
		//
		// },
		testMutation() {
			// console.log('----')
		},
	},
	actions: {
		async loginToken({ commit, dispatch }, token) {
			// console.log('login token +')
			$http.post("/auth/validate", {
				token: token
			}, (data) => {
				// console.log(data, '       token login')
				if (data && data !== 'error') {
					// console.log(data, 'data user')
					commit("dataUser", data)
					if (data.role !== 'admin' && data.role !== 'head_admin') {
						dispatch('call/connectSipJs', null, { root: true })
					} else {
						commit('call/loadingAdmin', null, { root: true })
					}

				} else {
					// console.log('logoutUser --------------------')
					commit("logoutUser")
				}
			})
		},
		loginUser({ commit, dispatch }, data) {
			commit('testMutation')
			commit('dataUser', data)
			if (data.role !== 'admin' && data.role !== 'head_admin') {
				dispatch('call/connectSipJs', null, { root: true })
			} else {
				commit('call/loadingAdmin', null, { root: true })
			}
		},
		logoutUser({ commit, dispatch }) {
			commit('receiveCalls', false)
			commit('online', false)
			$http.post("/operators/logout",
				{},
				() => {
				})
			commit('logoutUser')
			dispatch('call/closeConnect', null, { root: true })
			router.push("/login");
		},
		oppositeReceiveCalls({ commit, dispatch }, val) {
			commit('receiveCalls', val)
			dispatch('call/socketGetOnline', null, { root: true })
		},
		changeOnline({ commit }, val) {
			// commit('online', !state.online)
			commit('online', val)

		},
		testAction({ commit },) {
			commit('testMutation')
			// console.log(text)
		}
	},
}