<template>
    <div id="setting_tab" class="card-container">
        <a-tabs type="card">
            <a-tab-pane key="1" tab="Статистика по звонкам">
                <TitlePage title="Статистика" subtitle="Отчёт по звонкам" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        operators: true,
                        channels: true,
                    }" />
                </TitlePage>

                <!--		============================ TABLE =========================-->
                <a-table :columns="columns" :data-source="calls_data" :loading="loading.table" :pagination="false"
                    @change="false">
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Детализация отправок">
                <TitlePage title="Статистика" :subtitle="`Итого:  ` + offers_count + ` отправок`" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        operators: true,
                        channels: true,
                    }" />

                </TitlePage>

                <!--		============================ TABLE =========================-->
                <a-table :columns="offers_columns" :data-source="sends_data" :loading="loading.table"
                    :pagination="pagination" @change="false">
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Статистика по смс">
                <TitlePage title="Статистика" :subtitle="`Всего смс:  ` + sms_count" :border="true">

                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter1" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter1" :view-input-filter="{
                        operators: true,

                    }" />
                </TitlePage>

                <!--		============================ TABLE =========================-->
                <a-table :columns="smscolumns" :data-source="sms_data" :loading="loading.table" :pagination="pagination"
                    @change="false">
                </a-table>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import FiltersHeader from "@/components/ui/FiltersHeader";
//import moment from "moment/moment";
export default {
    name: "CallHistory",
    components: {
        TitlePage,
        FiltersHeader,
    },
    data() {
        this.$http.post("/statistics/allSuper",
            {
            },
            (data) => {


                this.calls_data = data.calls_statistics
                this.sends_data = data.offers_send
                this.offers_count = data.offers_send.length

                this.loading.table = false
                this.pagination.total = this.sends_data.length

            })
        return {
            sms_count: '',
            offers_count: '',
            channel: '',
            count: '',
            // numberPhone: '',
            loading: {
                table: true,
            },
            pagination: {
                total: 500,
                pageSize: 100
            },
            filter: {
                rangeDate: [],
                operators: {
                    title: [],
                    options: [
                        { value: '5', title: '5' },
                    ]
                },
                base: {
                    value: [],
                    options: [
                        { value: '1', title: '112' },
                    ]
                },
                channels: {
                    value: [],
                    options: [
                        { value: '1', title: '1' }
                    ]
                },
                singleoperator: {
                    value: [],
                    options: [
                        { value: '', title: '' }
                    ]
                }
            },
            columns: [
                {
                    title: 'Количество звонков',
                    dataIndex: 'calls_amount',
                    key: 'calls_amount',
                },
                {
                    title: 'Количество звонков с оператором',
                    dataIndex: 'calls_operator_amount',
                    key: 'calls_operator_amount',
                },
                {
                    title: 'Количество секунд',
                    dataIndex: 'seconds_amount',
                    key: 'seconds_amount',
                },
                {
                    title: 'Среднее время звонка оператора, секунды',
                    dataIndex: 'avg_operator_time_call',
                    key: 'avg_operator_time_call',
                },
                {
                    title: 'Цена звонков, руб',
                    dataIndex: 'calls_cost',
                    key: 'calls_cost',
                },
                {
                    title: 'Количество отправок',
                    dataIndex: 'sends_amount',
                    key: 'sends_amount',
                },
                {
                    title: 'Цена робота, руб',
                    dataIndex: 'robot_cost',
                    key: 'robot_cost',
                },
                {
                    title: 'Себестоимость отправки, руб',
                    dataIndex: 'full_cost',
                    key: 'full_cost',
                },
            ],
            offers_columns: [
                {
                    title: 'Дата отправки',
                    dataIndex: 'date_create',
                    key: 'date_create',
                },
                {
                    title: 'Оператор',
                    dataIndex: 'operator_id',
                    key: 'operator_id',
                },
                {
                    title: 'Название оффера',
                    dataIndex: 'offer_id',
                    key: 'offer_id',
                },
                {
                    title: 'Номер',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: 'Статус',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: 'Ошибка',
                    dataIndex: 'error',
                    key: 'error',
                },
            ],
            smscolumns: [
                {
                    title: 'Номер',
                    dataIndex: 'phone',
                    key: 'phone'
                },
                {
                    title: 'Статус',
                    dataIndex: 'status',
                    key: 'status'

                },
                {
                    title: 'Альфа',
                    dataIndex: 'alpha',
                    key: 'alpha'
                },
                {
                    title: 'Текст',
                    dataIndex: 'text',
                    key: 'text'
                },
                {
                    title: 'Сотовый оператор',
                    dataIndex: 'operator',
                    key: 'operator'
                },
                {
                    title: 'Оператор',
                    dataIndex: 'name',
                    key: 'name'
                },

            ],
            calls_data: [
                {
                    calls_amount: 0,
                    calls_operator_amount: 0,
                    seconds_amount: 0,
                    avg_operator_time_call: 0,
                    calls_cost: 0,
                    sends_amount: 0,
                    robot_cost: 0,
                    full_cost: 0,
                }
            ],
            sends_data: [
                {
                    date_create: '',
                    operator_id: 0,
                    offer_id: '0',
                    phone: '900',
                    status: '',
                    error: '',

                }
            ],
            sms_data: [
                {
                    phone: '',
                    status: '',
                    alpha: '',
                    text: '',
                    operator: '',
                    name: ''
                }
            ]
        }
    },
    methods: {
        changeFilter(filter) {

            this.loading.table = true
            this.$http.post("/statistics/allSuper",
                {
                    filter: filter
                },
                (data) => {

                    this.calls_data = data.calls_statistics
                    this.sends_data = data.offers_send
                    this.sms_data = data.sms_data
                    this.offers_count = data.offers_send.length
                    this.loading.table = false
                    this.pagination.total = this.sends_data.length
                })


        },
        changeFilter1(filter) {

            this.loading.table = true
            this.$http.post("/statistics/smsstats",
                {
                    filter: filter
                },
                (data) => {

                    this.calls_data = data.calls_statistics
                    this.sends_data = data.offers_send
                    this.sms_count = data.sms_data.length
                    this.sms_data = data.sms_data
                    this.loading.table = false
                    this.pagination.total = this.sms_data.length
                })


        },
    },
    mounted() {

        this.filter.base.options = []
        this.filter.base.singleoperator = []
        this.filter.base.operators = []
        this.filter.channels.options = []
        this.$http.post("/statistics/allOperators",
            {
            },
            (data) => {

                this.filter.operators.options = data
            })
        // this.$http.post("/bases/regions",
        //     {
        //     },
        //     (data) => {
        //         data.forEach((item)=>{
        //             if (item!=='Все регионы') {
        //                 this.filter.region.options.push({value: item, title: item})
        //             }
        //         })
        //     })
        // this.$http.post("/bases/all",
        //     {
        //     },
        //     (data) => {
        //         data.bases.forEach((item)=>{
        //                 this.filter.base.options.push({value: item.id, title: item.name})
        //         })
        //     })

        this.$http.post("/statistics/allChanels",
            {},
            (data) => {
                // const channelValue = data.channels;


                this.filter.channels.options = data.map(item => ({
                    value: item.channel,
                    title: item.channel
                }));
            })
        this.$http.post("statistics/smsstats",
            {
            },
            (data) => {
                this.sms_count = data.sms_count;

                this.sms_data = data.sms_data.map(item => ({
                    phone: item.phone,
                    status: item.status,
                    alpha: item.alpha,
                    text: item.text,
                    operator: item.operator,
                    operator_id: item.operator_id,
                    name: item.name

                }));
                const uniqueOperators = new Set();
                this.sms_data.forEach(item => {
                    uniqueOperators.add(item.operator_id);
                });
                this.filter.singleoperator.options = Array.from(uniqueOperators).map(operator_id => ({
                    value: operator_id,
                    title: this.sms_data.find(item => item.operator_id === operator_id).name
                }));

            });




    }
}
</script>

<style scoped></style>