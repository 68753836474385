<template>
	<TitlePage title="Поток" subtitle="Ваша очередь звонков" :border="true" />
	<div class="flow">
		<a-row :gutter="10" :style="{ height: '100%' }">
			<a-col :span="17">
				<div class="flow_main">
					<a-card v-for="(phone, key) in incomingPhone" :key="key"
						style="width: 100%; border-radius: 2px; margin-bottom: 15px;">
						<template #title>
							{{ phoneCustom(phone) }}
						</template>
						<template #extra><a href="#">
								<a-button type="primary" danger @click="$router.push(`/call/${phone}`)">
									Принять звонок
								</a-button>
							</a></template>

					</a-card>
				</div>
				<div v-if="loadingFlow" class="flow_main">
					<a-card v-for="(card, key) in 3" :key="key"
						style="width: 100%; border-radius: 2px; margin-bottom: 15px;">
						<template #title><a href="#">
								<a-skeleton-input style="width: 220px" :active="true" />
							</a></template>
						<template #extra><a href="#">
								<a-skeleton-button style="width: 100px" :active="true" :block="true" />
							</a></template>
						<a-skeleton active />
					</a-card>
				</div>
				<div v-else class="flow_main">
					<a-card v-if="data_card.length === 0" style="width: 100%; border-radius: 2px; margin-bottom: 15px;">
						<a-empty />
					</a-card>
					<a-card v-else v-for="(card, key) of filterFlow" :key="key" :title="phoneCustom(card.number)"
						style="width: 100%; margin-bottom: 15px; border-radius: 2px">
						<template #extra><a @click="$router.push(`/call/${card.number}`)" href="#">Открыть</a>
						</template>
						<div class="flow_main__card_body">
							<InformText v-if="card.inform.callbacks_count === 0" :params="['Тип', 'Источник', 'Дата']"
								:values="[card.inform.type, card.inform.source, card.inform.date]" />
							<InformText v-if="card.inform.callbacks_count > 0"
								:params="['Тип', 'Источник', 'Дата', 'Перезвоны']"
								:values="[card.inform.type, card.inform.source, card.inform.date, card.inform.callbacks_count]" />
							<div>
								<a-button class="flow_main__card_body_btn" @click="visiblePopupFun('History', card.number)">
									История
								</a-button>
								<a-button class="flow_main__card_body_btn" @click="visiblePopupFun('Inform', card.number)">
									Анкета
								</a-button>
							</div>
						</div>
						<div class="flow_main__card_score">
							<div class="" v-for="(scoreItem, key) of card.score" :key="key">
								<div class="dop_color_text">{{ scoreItem.name }}</div>
								<div v-if="scoreItem.value && scoreItem.value > 0">Хорошо
									<CaretUpOutlined :style="{ color: '#52C41A' }" />
									<span class="green_color_text">{{ scoreItem.value }}%</span>
								</div>
								<div v-if="scoreItem.value && scoreItem.value <= 0">Плохо
									<CaretUpOutlined :style="{ color: '#FF4D4F', rotate: '180deg' }" />
									<span class="red_color_text">{{ scoreItem.value }}%</span>
								</div>
								<a-button :disabled="true" v-if="!scoreItem.value" class="flow_main__card_function_btn"
									type="primary">
									Проскорить
								</a-button>
							</div>
						</div>
					</a-card>
				</div>
			</a-col>
			<a-col :span="7">
				<div class="flow_options">
					<div class="flow_options__checked">
						<div>
							<a-switch class="flow_options__checked_btn" v-model:checked="show_cold_value"
								@change="show_cold_calls(show_cold_value)" />
							Показать холодные лиды
						</div>
					</div>

					<div class="flow_options__command">
						<CheckboxGroup v-for="(filter, key) of filtersFlowOptions" :key="key" :titleDivider="filter.type"
							:plainOptions="filter.options" @checkedArr="filterFlowOptions" />
					</div>
					<div class="flow_options__command">
						<a-divider>Ручной набор номера</a-divider>
						<a-input-group compact>
							<a-input v-model:value="input_number" placeholder="Введите номер телефона"
								style="width: calc(100% - 200px)" />
							<a-button @click="$router.push(`/call/${input_number}`)">Позвонить</a-button>
						</a-input-group>
					</div>
				</div>
			</a-col>
		</a-row>
		<!--		{{numberHistory}}-->
	</div>
	<BasicModal :visible="visiblePopup.History" :title="`История номера ${phoneCustom(visibleNumber)}`"
		@closePopup="closePopup" :callback="closePopup">
		<!--		<a-range-picker style="margin-bottom: 20px; width: 100%" v-model:value="valueDateFilter"/>-->
		<TimeLine :value="numberHistory" />
	</BasicModal>
	<BasicModal :visible="visiblePopup.Inform" :title="`Имеющиеся анкета на номер ${phoneCustom(visibleNumber)}`"
		@closePopup="closePopup" :callback="closePopup">
		<a-form class="number_inform_form" layout="vertical">
			<component class="number_inform_form_item" v-for="field of resultFields" :key="field.key" :is="field.type.value"
				:options="field.options" :value="field.value" :name="field.name" :disabled="true"></component>
		</a-form>
	</BasicModal>
</template>
<script>
import { CaretUpOutlined } from '@ant-design/icons-vue';
import BasicModal from "@/components/modal/BasicModal";
import TitlePage from "@/components/TitlePage";
import CheckboxGroup from "@/components/ui/CheckboxGroup";
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import TimeLine from "@/components/ui/TimeLine";
import InformText from "@/components/ui/InformText.vue";

import inputText from "@/components/field/input-text";
import inputNumber from "@/components/field/input-number";
import inputSelect from "@/components/field/input-select";
import inputTreeSelect from "@/components/field/input-tree-select";
import inputBoolean from "@/components/field/input-boolean";
import inputDate from "@/components/field/input-date";
import inputRangeDate from "@/components/field/input-range-date";
import inputRangeNumber from "@/components/field/input-range-number";

import { mapGetters } from "vuex";

export default {
	name: "FlowPage",
	mixins: [formattingPhoneMixin],
	components: {
		CaretUpOutlined,
		BasicModal,
		TitlePage,
		CheckboxGroup,
		TimeLine,
		InformText,
		// ClockCircleOutlined
		inputText,
		inputNumber,
		inputSelect,
		inputTreeSelect,
		inputBoolean,
		inputDate,
		inputRangeDate,
		inputRangeNumber,
	},
	data() {
		return {
			show_cold_value: true,
			visiblePopup: {
				History: false,
				Inform: false,
			},
			visibleNumber: '',
			input_number: '',
			data_card: [],
			valueDateFilter: [],
			// numberInform: {},
			numberHistory: [],
			// dateFormat: 'DD/MM/YYYY',
			loadingFlow: true,
			phoneFields: {},
			// dataFields: [],
			resultFields: [],
			filtersFlowOptions: [],
			filterOptions: [[], []],
			filterFlow: [],
		}
	},
	computed: {
		...mapGetters({ incomingPhone: 'call/getIncomingPhone', dataFields: 'questions/getQuestions', })
	},
	methods: {
	// 	phoneCustom(phone) {
      
    //   const countryCode = "+7";
    //   return `${countryCode} **** ${phone.slice(-4)}`;
    // },
		show_cold_calls(value) {
			// console.log("!!!!!!!!!!!!!!!!!!@#!@31231231231312")
			this.loadingFlow = true
			if (value) {
				this.funLoadingFlow()
			}
			else {
				this.filterFlow = this.filterFlow.filter(item => item.inform.type === 'Перезвонить' || item.inform.type === 'Пропущенный')
			}
			this.loadingFlow = false
		},
		visiblePopupFun(popup, number) {
			this.visibleNumber = number
			switch (popup) {
				case 'History':
					this.visiblePopup.History = true
					this.$http.post("/clients/history",
						{
							number: number
						},
						(data) => {
							//console.log(data,777)
							this.numberHistory = data
						})
					// тут запрос истории номера this.visibleNumber
					// this.numberHistory = []

					break
				case 'Inform':
					this.visiblePopup.Inform = true
					// тут запрос информации о номере this.visibleNumber
					// this.$http.post("/clients/anketa",
					// 	{
					// 		number: number
					// 	},
					// 	(data) => {
					// 		this.questions = data
					// 	})
					// this.phoneFields = {}
					// this.dataFields = []
					// this.$http.post("/offers/questselect",
					// 	{},
					// 	(data) => {
					// 		this.dataFields = data
					this.$http.post("/clients/anketa",
						{
							number: number
						},
						(anketa) => {
							this.phoneFields = anketa
							this.renderFields()
						})
					// })
					break
			}
		},
		filterFlowOptions(options, type) {
			if (type === 'Тип лида') {
				this.filterOptions[0] = options
			} else if (type === 'Источник') {
				this.filterOptions[1] = options
			}
			this.renderFlowView()
		},
		renderFlowView() {
			let flow = [...this.data_card]

			if (this.filterOptions[0].length) {
				flow = flow.filter((phone) => this.filterOptions[0].findIndex((value) => value === phone.inform.type) !== -1)
			}
			if (this.filterOptions[1].length) {
				flow = flow.filter((phone) => this.filterOptions[1].findIndex((value) => value === phone.inform.source) !== -1)
			}

			this.filterFlow = flow
		},
		renderFields() {
			let fields = []
			this.dataFields.forEach((field) => {
				let value = this.phoneFields[field.key]
				if (value) {
					fields.push({
						key: field.key,
						value: value,
						name: field.name,
						type: field.type,
						options: field.options
					})
				}
			})
			this.resultFields = fields
		},
		renderFiltersFlow() {
			let filter = [
				{
					type: 'Тип лида',
					options: []
				},
				{
					type: 'Источник',
					options: []
				},
			]
			//console.log(this.data_card, '    this.data_card');
			this.data_card?.forEach(phone => {
				filter[0].options.push(phone.inform.type)
				filter[1].options.push(phone.inform.source)
			})
			filter.forEach((section) => {
				section.options = [...new Set(section.options)]
			})
			this.filtersFlowOptions = filter
		},
		closePopup() {
			this.phoneFields = {}
			this.resultFields = []
			this.visiblePopup.History = false
			this.visiblePopup.Inform = false
		},
		funLoadingFlow() {
			this.$http.post("/flow/all",
				{},
				(data) => {
					if (data) {
						this.data_card = data
						this.loadingFlow = false
						this.renderFiltersFlow()
						this.renderFlowView()
					}
				})
		},
	},
	mounted() {
		console.log(this.$store.state.call.incomingPhone)
		// if (this.$store.state.call.incomingPhone.length>0) {
		//   const audio = new Audio('https://storage.yandexcloud.net/ivrrecord/uvedomlen-korotkoe.mp3')
		//   audio.play()
		// }
		this.$store.dispatch('offers/loadingOffers')
		this.$store.dispatch('questions/loadingQuestions')

		this.funLoadingFlow()
		setInterval(() => {
			this.funLoadingFlow()
		}, 1000 * 60)

		// setTimeout(() => {
		// 	setInterval(() => {
		// 		if (this.incomingPhone.length>0){
		// 			let phone = this.incomingPhone[0]
		// 			this.$router.push(`/call/${phone}`)
		// 		}
		// 	}, 1000)
		// }, 1000 * 3)

	}
}
</script>

<style scoped>
/*.container_flow {*/
/*    display: grid;*/
/*    grid-template-columns: 3fr 1fr;*/
/*    grid-gap: 10px;*/
/*}*/

.flow {
	/*background: #ffffff;*/
}

.flow>div {
	height: 100px;
}

.flow_main {
	/*display: flex;*/
	/*flex-direction: column;*/
}

.flow_options {
	display: flex;
	flex-direction: column;
	gap: 10px;
	/*height: 100%;*/
	/*position: fixed;*/
	width: 100%;
	position: sticky;
	top: 75px;
}

.flow_main__card,
.flow_options__checked,
.flow_options__command {
	background: #ffffff;
	border-radius: 2px;
}

.flow_main__card {
	margin-bottom: 15px;
}

.flow_main__card_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: inset 0 -1px 0 #F0F0F0;
}

.flow_main__card_head,
.flow_main__card_body,
.flow_main__card_score {
	/*padding: 16px 24px;*/
}

.flow_main__card_body {
	margin-bottom: 25px;
}

.flow_main__card_body,
.flow_main__card_body>div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flow_main__card_body__info>div {
	margin-right: 25px;
}

.flow_main__card_body_btn {
	width: 150px;
	margin-left: 20px;
}

.flow_main__card_body {}

.flow_main__card_score {
	display: flex;
	gap: 20px;
}

.flow_main__card_function_skor {
	margin-right: 25px;
}

.flow_main__card_function_btn {
	margin-top: 6px;
}

.flow_options__checked,
.flow_options__command {
	padding: 15px 25px;
}

/*.flow_options__checked div {*/
/*    margin-bottom: 10px;*/
/*}*/

.flow_options__checked_btn {
	margin-right: 15px;
}

.flow_options__command_checkboxes {
	display: flex;
	flex-direction: column;
}

.flow_options__command_checkboxes>label {
	margin: 6px 0;
}

.number_inform_form {
	display: flex;
	flex-direction: column;
}

.mini_circle {
	content: '';
	width: 6px;
	height: 6px;
	display: inline-block;
	border-radius: 100px;
	/*background: #FF4D4F;*/
	margin-bottom: 2px;
	margin-right: 7px;
}

.inform_item {
	margin-bottom: 15px;
}

.number_inform_form_item {
	page-break-inside: avoid;
	break-inside: avoid;
}
</style>