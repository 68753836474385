<template>
	<a-spin tip="Загрузка данных..." :spinning="!data_phone.number">
		<div class="outgoing_call_page">
			<div v-if="callActive">
				<a-row :gutter="[10, 0]">
					<a-col :span="17">
						<TitlePage :title="phoneCustom(numberPhone)" :style="{ margin: '0', width: '100%' }"
							subtitle="Исходящий звонок" :backPage="() => { $router.push('/') }">
							<template v-slot:title_right>
								<div>
									<span style="margin-right: 15px"
										:style="{ color: (statusCall === 'call' || statusCall === 'dial') && seconds >= this.timeOp2 ? 'red' : (statusCall === 'call' || statusCall === 'dial') && seconds >= this.timeOp ? 'orange' : 'black' }"
										v-if="statusCall === 'call' || statusCall === 'dial'">{{ minutes + ":" + seconds
										}}</span>
									<a-button v-if="statusCall === 'view' || statusCall === 'end'" type="primary"
										@click="clickStartCall()">
										Позвонить
									</a-button>
									<a-button v-if="statusCall === 'dial'" type="primary" disabled>Идет набор...
									</a-button>
									<a-button v-if="statusCall === 'call'" @click="clickStopCall()"
										:style="{ background: '#FF7875', color: '#ffffff' }" danger>
										Бросить трубку
									</a-button>
									<a-button style="margin-left: 10px" v-if="statusCall === 'end'" @click="endCallActive()"
										danger>Завершить
									</a-button>
								</div>


							</template>
						</TitlePage>
						<div class="outgoing_call_data_phone">
							<div class="data_phone__inform">
								<a-divider style="font-size: 16px; font-weight: 400;" orientation="left">Информация
								</a-divider>
								<!-- <div class="creditRate">
								<span class="creditRateText">Кредитный рейтинг</span>
								<input type="class" class="textCred" v-model="data_phone.creditRate">
							</div> -->
								<div class="data_phone__inform_value">
									<div>
										<div class="dop_color_text">Тип клиента</div>
										<div class="fz24">{{ data_phone.inform.type }}</div>
									</div>
									<InformText :params="['Источник', 'Дата']"
										:values="[data_phone.inform.source, String(data_phone.inform.date).split('T')[0]]" />
								</div>
							</div>
							<!--							<div class="data_phone__scoring">-->
							<!--								<a-divider style="font-size: 16px; font-weight: 400;" orientation="left">Скоринг-->
							<!--								</a-divider>-->
							<!--								<div class="flow_main__card_score">-->
							<!--									<div class="" v-for="(scoreItem, key) of data_phone.score" :key="key">-->
							<!--										<div class="dop_color_text">{{ scoreItem.name }}</div>-->
							<!--										<div v-if="scoreItem.value && scoreItem.value > 0">Хорошо-->
							<!--											<CaretUpOutlined :style="{color: '#52C41A'}"/>-->
							<!--											<span class="green_color_text">{{ scoreItem.value }}%</span>-->
							<!--										</div>-->
							<!--										<div v-if="scoreItem.value && scoreItem.value <= 0">Плохо-->
							<!--											<CaretUpOutlined :style="{color: '#FF4D4F', rotate: '180deg'}"/>-->
							<!--											<span class="red_color_text">{{ scoreItem.value }}%</span>-->
							<!--										</div>-->
							<!--										<a-button v-if="!scoreItem.value" class="flow_main__card_function_btn"-->
							<!--												type="primary">-->
							<!--											Проскорить-->
							<!--										</a-button>-->
							<!--									</div>-->
							<!--								</div>-->
							<!--							</div>-->
							<div class="data_phone__scoring">
								<a-divider style="font-size: 16px; font-weight: 400;" orientation="left">СМС
								</a-divider>
								
								<div class="flow_main__card_score">
									<a-input v-model:value="real_phone" placeholder="Контактный номер" style="width: 200px"
										prefix="Номер:" />
									<a-checkbox v-model:checked="sms_callback" style="margin-top: 1%">Перезвонить по итогам
										СМС</a-checkbox>
								</div>
								<div class="flow_main__card_score" style="margin-top: 3%">
									<a-select v-model:value="smstemplate_value"
										:options="smstemplates.map(v => ({ value: v.name }))" placeholder="Выбор шаблона"
										class="sms_card"></a-select>
								</div>
							</div>
						</div>
						<div class="data_phone_menu">
							<a-tabs class="data_phone_menu_tabs" v-model:activeKey="dataPhoneTab">
								<template #leftExtra>
									<div class="checked_script_call">
										<a-switch v-model:checked="checkedScriptCall" />
										Показать скрипт звонка
									</div>

								</template>
								<a-tab-pane key="0" tab="Анкета">
									<div :style="{ display: checkedScriptCall ? 'flex' : 'block' }">
										<div class="text_script_call"
											:style="{ display: checkedScriptCall ? 'flex' : 'none' }">
											{{ scriptText }}
										</div>
										<div>
											<a-form class="number_inform_form" layout="vertical">
												<component class="number_inform_form_item" v-for="field of viewFields"
													:key="field.key" :is="field.type.value" :options="field.options"
													:value="field.value" @changInput="changeValueField" :name="field.name"
													:id="field.key" :display="field.display"></component>
											</a-form>
										</div>
									</div>


								</a-tab-pane>
								<a-tab-pane key="1" tab="История" force-render>
									<div class="data_phone_menu_tabs_history">
										<div>
											<TimeLine :value="data_phone.history" />
										</div>
										<div>
											<a-range-picker :style="{ marginBottom: '20px' }" v-model:value="valueDate" />
											<div v-for="(comment, key) of data_phone.comments" :key="key">
												<div class="dop_color_text">
													{{ comment.name }}
													<span>{{ comment.date }}</span>
												</div>
												<p>{{ comment.text }}</p>
											</div>
										</div>
									</div>
								</a-tab-pane>
							</a-tabs>
						</div>
					</a-col>
					<a-col :span="7">
						<div class="bg_white_container ">
							<a-tabs v-model:activeKey="activeKeyTab" tab-position="top" style="padding: 0 0 15px 15px">
								<a-tab-pane :style="{}" v-for="(tab, i) of tabs" :key="i">
									<template #tab>
										<span @click="offerTabDisplayFalse">
											{{ tab.name }}
											<a-badge :count="countValidOffers(tab.content)"
												:number-style="{ backgroundColor: '#2db7f5' }" />
										</span>
									</template>
									<OffersCards :offers-arr="tab.content.filter(item => item.view === 'scenario')"
										@displayFieldsThisOffer="changeDisplayFields" @sendOffer="sendOffer"
										@sendOfferSecondHand="sendOfferSecondHand" @sendSms="sendSms"></OffersCards>
								</a-tab-pane>
							</a-tabs>
						</div>
						<div>
							<a-divider orientation="left">Оффера по другим активным сценариям</a-divider>
						</div>
						<div class="bg_white_container ">
							<a-tabs v-model:activeKey="activeKeyTab" tab-position="top" style="padding: 0 0 15px 15px">
								<a-tab-pane :style="{}" v-for="(tab, i) of tabs" :key="i">
									<template #tab>
										<span @click="offerTabDisplayFalse">
											{{ tab.name }}
											<a-badge :count="countValidOffers(tab.content)"
												:number-style="{ backgroundColor: '#2db7f5' }" />
										</span>
									</template>
									<OffersCards :offers-arr="tab.content.filter(item => item.view === 'else')"
										@displayFieldsThisOffer="changeDisplayFields" @sendOffer="sendOffer_else"
										@sendOfferSecondHand="sendOfferSecondHand_else" @sendSms="sendSms"></OffersCards>

								</a-tab-pane>
							</a-tabs>
						</div>
						<!--						<div class="action_list_phone">-->
						<!--							<a-button :disabled="true" @click="visibleAddAction = true" block>Добавить действие</a-button>-->
						<!--							<ActionPhoneList-->
						<!--									:visible="visibleAddAction"-->
						<!--									:action-list="actionListPhone"-->
						<!--									:add-list-action="addListAction"-->
						<!--									@close="visibleAddAction = false"-->
						<!--									@open="visibleAddAction = true"-->
						<!--									@save="saveActionList"-->
						<!--							/>-->
						<!--						</div>-->
						<div>
							<a-button type="danger" class="blacklist" @click="blacklist(numberPhone)" block>Добавить номер в
								чёрный список </a-button>
						</div>
					</a-col>
				</a-row>
			</div>
			<div v-else class="results_call">
				<div class="results_call_title">
					Итоги звонка с +{{ phoneCustom(numberPhone) }}
				</div>
				<a-col>
					<a-button type="primary" class="backward_button" @click="backward"> Вернуться назад</a-button>
				</a-col>
				<div class="resultCall">
					<a-row :gutter="35">
						<a-col :span="10">
							<div>
								<a-divider orientation="left">Отправленные оферы</a-divider>
								<OffersCards :offers-arr="sendOffers" :allSend="true"></OffersCards>
							</div>
						</a-col>
						<a-col :span="14">
							<div>
								<a-divider orientation="left">Оценка звонка</a-divider>
								<!--								<ActionPhoneList-->
								<!--										:visible="visibleAddAction"-->
								<!--										:action-list="actionListPhone"-->
								<!--										:add-list-action="addListAction"-->
								<!--										@save="saveActionList"-->
								<!--										:styleView="true"-->
								<!--								/>-->
								<a-select ref="select" v-model:value="addAction" style="width: 300px"
									:options="addListAction">
								</a-select>
								<a-divider orientation="left">Нужен перезвон (не берут трубку) </a-divider>
								<span>
									<a-checkbox v-model:checked="callback_checkbox_noans"> Вернуть номер в поток позднее
									</a-checkbox>
								</span>
								<a-divider orientation="left">Настроить перезвон (попросили связаться позже)</a-divider>
								<span>
									<a-checkbox v-model:checked="callback_checkbox">Перезвонить через </a-checkbox>
									<a-input-number :disabled="!callback_checkbox" id="inputNumber"
										v-model:value="callback_time" :min="0" :max="1000" />
									<span> часов </span>
									<a-input-number :disabled="!callback_checkbox" id="inputNumber"
										v-model:value="callback_time_min" :min="0" :max="1000" />
									<span> минут </span>
								</span>
							</div>
						</a-col>
					</a-row>
					<a-row class="end_call_outgoing" type="flex" justify="space-between">
						<a-col class="end_call_button" :span="12">
							<a-textarea v-model:value="comment" placeholder="Комметарий к звонку" :rows="4"
								class="end_call_comment" />
						</a-col>
						<a-col>
							<a-button type="primary" @click="closeOutgoingCall">Завершить</a-button>
						</a-col>
					</a-row>
				</div>

			</div>
		</div>
	</a-spin>
</template>

<script>
// import {CheckCircleOutlined} from '@ant-design/icons-vue';

import TitlePage from "@/components/TitlePage";
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import TimeLine from "@/components/ui/TimeLine";
import ActionPhoneList from "@/components/ui/ActionPhoneList";
import OffersCards from "@/components/ui/OffersCards";
import InformText from "@/components/ui/InformText.vue";

import inputText from "@/components/field/input-text";
import inputNumber from "@/components/field/input-number";
import inputSelect from "@/components/field/input-select";
import inputSelectRegion from "@/components/field/input-select-region";
import inputTreeSelect from "@/components/field/input-tree-select";
import inputBoolean from "@/components/field/input-boolean";
import inputDate from "@/components/field/input-date";
import inputRangeDate from "@/components/field/input-range-date";
import inputRangeNumber from "@/components/field/input-range-number";
import inputSelectName from "@/components/field/input-select-name";
import { mapGetters, mapActions } from "vuex";
import { notification } from "ant-design-vue";

export default {
	name: "OutgoingCall",
	mixins: [formattingPhoneMixin],
	components: {
		TitlePage,
		// CheckCircleOutlined,
		// CaretUpOutlined,
		InformText,
		TimeLine,
		ActionPhoneList,
		OffersCards,
		inputText,
		inputNumber,
		inputSelect,
		inputSelectRegion,
		inputTreeSelect,
		inputBoolean,
		inputDate,
		inputRangeDate,
		inputRangeNumber,
		inputSelectName
	},
	data() {
		this.$http.post("/sms/smstemplatesget",
			{},
			(data) => {
				this.smstemplates = data
				if (this.smstemplates.length > 0) {
					this.smstemplate_value = this.smstemplates[0].name
				}
			})
		return {
			
			checkedScriptCall: true,
			// loading: {
			// 	data: true
			// },
			// stopCall: false,
			statusCall: 'view',
			callActive: true,
			scriptText: 'Привет',
			addAction: '',
			minutes: '00',
			seconds: '00',
			callback_checkbox: false,
			callback_checkbox_noans: false,
			callback_time: 0,
			callback_time_min: 0,
			offer_send_count: 1,
			comment: '',
			data_phone: {
				number: '',
				inform: {},
				score: [],
				fields: {},
				actions: [],
				history: [],
				comments: [],
				data_call: [],
				scenario: '',
				base: '',
				// creditRate:'',
			},
			valueDate: '',
			numberPhone: '',
			activeKeyTab: 0,
			dataPhoneTab: '0',
			tabs: [],
			sendOffers: [],
			actionListPhone: [],
			visibleAddAction: false,
			addListAction: [],

			dataOffers: [],
			// dataFields: [],
			viewFields: [],
			filledFields: [],
			smstemplates: [],
			smstemplate_value: '',
			sms_callback: 1,
			real_phone: '',
			interval: 0,

			displayFieldOffer: [],
			timeOp: 5,
			timeOp2: 10,
		}
	},
	computed: {
		loadingData() {
			return !!this.data_phone.number
		},
		...mapGetters({
			//dataOffers: 'offers/getOffers',
			// loadingOffers: 'offers/getLoading',
			dataFields: 'questions/getQuestions',
			// loadingQuestions: 'questions/getLoading'
			dataCallStore: 'call/getDataCall',
			statusCallStore: 'call/getStatusCall',
		})
	},
	watch: {
		statusCallStore(val) {
			if (!val) {
				this.clickStopCall()
			}
		},
	},
	methods: {
		backward() {
			this.callActive = true
			this.statusCall = 'end'
		},
		clickStartCall() {
			this.minutes = '00'
			this.seconds = '00'
			this.statusCall = 'dial'
			this.startCall(this.numberPhone)
			setTimeout(() => {
				this.statusCall = 'call'
			}, 2000)
			let sec = 0
			const int = setInterval(() => {
				sec++
				if (sec < 60) {
					if (sec < 10) {
						this.seconds = '0' + sec.toString()
					}
					if (sec >= 10) {
						this.seconds = sec.toString()
					}
					if (sec === this.timeOp) {
						notification.info({
							message: 'Долгий звонок',
							placement: 'BottomRight'
						});
					}
					if (sec === this.timeOp2) {
						notification.info({
							message: 'Очень долгий звонок',
							placement: 'BottomRight'
						});
					}
					// console.log("00:" + this.seconds)
				}
				if (sec >= 60) {
					this.minutes = Math.floor(sec / 60)
					this.seconds = sec - (60 * this.minutes)
					if (this.minutes < 10) {
						this.minutes = '0' + this.minutes
					}
					if (this.seconds < 10) {
						this.seconds = '0' + this.seconds
					}
					// console.log(this.minutes + ":" + this.seconds)
				}
				if (this.statusCall === 'end') {
					clearInterval(int)
				}
			}, 1000);


			this.$http.post("/flow/buffer",
				{
					phone: this.numberPhone
				},
				() => {
				})
		},
		clickStopCall() {
			this.minutes = '00'
			this.seconds = '00'
			this.statusCall = 'end'
			this.stopCall()
			this.saveRecordAndTime()

			let sec = 0
			if (this.interval === 0) {
				this.interval = 1
				const int = setInterval(() => {
					sec++
					if (this.statusCall !== 'end') {
						clearInterval(int)
						sec = 0
						this.interval = 0
					}
					// console.log(sec)
					if (sec === 180) {
						notification.open({
							message: 'Необходимо выйти из карточки звонка',
							description: 'Скоро будет отправлено уведомление',
							duration: 3,
							placement: 'bottomLeft'
						});
					}
					if (sec === 300) {
						notification.open({
							message: 'Необходимо выйти из карточки звонка',
							description: 'Отправлено уведомление',
							duration: 3,
							placement: 'bottomLeft'
						});
						// this.$http.post("/operators/notification",
						// 	{
						// 	},
						// 	() => {
						// 	})
					}
				}, 1000);
			}


		},
		endCallActive() {
			
			// тут можно чето делать когда звонок завершен
			// this.stopCall = true
			this.callActive = false

			this.saveFieldsPhone()
			this.saveActionsPhone()

			this.$http.post("/flow/callresult",
				{
					data: this.data_phone
				},
				() => {
				})

			// this.$http.post("/flow/questions",
			// 	{
			// 		data: this.data_phone
			// 	},
			// 	() => {
			// 	})
		},
		...mapActions({ startCall: 'call/startCall', stopCall: 'call/stopCall' }),
		countValidOffers(offers) {
			let count = 0
			offers.forEach((offer) => {
				if (!offer.send) {
					let empty = offer.Dropdown.length
					offer.Dropdown.forEach((field) => {
						if (field.valid) --empty
					})
					if (empty === 0) {
						count++
					}
				}
			})
			return count
		},
		saveActionList(newList) {
			this.actionListPhone = newList
			this.saveActionsPhone()
		},
		closeOutgoingCall() {
			this.$http.post("/flow/call_add_action",
				{
					phone: this.data_phone.number,
					action: this.addAction,
					scenario: this.data_phone.scenario,
					base: this.data_phone.base
				},
				() => {
				})
			if (this.callback_checkbox || this.callback_checkbox_noans) {
				if (this.callback_checkbox_noans) { this.callback_time = 2 }
				this.$http.post("/flow/callback",
					{
						phone: this.data_phone.number,
						callback_time: this.callback_time,
						callback_time_min: this.callback_time_min,
						scenario: this.data_phone.scenario,
						wish: this.callback_checkbox
					},
					() => {
					})
			}
			if (this.comment !== '') {
				this.$http.post("/flow/comment",
					{
						phone: this.data_phone.number,
						text: this.comment,
					},
					() => {
					})
			}
			// сохраняем все и закрываем
			this.$router.push('/')
		},
		renderFieldsValue() {
			this.dataFields.forEach((field) => {
				let value = this.data_phone.fields[field.key]
				if (value) {
					if (field.key === 1685683801698 || field.key === 1678184266522 || field.key === 1689597354856) {
						// field.value = [{value:value}]
						let regvalue = []
						regvalue.push(value)
						this.changeValueField(regvalue, 1685683801698)
						this.changeValueField(regvalue, 1678184266522)
						this.changeValueField(regvalue, 1689597354856)
					}
					else {
						field.value = value
					}
				} else {
					field.value = ''
				}
			})
		},
		renderOffersTabs() {
			this.dataOffers.forEach(offer => {
				// offer.send = false
				let itemTab = this.tabs.find(tab => tab.name === offer.type)
				if (!itemTab) {
					this.tabs.push(
						{
							name: offer.type,
							content: []
						}
					)
				}
				itemTab = this.tabs.find(tab => tab.name === offer.type)
				const dropdownField = () => {
					let res = []
					// console.log(this.dataFields, '      this.dataFields this.dataFields this.dataFields this.dataFields')
					Object.keys(offer.fields).forEach(fieldKey => {
						let field = this.dataFields.find(field => +field.key === +fieldKey)
						if (field) {
							res.push({
								name: field ? field.name : 'error',
								key: fieldKey,
								valid: false,
								// display: false,
							})
						}
					})
					return res
				}
				if (itemTab) {
					let criterios = []
					if (offer.criterios) { criterios = offer.criterios }
					itemTab.content.push(
						{
							title: offer.name,
							subtitle: offer.description,
							status: false,
							send: false,
							display: false,
							key: offer.key,
							Dropdown: dropdownField(),
							view: offer.view,
							criterios: criterios,
							type: offer.type,
							url: offer.url
						}
					)
				}
			})
			this.dataFields.forEach(field => {
				field.display = ''
			})
			this.addFilledFields()
			this.checker()
		},
		changeDisplayFields(fields) {
			//console.log(fields, '                ======================');
			if (Array.isArray(fields)) {
				this.displayFieldOffer = fields
			}
			this.viewFields.forEach(field => {
				field.display = ''
			})
			if (this.displayFieldOffer) {
				this.displayFieldOffer.forEach(field => {
					this.viewFields.find((item) => +field.key === +item.key).display = field.valid ? 'success' : 'error'
				})
			}

		},
		changeValueField(value, key) {
			// console.log(value, '        value input')
			// console.log(key, '        key input')
			this.dataFields.forEach(field => {
				if (field.key === key) {
					field.value = value
				}
			})
			// this.dataFields.find(field => {
			// 	return field.key === key
			// }).value = value
			// console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")

			this.addFilledFields()
			this.changeDisplayFields(false)
		},
		filtersViewFields() {
			// тут фильтрация вывода полей, пока не используется
			let render = []
			// if (this.filterFields.arrFields.length) {
			// 	this.filterFields.arrFields.forEach(fieldKey => {
			// 		render.push(this.dataFields.find(item => +item.key === fieldKey))
			// 	})
			// } else {
			render = this.dataFields
			// }
			this.viewFields = render
		},
		addFilledFields(key) {
			if (key) {
				this.filledFields.push(key)
			} else {
				let filled = []
				this.dataFields.forEach(field => {
					if (field.value) filled.push(field.key)
				})
				this.filledFields = filled
			}
			this.changeFieldOffers()
		},
		changeFieldOffers() {
			//console.log(this.dataOffers)
			this.tabs.forEach(tab => {
				tab.content.forEach((offer) => {
					offer.Dropdown.forEach((field) => {
						if (this.filledFields.find(fieldKey => +fieldKey === +field.key)) {
							field.valid = true
						} else {
							field.valid = false
						}
						if (offer.criterios.length > 0) {
							offer.criterios.forEach((criterion) => {
								if (criterion.id == field.key) {
									//let key = criterion.id
									let value = this.dataFields.find(fieldKey => +fieldKey.key === +field.key).value
									let condition = criterion.criterion
									//let statement = value + condition
									if (value !== '') {
										if (condition[0] === '>' || condition[0] === '<') { // костыль если условие цифровое
											if (condition[0] === '>') {
												if (value >= Number(condition.replace(/\D/g, ''))) {
													field.valid = true
												}
												else {
													field.valid = false
												}
											}
											if (condition[0] === '<') {
												if (value <= Number(condition.replace(/\D/g, ''))) {
													field.valid = true
												}
												else {
													field.valid = false
												}
											}
										}
										else { // костыль если условие - регион
											if (condition.split(',').indexOf(value[0]) >= 0) {
												field.valid = true
											}
											else {
												field.valid = false
											}
										}
									}
								}
							})
						}
					})
				})
			})

			let _this = this
			let finalFields = []
			_this.dataOffers.forEach(offer => {
				Object.keys(offer.fields).forEach(field_in_offer => {
					_this.dataFields.forEach(field => {
						if (field_in_offer.toString() === field.key.toString()) {
							finalFields.push({
								display: field.display, key: field.key, name: field.name, slag: field.slag, type: field.type, options: field.options, value: field.value
							})
						}
					})
				})
			})

			finalFields = finalFields.filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.key === value.key
				))
			)

			_this.viewFields = finalFields
		},
		checker() {
			let offers_toDelete = []
			let offers_suitable = []
			this.$http.post("/checker/check",
				{
					phone: this.$route.params.id
				},
				(data) => {
					if (data.offers_toDelete.length > 0) {
						data.offers_toDelete.forEach((item) => {
							offers_toDelete.push(item)
						})
					}
					if (data.offers_suitable.length > 0) {
						data.offers_suitable.forEach((item) => {
							offers_suitable.push(item)
						})
					}
					this.tabs.forEach((tab) => {
						// console.log(tab)
						tab.content.forEach((offer) => {
							offers_toDelete.forEach((item) => {
								if (offer.key === item) {
									tab.content.splice(tab.content.findIndex((offer) => offer.key === item), 1)
								}
							})
							offers_suitable.forEach((item) => {
								if (offer.key === item) {
									offer.title = '🔥' + offer.title + '🔥'
									offer.subtitle = '⚡НОМЕР ОДОБРЕН⚡' + offer.subtitle
								}
							})
						})
					})
				})
		},
		sendOffer(keyOffer) {
			this.addAction = 'Положительный'
			this.$http.post("/offers/send",
				{
					id: keyOffer,
					phone: this.data_phone.number,
					scenario_id: this.data_phone.scenario,
					fields: this.dataFields,
					//record_id:this.data_phone.number+this.$store.state.call.call_id
				},
				() => {
				})
			// тут тоже можно отправлять оффер ))
			this.tabs.forEach((tab) => {
				tab.content.forEach(offer => {
					if (offer.key === keyOffer) {
						this.sendOffers.push({
							title: offer.name,
							subtitle: offer.subtitle,
							status: 'success',
							Dropdown: []
						})
					}
				})
			})
		},
		sendOfferSecondHand(keyOffer) {
			this.addAction = 'Положительный'
			this.$http.post("/offers/send",
				{
					id: keyOffer,
					phone: this.data_phone.number,
					scenario_id: this.data_phone.scenario,
					fields: this.dataFields,
					secondhand: true,
					click: this.offer_send_count,
					//record_id:this.data_phone.number+this.$store.state.call.call_id
				},
				() => {
				})
			// тут тоже можно отправлять оффер ))
			this.tabs.forEach((tab) => {
				tab.content.forEach(offer => {
					if (offer.key === keyOffer) {
						this.sendOffers.push({
							title: offer.name,
							subtitle: offer.subtitle,
							status: 'success',
							Dropdown: []
						})
					}
				})
			})
			this.offer_send_count = this.offer_send_count + 1
		},

		sendOffer_else(keyOffer) {
			this.addAction = 'Положительный'
			this.$http.post("/offers/send",
				{
					else: true,
					id: keyOffer,
					phone: this.data_phone.number,
					scenario_id: this.data_phone.scenario,
					fields: this.dataFields,
					//record_id:this.data_phone.number+this.$store.state.call.call_id
				},
				() => {
				})
			// тут тоже можно отправлять оффер ))
			this.tabs.forEach((tab) => {
				tab.content.forEach(offer => {
					if (offer.key === keyOffer) {
						this.sendOffers.push({
							title: offer.name,
							subtitle: offer.subtitle,
							status: 'success',
							Dropdown: []
						})
					}
				})
			})
		},
		sendOfferSecondHand_else(keyOffer) {
			this.addAction = 'Положительный'
			this.$http.post("/offers/send",
				{
					else: true,
					id: keyOffer,
					phone: this.data_phone.number,
					scenario_id: this.data_phone.scenario,
					fields: this.dataFields,
					secondhand: true,
					click: this.offer_send_count,
					record_id: this.data_phone.number + this.$store.state.call.call_id
				},
				() => {
				})
			// тут тоже можно отправлять оффер ))
			this.tabs.forEach((tab) => {
				tab.content.forEach(offer => {
					if (offer.key === keyOffer) {
						this.sendOffers.push({
							title: offer.name,
							subtitle: offer.subtitle,
							status: 'success',
							Dropdown: []
						})
					}
				})
			})
			this.offer_send_count = this.offer_send_count + 1
		},
		sendSms(keyOffer) {
			let phone = this.real_phone
			let smstemplate_key = 0
			this.smstemplates.forEach(item => {
				if (item.name === this.smstemplate_value) {
					smstemplate_key = item.id
				}
			})
			this.$http.post("/sms/get",
				{
					phone: phone,
					smstemplate: smstemplate_key,
					sms_callback: this.sms_callback,
					scenario: this.data_phone.scenario,
					offer_key: keyOffer
				},
				() => {
					notification.open({
						message: 'СМС отправлена',
						description: '',
						duration: 2,
						placement: 'bottomRight'
					});
				})

		},

		saveFieldsPhone() {
			let bufferFields = {}
			this.dataFields.forEach((field) => {
				if (field.value) {
					bufferFields[field.key] = field.value
				}
			})
			this.data_phone.fields = bufferFields
		},
		saveActionsPhone() {
			this.data_phone.actions = this.actionListPhone
		},
		saveRecordAndTime() {
			this.data_phone?.data_call.push(Object.assign({}, this.dataCallStore))
		},
		offerTabDisplayFalse() {
			// console.log('new click tabs')
			// console.log(this.tabs, '                 this.tabsthis.tabsthis.tabsthis.tabs');
			this.tabs.forEach((tab) => {
				tab.content.forEach((card) => {
					card.display = false
				})
			})
			this.changeDisplayFields([])
		},
		blacklist(phone) {
			const result = confirm('Добавить этот номер в чёрный список?')
			if (result) {
				this.$http.post("/clients/blacklist",
					{
						phone: phone
					},
					() => {
					})
			}
		},
	},
	mounted() {
		this.$store.state.call.awaiting = 0

		this.numberPhone = this.$route.params.id
		this.real_phone = this.numberPhone
		// console.log(this.$store.state)
		// попытка начать звонок через стор
		// this.startCall(this.numberPhone)
		// сначала мы получаем дату по номеру, после ответа получаем все поля, после запускаем функцию для объединения значений!!!!
		this.addListAction = JSON.parse(localStorage.actions)
		
		this.$http.post("/flow/select",
			{
				number: this.numberPhone
			},
			(data) => {
				console.log(data, 8)
				console.log(this.$store.state.call, 7)
				this.data_phone = data
				console.log(this.data_phone, 8)
				if (this.data_phone.scenario == 'incoming') { this.data_phone.scenario = this.$store.state.call.incomingScenario }
				// console.log('!!!!!!!!!!!!!!!!!!!!!!!' + this.data_phone.scenario)
				this.$http.post("/scenarios/calltext",
					{
						phone: this.$route.params.id,
						scenario: this.data_phone.scenario
					},
					(data) => {
						console.log(data, 2)
						this.scriptText = data
					})
				// this.dataFields = JSON.parse(localStorage.questions)
				// this.dataOffers = JSON.parse(localStorage.offers)
				// this.loading.data = false
				this.$http.post("/offers/all",
					{
						scenario: this.data_phone.scenario,
						elseoffers: true
					},
					(data) => {
						console.log(data, 1)
						this.dataOffers = data
						this.renderOffersTabs()
					})
				this.$http.post("/settings/checkwarningtime",
					{
					},
					(data) => {
						console.log(data, 3)
						this.timeOp = data.first
						this.timeOp2 = data.sec
					})

				this.renderFieldsValue()
				this.filtersViewFields()
			})
		if (this.$store.state.call.loading.SimpleUser) {
			this.clickStartCall()
		}
	},
	unmounted() {
		this.$store.state.call.awaiting = 1
		if (this.statusCall === 'call') {
			this.minutes = '00'
			this.seconds = '00'
			this.statusCall = 'end'
			this.stopCall()
			notification.open({
				message: 'Звонок принудительно завершён',
				description: '',
				duration: 3,
				placement: 'bottomLeft'
			})
		}
		this.statusCall = 'view'
		this.interval = 0
	}
}
</script>

<style scoped>

.creditRate {
	display: flex;
	flex-direction: column;
  }
.textCred{
	width: 100px;
	border: ;
}
.outgoing_call_page {
	background: #F0F2F5;
	padding: 0 15px 15px 0px;
}

.outgoing_call_data_phone,
.data_phone_menu_tabs {
	background: #FFFFFF;
	padding: 0 24px 15px;
	margin-bottom: 15px;
	display: flex;
	gap: 20px;
}

.flow_main__card_score {
	display: flex;
	gap: 20px;
}

.data_phone__inform {
	flex: auto;
}

/*.data_phone_menu_tabs {*/
/*    margin-bottom: 15px;*/
/*    background: #FFFFFF;*/
/*	padding: 0 24px 15px;*/
/*}*/

.data_phone__inform_value {
	display: flex;
	gap: 30px;
	align-items: end;
}

.data_phone__scoring {
	flex: min-content;
}

.data_phone_menu_tabs_history {
	display: flex;
	gap: 30px;
}

.data_phone_menu_tabs_history>div:nth-child(1) {
	min-width: 46%;
}

.number_inform_form {
	/*display: flex;*/
	/*flex-direction: column;*/
	columns: auto 4;
	column-gap: 30px;
}

.number_inform_form_item {
	page-break-inside: avoid;
	break-inside: avoid;
}


.results_call {
	background: #FFFFFF;
	border-radius: 2px;
	margin-top: 15px;
	padding: 15px;
}

.results_call_title {
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	color: rgba(0, 0, 0, 0.85);
	text-align: center;
	padding-top: 35px;
	margin-bottom: 35px;
}

.action_list_phone {
	background: #FFFFFF;
	border-radius: 2px;
	margin-top: 15px;
}

.blacklist {

	border-radius: 2px;
	margin-top: 20px;
}

.action_list_phone_card {
	display: flex;
	justify-content: space-between;
	padding: 12px 10px 12px 15px;
	box-shadow: inset 0 -1px 0 #F0F0F0;
}

.add_action_list {
	padding: 25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.add_action_list__btn {
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

/*.end_call_outgoing {*/
/*    margin: 50px 60px;*/
/*}*/

.end_call_comment {
	width: calc(87% - 15px);
}

.end_call_outgoing {
	padding-right: 120px;
}



/*.resultCall {*/
/*    padding: 0 40px;*/
/*}*/

.checked_script_call {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-right: 30px;
}

.text_script_call {
	width: 40%;
	height: 100%;
	padding: 15px;
	border-right: 1px solid #D9D9D9;
	margin-right: 30px;
	white-space: pre-wrap;
}

.backward_button {
	margin-left: 40px;
	margin-bottom: 15px;
}

.sms_card {
	width: 200px;
	max-width: 200px;
}
</style>















