import {createRouter, createWebHistory} from "vue-router";

import FlowPage from "@/views/FlowPage";
import RegistrationPage from "@/views/LoginPage";
import CallHistory from "@/views/CallHistory";
import OutgoingCall from "@/views/OutgoingCall";
import MyOffers from "@/views/MyOffers";
import SettingUser from "@/views/SettingUser";
import OperatorsAdmin from "@/views/admin/OperatorsAdmin";
import FieldsOfferAdmin from "@/views/admin/FieldsOfferAdmin";
import OffersAdmin from "@/views/admin/OffersAdmin";
import PageOfferAdmin from "@/views/admin/PageOfferAdmin";
import ReportDayOperator from "@/views/Analytics/ReportDayOperator.vue";
import ReportForOperator from "@/views/Analytics/ReportForOperator.vue";
import MonitoringVue from "@/views/Analytics/Monitoring.vue";
// import testTest from "@/views/Analytics/testTest.vue";
import AdminSuperAdmin from "@/views/admin/super/AdminSuperAdmin.vue";
import LidsAdmin from "@/views/admin/LidsAdmin.vue";
import BaseAdmin from "@/views/admin/BaseAdmin.vue";
import ScenarioAdmin from "@/views/admin/ScenarioAdmin.vue";
import ScriptsCall from "@/views/admin/ScriptsCall.vue";
import ScenarioArchiveAdmin from "@/views/admin/ScenarioArchiveAdmin.vue"
import ReportDayOperatorSuperAdmin from "@/views/Analytics/ReportDayOperatorSuperAdmin.vue";
const routes = [
	{
		path: '/',
		name: 'flow',
		component: FlowPage
	},
	{
		path: '/login',
		name: 'login',
		meta: {layout: 'empty'},
		component: RegistrationPage
	},
	{
		path: '/call_history',
		name: 'call_history',
		component: CallHistory
	},
	{
		path: '/call/:id',
		name: 'call',
		component: OutgoingCall
	},
	{
		path: '/offers',
		name: 'offers',
		component: MyOffers
	},
	{
		path: '/report-days-operator',
		name: 'report-days-operator',
		component: ReportDayOperator
	},
	{
		path: '/setting-user',
		name: 'setting-user',
		component: SettingUser
	},
	{
		path: '/operators',
		name: 'admin-operator',
		meta: {role: 'admin'},
		component: OperatorsAdmin
	},
	{
		path: '/field',
		name: 'admin-field',
		meta: {role: 'admin'},
		component: FieldsOfferAdmin
	},
	{
		path: '/all-offers',
		name: 'admin-offers',
		meta: {role: 'admin'},
		component: OffersAdmin
	},
	{
		path: '/offer/:id',
		name: 'admin-offer-page',
		meta: {role: 'admin'},
		component: PageOfferAdmin
	},
	{
		path: '/admins',
		name: 'admin-super-admin',
		meta: {role: 'head_admin'},
		component: AdminSuperAdmin
	},
	{
		path: '/lids/:id',
		name: 'admin-lids',
		meta: {role: 'admin'},
		component: LidsAdmin
	},
	{
		path: '/base',
		name: 'admin-base',
		meta: {role: 'admin'},
		component: BaseAdmin
	},
	{
		path: '/scenario',
		name: 'admin-scenario',
		meta: {role: 'admin'},
		component: ScenarioAdmin
	},
	{
		path: '/scripts-call',
		name: 'admin-scripts-call',
		meta: {role: 'admin'},
		component: ScriptsCall
	},
	{
		path:'/scenarioarchive',
		name:'admin-scenario-archive',
		meta:{role:'admin'},
		component:ScenarioArchiveAdmin
	},
	{
		path:'/monitoring',
		name:'monitoring-vue',
		meta:{role:'admin'},
		component:MonitoringVue
	},
	{
		path:'/report-days-super',
		name:'report-days-super',
		meta:{role:'head_admin'},
		component:ReportDayOperatorSuperAdmin
	},
	{
		path:'/report-foroperator',
		name:'report-foroperator',
		meta:{role:'operator'},
		component:ReportForOperator
	},
	// {
	// 	path: '/test',
	// 	name: 'test',
	// 	component: testTest
	// },
]


const router = createRouter({
	// mode: 'history',
	history: createWebHistory(),
	base: process.env.BASE_URL,
	routes
})

export default router