<template>
	<TitlePage title="Настройки" subtitle="" :border="true"></TitlePage>

	<div id="setting_tab" class="card-container">
		<a-tabs type="card">
			<a-tab-pane key="1" tab="Настройки маршрутизатора">
				<a-form layout="vertical">
					<div style="float: left; width: 40%">
						<a-divider>Настройки скорости обзвона</a-divider>
						<a-form-item label="Длительность звонка, в секундах">
							<a-input type="number" v-model:value="router_settings.avg_talk_time" />
						</a-form-item>
						<a-form-item label="Время поствызовной обработки, в секундах">
							<a-input type="number" v-model:value="router_settings.avg_aftercall_work_time" />
						</a-form-item>
						<a-form-item label="Время отдыха оператора, в секундах">
							<a-input type="number" v-model:value="router_settings.operator_rest_time" />
						</a-form-item>
						<a-form-item label="Регулировка обзвона, %">
							<a-slider :min="0" :max="1000" v-model:value="router_settings.control_factor" />
							<a-input-number v-model:value="router_settings.control_factor" :min="0.00000000000000001" :max="1000" />
						</a-form-item>
					</div>
					<div style="float: left; width: 20%; margin-top: 5px; margin-left: 25px">
						<a-divider>Настройки продуктов</a-divider>
						<a-form-item v-for="(product, index) in product_settings" :key="index"
							:name="['domains', index, 'value']">
							<span style="font-weight: bold;">{{ product.label }}</span>
							<span style="display: flex ; margin-top:20px ;margin-bottom=:10px">Приоритет обзвона</span>
							<template style="display: flex">
								<a-input type="number" v-model:value="product.value" :min="0" :max="500" />

							</template>
							<span>Сценарий для входящих</span>
							<template style="display: flex">

								<a-input style="margin-top: 10px;" v-model:value="product.scenario" :min="0" :max="500" />

							</template>
						</a-form-item>


						<a> <i>Распределение обзвона по продуктам задаётся в процентах, при 100% на обзвон будет отправлено
								расчётное количество номеров без корректировок </i> </a>

					</div>

					<div style="float: left; width: 20%; margin-top: 5px; margin-left: 50px">
						<a-divider>Настройки входящих</a-divider>
						<span style="margin-left: 68px;">Включить входящие звонки:</span>
						<a-checkbox style="margin-left: 10px;" v-model="isChecked" @click="toggleCheckbox()"
							:checked="isChecked"></a-checkbox>
					</div>

					<div style="float: right; width: 100%">
						<a-form-item>
							<a-space>
								<a-button type="primary" @click="router_settings_save">
									Сохранить настройки
								</a-button>
								<a-button type="dashed" @click="router_settings_reset">
									Сбросить настройки
								</a-button>
							</a-space>
						</a-form-item>
					</div>
				</a-form>

			</a-tab-pane>
			<a-tab-pane key="2" tab="Настройки СМС">
				<a-divider> Настройки СМС</a-divider>
				<a-form-item label="Время до перезвона">
					<a-input type="number" v-model:value="sms_callback" />
				</a-form-item>
				<a-button type="primary" @click="router_settings_save">
					Сохранить настройки
				</a-button>
				<a-divider> Шаблоны СМС</a-divider>
				<a-form class="number_inform_form" layout="vertical" style="width: 30%">
					<a-form-item v-for="template of templates" :key="template" :label="template.name">
						<div>
							<a-space>
								<a-textarea :rows="4" v-model:value="template.value" showCount :maxlength="44"
									style="min-width: 500px" />
								<a-button type="primary" danger ghost @click="sms_delete_template(template.id)">Удалить
									шаблон</a-button>
							</a-space>
						</div>
					</a-form-item>
					<a-form-item>
						<a-space>
							<a-button v-if="templates.length > 0" type="primary" @click="sms_settings_save">
								Сохранить настройки
							</a-button>
							<a-button type="primary" @click="popup.newTemplate = true">
								Добавить шаблон
							</a-button>
						</a-space>
					</a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="3" tab="Настройки операторов">
				<a-form class="number_inform_form" layout="vertical" style="width: 30%">
					<a-form-item label="Время первого уведомления (сек)">
						<a-input-number :min="1" :max="500"
							v-model:value="operator_settings.first_notification_time"></a-input-number>
					</a-form-item>
					<a-form-item label="Время второго уведомления (сек)">
						<a-input-number :min="1" :max="500"
							v-model:value="operator_settings.second_notification_time"></a-input-number>
					</a-form-item>
				</a-form>
				<a-button type="primary" @click="op_settings_save">Сохранить настройки</a-button>
			</a-tab-pane>
			<a-tab-pane key="4" tab="Баланс операторов (ТЕСТОВЫЙ ПОДСЧЁТ)">
				<a-table :columns="users.columns" :data-source="users.data" :loading="loading.users"
					:pagination="pagination" size="small">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'operation'">
							<span>
								<a-popconfirm title="Обнулить баланс?" @confirm="balance_clear(record.id)">
									<a>Обнулить баланс</a>
								</a-popconfirm>
							</span>
						</template>
					</template>
				</a-table>
			</a-tab-pane>
		</a-tabs>
	</div>

	<BasicModal title="Шаблон" :visible="popup.newTemplate" @closePopup="closePopup()" :btn-ok="'Добавить'"
		:callback="sms_add_template">
		<a-form layout="vertical">
			<a-form-item label="Название">
				<a-input v-model:value="newTemplate.name" />
			</a-form-item>
		</a-form>
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import { notification } from "ant-design-vue";
import BasicModal from "@/components/modal/BasicModal.vue";

export default {
	name: "SettingUser",
	components: {
		BasicModal,
		TitlePage,
	},
	mounted() {
		this.loading.users = true
		this.$http.post("/operators/all",
			{},
			(data) => {
				this.users.data = data
				this.loading.users = false
			})
		this.$http.post("/settings/loadTime",
			{},
			(data) => {

				this.operator_settings.first_notification_time = data[0].warning_time_1
				this.operator_settings.second_notification_time = data[0].warning_time_2
			})


	},
	data() {

		this.$http.post("/settings/get_routersettings",
			{},
			(data) => {

				this.isChecked = data.incoming_value
				this.router_settings = data.router_settings
				this.sms_callback = data.sms_callback
				this.product_settings = data.product_settings
			})
		this.$http.post("/sms/smstemplatesget",
			{},
			(data) => {
				this.templates = data
			})
		return {
			isChecked: false,
			router_settings: {
				avg_talk_time: 60,
				avg_aftercall_work_time: 20,
				operator_rest_time: 5,
				control_factor: 75
			},
			product_settings: [
				{ label: "БФЛ", value: 1, scenario: 'вап' },
				{ label: "Залог", value: 0.65, scenario: 'вап' }
			],
			sms_callback: 0,
			popup: {
				newTemplate: false
			},
			newTemplate: {
				name: '',
				value: '',
			},
			templates: [],
			operator_settings: {
				first_notification_time: null,
				second_notification_time: null
			},
			timeData_1: '',
			timeData_2: '',
			loading: {
				users: false
			},
			pagination: {
				total: 100,
				pageSize: 100
			},
			users: {
				columns: [
					{
						title: 'Оператор',
						dataIndex: 'name',
						key: 'name',
						width: 400,
					},
					{
						title: 'Баланс, ₽',
						dataIndex: 'balance',
						key: 'balance',
						width: 200
					},
					{
						title: 'Действия',
						dataIndex: 'operation',
						key: 'operation',
						width: 200
					}
				],
				data: [
					{
						id: 1,
						name: 'op1',
						balance: 35,
					},
					{
						id: 2,
						name: 'op2',
						balance: 123,
					},
					{
						id: 3,
						name: 'op3',
						balance: 166,
					},
				]
			},
		}
	},
	methods: {
		balance_clear(id) {
			this.$http.post("/operators/clearbalance",
				{
					operator_id: id
				},
				() => {
					this.loading.users = true
					this.$http.post("/operators/all",
						{},
						(data) => {
							this.users.data = data
							this.loading.users = false
						})
				})
		},
		toggleCheckbox() {

			this.isChecked = !this.isChecked;

		},
		router_settings_save() {

			const galochkaValue = this.isChecked ? 1 : 0;

			this.$http.post("/settings/set_routersettings",
				{
					galochka: galochkaValue,
					settings: this.router_settings,
					sms_callback: this.sms_callback,
					product_settings: this.product_settings
				},
				(res) => {
					if (res === 'Done') {
						notification.open({
							message: 'Настройки сохранены',
							description: '',
							duration: 2,
							placement: 'bottomRight'
						});
					}
				})
		},
		router_settings_reset() {
			this.router_settings = {
				avg_talk_time: 60,
				avg_aftercall_work_time: 20,
				operator_rest_time: 5,
				control_factor: 75
			}
			this.$http.post("/settings/set_routersettings",
				{
					settings: this.router_settings
				},
				(res) => {
					if (res === 'Done') {
						notification.open({
							message: 'Настройки сброшены к значениям по умолчанию',
							description: 'Маршрутизатор перенастроен',
							duration: 2,
							placement: 'bottomRight'
						});
					}
				})
		},
		sms_settings_save() {
			this.$http.post("/sms/smstemplatesadd",
				{
					templates: this.templates
				},
				(res) => {
					if (res === 'Done') {
						notification.open({
							message: 'Настройки СМС сохранены',
							description: '',
							duration: 2,
							placement: 'bottomRight'
						});
					}
				})
		},
		closePopup() {
			this.popup = {
				newTemplate: false
			}
			this.newTemplate = {
				name: '',
				value: '',
			}
		},
		sms_add_template() {
			// console.log(this.newTemplate.name)
			let key = Date.now()
			this.templates.push({ id: key, name: this.newTemplate.name, value: '' })
			this.$http.post("/sms/smstemplatesadd",
				{
					templates: this.templates
				},
				() => {
				})
			this.closePopup()
		},
		sms_delete_template(template_id) {
			this.$http.post("/sms/smstemplatesdelete",
				{
					template_id: template_id
				},
				(res) => {
					if (res === 'Done') {
						this.templates.splice(this.templates.findIndex((item) => item.id === template_id), 1)
					}
				})
		},
		op_settings_save() {
			let first = this.operator_settings.first_notification_time
			let sec = this.operator_settings.second_notification_time
			this.$http.post("/settings/operatorTime",
				{
					first: first, sec: sec
				},
				(res) => {
					if (res === "Done") {
						notification.open({
							message: 'Настройки сохранены',
							description: '',
							duration: 2,
							placement: 'topRight'
						});
					}
				}
			)

		},
	}
}


</script>

<style scoped>
.card-container p {
	margin: 0;
}


.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
	padding: 16px;
	background: #fff;
}


#setting_tab .ant-tabs-tab {
	border-bottom: 2px solid #1890FF !important;
}

#setting_tab .ant-tabs-nav-list>.ant-tabs-tab {
	border-bottom: 2px solid #1890FF !important;
}
</style>