<template>
	<div class="title_page" :class=" border ? 'title_border' : ''">
		<!--		есть такой компонент PageHeader (увидел после того как сделал)-->
		<div class="title_page_head">
			<div class="title_page_head_main">
				<ArrowLeftOutlined v-if="backPage" class="back_arrow" :style="{ fontSize: '15px' }"
						@click="back()"/>
				<div class="title_page__title" v-if="!loading">
					{{ title }}
					<span class="title_page__subtitle">{{ subtitle }}</span>
				</div>
				<div class="title_page__title" v-else>
					<a-skeleton-input style="width: 260px; margin-top: 2px" :active="true" />
				</div>
			</div>
			<slot name="title_right"></slot>
		</div>

		<slot></slot>

	</div>
</template>

<script>
import {ArrowLeftOutlined} from '@ant-design/icons-vue';

export default {
	name: "TitlePage",
	props: [
		'title', 'subtitle', 'backPage', 'border', 'loading'
	],
	components: {
		ArrowLeftOutlined
	},
	methods: {
		back(){
			this.backPage()
		}
	}
}
</script>

<style scoped>

.title_border {
	border: 1px solid #D9D9D9;
}
.title_page {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    padding: 16px 24px;
    min-height: 72px;
    width: calc(100% + 20px);
    /*width: 100%;*/
    background: #FFFFFF;
	margin: -10px -10px 15px -10px;
    /*justify-content: space-between;*/
}

.title_page_head_main,
.title_page_head {
    display: flex;
    align-items: center;
}

.title_page_head {
	justify-content: space-between;
}

.title_page__title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}

.title_page__subtitle {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin-left: 10px;
}

.back_arrow {
    margin-right: 10px;
    padding-top: 2px;
}

.back_arrow:hover {
    color: #40a9ff;
    cursor: pointer;
    transition: color .3s;
}
</style>