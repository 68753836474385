<template>
	<template v-for="(card, i) of offersArr" :key="i">
		<div class="bg_white_container tab_card" v-if="!card.send">

			<div>
				<div class="tab_card_title">
					{{ card.title }}
					<span class="tab_card_subtitle">{{ card.subtitle }}</span>
				</div>
				<a-dropdown-button :disabled="!lengthField(card.Dropdown)" @click="displayFieldsThisOffer(card)"
					:type="card.display ? 'primary' : ''">
					<!--@click="handleButtonClick"-->
					{{
						lengthField(card.Dropdown) > 0 ? `Еще ${lengthField(card.Dropdown)} поля` : 'Заполнено'
					}}
					<template #overlay>

						<a-menu v-if="lengthField(card.Dropdown) > 0"> <!--@click="handleMenuClick"-->
							<template v-for="item in card.Dropdown" :key="item.key">
								<a-menu-item v-if="!item.valid">
									{{ item.name }}
								</a-menu-item>
							</template>
						</a-menu>
					</template>
				</a-dropdown-button>
			</div>
			<div v-if="card.type !== 'Кредитные продукты' && card.type !== 'МФО'" class="buttons_div">
				<CheckCircleOutlined v-if="card.send || allSend" :style="{ color: '#1890FF', fontSize: '25px' }" />
				<a-button class="send_button" v-else :disabled="lengthField(card.Dropdown)" type="primary"
					@click="sendOffer(card)">
					Отправить
				</a-button>
				<CheckCircleOutlined v-if="card.send || allSend"
					:style="{ display: 'none', color: '#1890FF', fontSize: '25px' }" />
				<a-button class="send_button" style="margin-top: 3%" v-else :disabled="lengthField(card.Dropdown)"
					type="dashed" @click="sendOfferSecondHand(card)">
					Во вторые руки
				</a-button>
				<CheckCircleOutlined v-if="card.send || allSend" :style="{ color: '#1890FF', fontSize: '25px' }" />
			</div>
			<div v-if="card.type === 'Кредитные продукты'" class="buttons_div">
				<CheckCircleOutlined v-if="card.send || allSend" :style="{ color: '#1890FF', fontSize: '25px' }" />
				<a-button class="apply_button" v-else :disabled="lengthField(card.Dropdown)" type="primary"
					@click="applyOffer(card)">
					Перейти к оформлению
				</a-button>
				<CheckCircleOutlined v-if="card.send || allSend" :style="{ color: '#1890FF', fontSize: '25px' }" />
			</div>
			<div v-if="card.type === 'МФО'" class="buttons_div">
				<CheckCircleOutlined v-if="card.send || allSend" :style="{ color: '#1890FF', fontSize: '25px' }" />
				<a-button class="send_button" style="margin-top: 3%" type="primary" @click="sendSms(card)"
					:disabled="sms_sends > 0">
					📧 Отправить СМС
				</a-button>
				<CheckCircleOutlined v-if="card.send || allSend" :style="{ color: '#1890FF', fontSize: '25px' }" />
			</div>

		</div>
	</template>
</template>

<script>
import { CheckCircleOutlined } from '@ant-design/icons-vue';

export default {
	name: "OffersCards",
	props: ['offersArr', 'allSend'],
	components: {
		CheckCircleOutlined,
	},
	data() {
		return {
			sms_sends: 0
			// offers: [],
			// typeDropdown: false,
		}
	},
	computed: {},
	methods: {
		lengthField(field) {
			return field.filter((field) => !field.valid).length
		},
		// filterFieldsThisOffer(){
		// 	let filter = []
		// 		this.offersArr.Dropdown.forEach((field) => {
		// 			filter.push(field)
		// 		})
		// }
		displayFieldsThisOffer(card, status = true) {
			this.offersArr.forEach(offer => {
				// console.log(offer)
				// console.log(card)
				if (card.key !== offer.key) offer.display = false
			})
			// console.log(this.offersArr, '                offersArr ===================')
			if (status) {
				card.display = !card.display
			} else {
				card.display = false
			}
			this.$emit('displayFieldsThisOffer', card.display ? card.Dropdown : [])
		},
		sendOffer(offer) {
			// отправка оффера
			// console.log(offer);
			this.$emit('sendOffer', offer.key)
			offer.send = true
			this.displayFieldsThisOffer(offer, false)
		},
		sendOfferSecondHand(offer) {
			// отправка оффера
			// console.log(offer);
			this.$emit('sendOfferSecondHand', offer.key)
			offer.send = true
			this.displayFieldsThisOffer(offer, false)
		},
		applyOffer(offer) {
			let id = this.$store.state.user.user.id
			console.log(offer.url + "&sub3=operator-" + id)
			window.open(offer.url + "&sub3=operator-" + id);
		},
		sendSms(offer) {
			this.$emit('sendSms', offer.key)
			this.sms_sends++
		},
	},
	mounted() {
		this.$nextTick(function () {
			let offer = this.offersArr[0]
			if (offer.view === 'scenario') {
				this.displayFieldsThisOffer(offer)
			}
		})
	},
	watch: {}
}
</script>

<style scoped>
.tab_card {
	border: 1px solid #F0F0F0;
	padding: 15px 20px;
	width: calc(100% - 15px);
	/*width: 100%;*/
	display: flex;
	gap: 30px;
	align-items: end;
	margin-bottom: 5px;
	justify-content: space-between;
}

.tab_card_title {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.85);
	margin-bottom: 8px;
}

.tab_card_subtitle {
	font-size: 14px;
	color: rgba(70, 70, 70, 0.85);
}

.send_button {
	min-width: 180px;
}

.apply_button {
	width: auto;
}

.buttons_div {
	max-width: 180px;
}</style>