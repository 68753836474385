<template>
	<TitlePage title="Базы лидов" subtitle="" :border="true">
		<template v-slot:title_right>
			<div class="button-group">
				<a-button type="primary" @click="popup.newBase = true">
					Добавить базу
				</a-button>
				<a-popconfirm title="Сброс затронет только звонки по МФО + БАНКИ" @confirm="clearPhonedBases_mfo">
					<a-button type="primary">
						Сбросить базы МФО + БАНКИ
					</a-button>
				</a-popconfirm>
				<a-popconfirm title="Сброс затронет только звонки по БФЛ" @confirm="clearPhonedBases_bfl">
					<a-button type="primary">
						Сбросить базы БФЛ
					</a-button>
				</a-popconfirm>
				<a-popconfirm title="Сброс затронет только звонки по залогам" @confirm="clearPhonedBases_zalog">
					<a-button type="primary">
						Сбросить базы Залогов
					</a-button>
				</a-popconfirm>
			</div>
		</template>
	</TitlePage>


	<a-table :columns="base.columns" :data-source="base.data" :pagination="{ pageSize: 100 }" :loading="loading.base"
		size="middle">
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'phone'">
				{{ record.phone }}
			</template>
			<template v-else-if="column.key === 'scenario'">
				<span>
					<a-tag v-for="script in record.scenario" :key="script" :color="'green'">
						{{ script.toLowerCase().substring(0, 7) }}...
					</a-tag>
				</span>
			</template>
			<template v-else-if="column.key === 'active'">
				<span>
					<a-tag :color="record.active ? 'green' : 'red'">
						{{ record.active ? 'включена' : 'выключена' }}
					</a-tag>
				</span>
			</template>
			<template v-else-if="column.key === 'operation'">
				<span>
					<a v-if="record.active === 1" @click="startBase(record.id)">Остановить</a>
					<a v-if="record.active === 0" @click="startBase(record.id)">Запустить</a>
					<a-divider type="vertical" />
					<a @click="$router.push(`/lids/${record.id}`)">Посмотреть</a>
					<a-divider type="vertical" />
					<a @click="addLidsButton(record.id)">Подгрузить лиды</a>
					<a-divider type="vertical" />
					<a @click="changeBase(record.id)">Редактировать</a>
					<a-divider type="vertical" />
					
					<a @click="exelRout(record.id)">Результаты звонков
						
						<svg width="20px" height="20px" viewBox="-4 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M5.112.006c-2.802 0-5.073 2.273-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.902-20.31h-31.946z" fill-rule="evenodd" clip-rule="evenodd" fill="#45B058"/><path d="M19.429 53.938c-.216 0-.415-.09-.54-.27l-3.728-4.97-3.745 4.97c-.126.18-.324.27-.54.27-.396 0-.72-.306-.72-.72 0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826c-.09-.126-.145-.27-.145-.414 0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646c.127-.18.324-.27.541-.27.378 0 .738.306.738.72 0 .144-.036.288-.127.414l-3.619 4.808 3.891 5.149c.09.126.125.27.125.414 0 .396-.324.738-.719.738zm9.989-.126h-5.455c-.595 0-1.081-.486-1.081-1.08v-10.317c0-.396.324-.72.774-.72.396 0 .721.324.721.72v10.065h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278.162.144.253.342.253.54 0 .36-.307.72-.703.72-.145 0-.307-.054-.432-.162-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z" fill="#ffffff"/><path d="M55.953 20.352v1h-12.801s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z" fill-rule="evenodd" clip-rule="evenodd" fill="#349C42"/><path d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801l-18.905-20.352z" opacity=".5" fill-rule="evenodd" clip-rule="evenodd" fill="#ffffff"/></svg>
					
					</a>
					
					
					<a-divider type="vertical" />
					<a-popconfirm title="Очистить записи старше 3 дней?" @confirm="clearFor3Days(record.id)">
					
						<a>Очистить старое</a>
					</a-popconfirm>
					<a-divider type="vertical" />
					<a-popconfirm title="Удалить базу?" @confirm="deleteBase(record.id)">
						<a>Удалить</a>
					</a-popconfirm>
				</span>
			</template>
		</template>
	</a-table>


	<BasicModal title="База лидов" :visible="popup.newBase" @closePopup="closePopup()" :btn-ok="'Сохранить'"
		:callback="saveBase">
		<a-form layout="vertical">

			<a-form-item label="Название">
				<a-input v-model:value="newBase.name" />
			</a-form-item>
			<a-form-item label="Api для загрузки в CRMCalls">
				<a-input v-model:value="newBase.api" />
			</a-form-item>
			<template v-if="changeIdx === -1">
				<!--				<a-form-item-->
				<!--						name="radio-button"-->
				<!--						label="Добавить номера"-->
				<!--				>-->
				<!--					<a-radio-group v-model:value="newBase.radioButton">-->
				<!--						<a-radio-button value="text">Текстом</a-radio-button>-->
				<!--						<a-radio-button value="file">С файла</a-radio-button>-->
				<!--					</a-radio-group>-->
				<!--				</a-form-item>-->
				<a-form-item label="Добавить номера">
					<a-textarea @change="renderBasePhone" v-model:value="newBase.dataPhone.text" style="height: 150px" />
				</a-form-item>
				<p>Номера должны быть в формате 7(0000)000-00-00, +7(000)000-00-00 , 8(000)000-00-00 или 8(000)000-00-00 по
					одному номеру в строке</p>
				<!--				<a-form-item v-if="newBase.radioButton === 'file'">-->
				<!--					<a-form-item name="dragger" no-style>-->
				<!--						&lt;!&ndash;					<a-upload-dragger v-model:fileList="newBase.dataPhone.dragger" name="files" action="/upload.do">&ndash;&gt;-->
				<!--						&lt;!&ndash;						<p class="ant-upload-drag-icon">&ndash;&gt;-->
				<!--						&lt;!&ndash;							<InboxOutlined/>&ndash;&gt;-->
				<!--						&lt;!&ndash;						</p>&ndash;&gt;-->
				<!--						&lt;!&ndash;						<p class="ant-upload-text">Click or drag file to this area to upload</p>&ndash;&gt;-->
				<!--						&lt;!&ndash;						<p class="ant-upload-hint">Support for a single or bulk upload.</p>&ndash;&gt;-->
				<!--						&lt;!&ndash;					</a-upload-dragger>&ndash;&gt;-->
				<!--						тут выводится XlsDrop-->
				<!--						<XlsDrop :callback="loadFile"/>-->
				<!--					</a-form-item>-->
				<!--				</a-form-item>-->
			</template>
		</a-form>
	</BasicModal>
	<BasicModal title="База лидов" :visible="popup.addLids" @closePopup="closePopup()" :btn-ok="'Начать загрузку'"
		:callback="getBase">
		<a-tabs type="card">
			<a-tab-pane key="1" tab="Перезгрузка готовых баз по методам">
				<a-form layout="vertical">
					<a-form-item label="Метод">
						<a-select v-model:value="addLids.method"
							:options="addMethods.map(item => ({ value: item.value, label: item.label }))"></a-select>
					</a-form-item>
					<a-form-item label="Условия запроса">
						<a-select v-model:value="addLids.conditions"
							:options="addСonditions.map(item => ({ value: item.value }))"></a-select>
					</a-form-item>
					<a-form-item label="Регион">
						<a-select v-model:value="addLids.region" mode="multiple"
							:options="regions.map(v => ({ value: v }))"></a-select>
					</a-form-item>
					<a-form-item v-if="addLids.region.length > 0">
						<div
							style="display: flex; margin-bottom: 8px; width: 100%; gap: 15px; align-items: center; padding-right: 23px;">
							<span style="width: 100%">Регион</span>
							<span style="width: 100%"> Лимит и свежесть </span>
						</div>
						<div v-for="(region, index) in addLids.region" :key="index"
							style="display: flex; margin-bottom: 8px; width: 100%; gap: 15px; align-items: center">
							<a-input v-model:value="addLids.region[index]" style="width: 100%" :disabled="true" />
							<a-input-number v-model:value="addLids.limit[index]" style="width: 120%" placeholder="Лимит" />
							<a-input-number v-if="addLids.conditions === 'По свежести'"
								v-model:value="addLids.day_min[index]" defaultValue=0 style="width: 90%"
								placeholder="Мин. свежесть" />
							<a-input-number v-model:value="addLids.day_max[index]" style="width: 90%"
								placeholder="Макс. свежесть" />
						</div>
					</a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Загрузка файлом">
				<div class="container">
					<div class="large-12 medium-12 small-12 cell">
						<label>
							<input type="file" id="file" ref="file" accept=".csv" @change="handleFileUpload($event)" />
						</label>
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>

		<p>Загрузка занимает в среднем 2-3 минуты</p>
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import BasicModal from "@/components/modal/BasicModal";
// import { InboxOutlined } from '@ant-design/icons-vue';
import Papa from "papaparse";
import { notification } from "ant-design-vue";


export default {
	name: "BaseAdmin",
	components: {
		TitlePage,
		BasicModal,
		// InboxOutlined,
	
	},
	data() {
		this.$http.post("/bases/all",
			{},
			(data) => {

				this.base.data = data.bases
				this.loading.base = false

			})
		this.$http.post("/bases/regions",
			{
			},
			(data) => {
				this.regions = data
			})
		return {
			selectedDate:'',
			file: '',
			content: [],
			parsed: false,
			changeIdx: -1,
			newBase: {
				name: '',
				api: '',
				dataPhone: {
					result: [],
					text: '',
					dragger: ''
				},
				radioButton: 'text',
			},
			addLids: {
				id: '',
				method: '',
				limit: [],
				day_min: [],
				day_max: [],
				region: [],
				radioButton: 'text',
				conditions: ''
			},
			addСonditions: [
				{ value: 'По свежести', index: 1 },
				{ value: 'Без свежести', index: 2 },
				{ value: 'Без свежести FINAL', index: 3 }
			],
			addMethods: [
				{ value: 'crmcalls_geo', label: 'Гео' },
				{ value: 'crmcalls_ddd', label: 'DDD' },
				{ value: 'crmcalls_conversions', label: 'Конверсии' },
				{ value: 'crmcalls_no_call', label: 'Без звонков' },
			],
			regions: ['Австралия', 'Армения'],

			popup: {
				newBase: false,
				addLids: false
			},
			loading: {
				base: true
			},
			base: {
				columns: [
					{
						title: 'id Базы',
						dataIndex: 'id',
						key: 'id'
					},
					{
						title: 'Название',
						dataIndex: 'name',
						key: 'name'
					},
					{
						title: 'Номеров',
						dataIndex: 'phone',
						key: 'phone'
					},
					{
						title: 'Кол-во сценариев',
						dataIndex: 'scenarioCount',
						key: 'scenarioCount'
					},
					{
						title: 'Перегрузка базы',
						dataIndex: 'active',
						key: 'active'
					},

					{
						title: 'Действия',
						dataIndex: 'operation',
						key: 'operation'
					}
				],
				data: [
					// {
					// 	id: '1233333',
					// 	name: 'Прислали',
					// 	phone: '32',
					// 	scenario: ['холодный', 'робот + оператор', 'теплый']
					// },
					// {
					// 	id: '1231233',
					// 	name: 'Сам добавил',
					// 	phone: '121212',
					// 	scenario: ['холодный', 'робот + оператор']
					// },
					// {
					// 	id: '1567333',
					// 	name: 'С робота',
					// 	phone: '121212',
					// 	scenario: ['теплый']
					// }
				]
			}
		}
	},
	methods: {
		handleFileUpload(event) {
			this.file = event.target.files[0]
		},
		renderBasePhone() {
			let phonesStart = this.newBase.dataPhone.text
			let phones
			phones = phonesStart.replace('/,/gi', '\n')
				.split(/\r\n|\r|\n/g)
				.filter(element => element !== '')
				.map(el => el.trim())

			this.newBase.dataPhone.result = phones
		},
		closePopup() {
			this.newBase.name = ''
			this.newBase.api = ''
			this.newBase.dataPhone.text = ''
			this.newBase.dataPhone.result = []
			this.radioButton = 'text'
			this.addLids.conditions = ''
			this.addLids.id = ''
			this.addLids.method = ''
			this.addLids.limit = []
			this.addLids.day_min = []
			this.addLids.day_max = []
			this.addLids.region = []

			this.popup.newBase = false
			this.popup.addLids = false
		},
		saveBase() {
			// тут сохраняем базу и обновляем таблицу запросом наверное )) Предварительно прелоадер true
			this.loading.base = true
			console.log('callbackPopup')
			if (this.newBase.name) {
				let dataChange = {
					id: !(this.changeIdx >= 0) ? Date.now() : this.base.data[this.changeIdx].id,
					date: !(this.changeIdx >= 0) ? new Date().toLocaleDateString('ru-RU', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					}) : this.base.data[this.changeIdx].date,
					scenario: !(this.changeIdx >= 0) ? [] : this.base.data[this.changeIdx].scenario,
					name: this.newBase.name,
					radioButton: this.newBase.radioButton,
					phoneCount: !(this.changeIdx >= 0) ? 0 : this.base.data[this.changeIdx].phone,
					newPhone: !(this.changeIdx >= 0) ? this.newBase.dataPhone.result : [],
				}
				this.$http.post("/bases/insert",
					{
						id: dataChange.id,
						data: this.newBase
					},
					() => {
						this.$http.post("/bases/all",
							{},
							(data) => {

								this.base.data = data.bases
								this.loading.base = false

							})
					})
				// если сделать проверку по ид (ну существование) при сохранении и обновлять таблицу после сохранения, то можно иф убрать
				if (!(this.changeIdx >= 0)) {
					this.base.data.push(dataChange)
					// тут изменяем
				} else if (this.changeIdx >= -1) {
					this.base.data[this.changeIdx] = dataChange
					// тут сохраняем новый
				}
				this.closePopup()
				this.changeIdx = -1
			}
		},
		deleteBase(idBase) {
			this.$http.post("/bases/delete",
				{
					id: idBase
				},
				() => {
					// console.log(data,123)

					//   this.base.data.splice(this.base.data.findIndex((base) => base.id === idBase), 1)

				})
			this.base.data.splice(this.base.data.findIndex((base) => base.id === idBase), 1)
		},
		changeBase(id) {
			this.changeIdx = this.base.data.findIndex(field => field.id === id)
			let field = this.base.data[this.changeIdx]

			this.newBase.name = field.name
			this.newBase.api = field.api

			this.popup.newBase = true
		},
		clearFor3Days(id) {
			this.changeIdx = this.base.data.findIndex(field => field.id === id)
			let field = this.base.data[this.changeIdx]
			console.log(field)
			this.$http.post("/bases/clearFor3",
				{ field },
				(data) => {

					console.log(data)
				}
			)
		},
		addLidsButton(id) {
			this.popup.addLids = true
			this.addLids.id = id
		},
		async getBase() {
			let id = this.addLids.id
			let _this = this
			if (this.addLids.method === '' || this.file !== '') {
				await Papa.parse(this.file, {
					complete: async function (results) {
						_this.loading.base = true
						notification.open({
							message: 'Идёт загрузка файла. Дождитесь уведомления о завершении загрузки',
							description: 'Если закрыть страницу, процесс загрузки прервётся',
							duration: 3,
							placement: 'bottomLeft'
						});
						this.content = results
						this.parsed = true;
						const chunkSize = 1000;
						for (let i = 0; i < this.content.data.length; i += chunkSize) {
							const chunk = this.content.data.slice(i, i + chunkSize);
							let phones = []
							if (chunk.length > 0) {
								chunk.forEach((phone) => {
									phones.push(phone[0])
								})
								setTimeout(() =>
									_this.$http.post("/bases/file",
										{
											phones: phones,
											base_id: id
										},
										() => {
										}),
									1000);
							}
							if (i + chunkSize >= this.content.data.length) {
								_this.loading.base = false
								notification.open({
									message: 'Загрузка файла завершена',
									description: '',
									duration: 3,
									placement: 'bottomLeft'
								});
							}
						}
					}
				})
			}
			this.addLids.region.forEach((region, index) => {
				if (!this.addLids.day_min[index]) { this.addLids.day_min[index] = 0 }
				this.$http.post("/bases/addphones",
					{
						base_id: id,
						method: this.addLids.method,
						limit: this.addLids.limit[index],
						day_min: this.addLids.day_min[index],
						day_max: this.addLids.day_max[index],
						region: region,
						conditions: this.addLids.conditions
					},
					() => {
					})
			})

			this.closePopup()

		},
		startBase(id) {
			this.$http.post("/bases/startstop",
				{
					base: this.base.data.find((base) => base.id === id)
				},
				(res) => {
					if (res != 'Error') {
						this.base.data.forEach((base) => {
							if (base.id == id) {
								base.active = res
							}
						})
					}
					else {
						alert("Чтобы начать загрузку, укажите Api")
					}

				})
		},
		clearPhonedBases_bfl() {
			this.$http.post("/bases/clearBasesbfl",
				{
				},
				() => {
				}
			)
		},
		clearPhonedBases_zalog() {
			this.$http.post("/bases/clearBaseszalog",
				{
				},
				() => {
				}
			)
		},
		clearPhonedBases_mfo() {
			this.$http.post("/bases/clearBasesmfo",
				{
				},
				() => {
				}
			)
		},
		exelRout(id){
			
			this.changeIdx = this.base.data.findIndex(field => field.id === id)
			let field = this.base.data[this.changeIdx]
			
			this.$http.post("bases/exelRout",
			{
				idBase:field.id
			},
			(data)=>{
				
				const buffer = data.buffer.data; 
				const basename = data.basename
				const uintArray = new Uint8Array(buffer);
				const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				const blobUrl = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = blobUrl;
				a.download = basename + '.xlsx'; 
				document.body.appendChild(a);
				a.click();
				URL.revokeObjectURL(blobUrl);
				document.body.removeChild(a);
			}
		
		)
		}
	},
	mounted() {
		this.$http.post("/bases/methods",
			{
			},
			(data) => {
				this.addMethods = data
			})
		
	}
}
</script>

<style scoped>
.button-group {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 45px;
}
</style>