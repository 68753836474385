import {http} from '@/axios/http'

let $http = new http();


export const QuestionsModule = {
	namespaced: true,

	state: () => ({
		questions: [],
		loading: false
	}),
	getters: {
		getQuestions(state){
			return state.questions
		},
		getLoading(state){
			return state.loading
		}
	},
	mutations: {
		setQuestions(state, questions) {
			state.questions = questions
			state.loading = true
		}
	},
	actions: {
		async loadingQuestions({commit}) {
			$http.post("/offers/questselect",
				{},
				(data) => {
					// let offers = JSON.stringify(data)
					// localStorage.offers = offers
					if (data && data !== 'error') {
						commit("setQuestions", data)
					}
				})
		},
	},
}