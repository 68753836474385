<template>
	<a-layout-sider style="background: #fff" :style="{ overflow: 'auto', height: '100vh'}" theme="light" width="200px" :defaultCollapsed="roleUser === 'operator'" collapsible >

		<a-menu
				mode="inline"
				v-model:openKeys="openKeys"
				:style="{ height: '100%', borderRight: 0 }"
		>
			<a-menu-item key="1"
					v-if="roleUser === 'operator'"
					@click="$router.push({ name: 'flow' })"
					:class="$route.name === 'flow' ? 'ant-menu-item-selected': ''"
			>
				<phone-outlined />
				<span>Поток</span>
			</a-menu-item>

<!--			<a-menu-item key="2"-->
<!--					v-if="roleUser === 'operator'"-->
<!--					@click="$router.push({ name: 'call_history' })"-->
<!--					:class="$route.name === 'call_history' ? 'ant-menu-item-selected': ''"-->
<!--			>-->
<!--				<clock-circle-outlined />-->
<!--				<span>История звонков</span>-->
<!--			</a-menu-item>-->

			<a-menu-item key="3"
					v-if="roleUser === 'operator'"
					@click="$router.push({ name: 'offers' })"
					:class="$route.name === 'offers' ? 'ant-menu-item-selected': ''"
			>
				<unordered-list-outlined />
				<span>Мои оферы</span>
			</a-menu-item>

<!--			<a-sub-menu key="sub1"-->
<!--				v-if="roleUser === 'operator'"-->
<!--			>-->
<!--				<template #title>-->
<!--							<span>-->
<!--								<line-chart-outlined />-->
<!--								Статистика-->
<!--							</span>-->
<!--				</template>-->
<!--				<a-menu-item key="4"-->
<!--						@click="$router.push({ name: 'report-days-operator' })"-->
<!--						:class="$route.name === 'report-days-operator' ? 'ant-menu-item-selected': ''"-->
<!--				>Рабочие дни-->
<!--				</a-menu-item>-->
<!--&lt;!&ndash;				<a-menu-item key="5">Звонки</a-menu-item>&ndash;&gt;-->
<!--&lt;!&ndash;				<a-menu-item key="5"&ndash;&gt;-->
<!--&lt;!&ndash;						@click="$router.push({ name: 'test' })"&ndash;&gt;-->
<!--&lt;!&ndash;						:class="$route.name === 'test' ? 'ant-menu-item-selected': ''"&ndash;&gt;-->
<!--&lt;!&ndash;				>test&ndash;&gt;-->
<!--&lt;!&ndash;				</a-menu-item>&ndash;&gt;-->
<!--			</a-sub-menu>-->
			<a-menu-item key="7"
					v-if="roleUser === 'admin'"
					@click="$router.push({ name: 'admin-operator' })"
					:class="$route.name === 'admin-operator' ? 'ant-menu-item-selected': ''"
			>
<!--				<laptop-outlined/>-->
				<user-outlined />
				<span>Операторы</span>
			</a-menu-item>
			<a-menu-item key="8"
					v-if="roleUser === 'admin'"
					@click="$router.push({ name: 'admin-field' })"
					:class="$route.name === 'admin-field' ? 'ant-menu-item-selected': ''"
			>
<!--				<laptop-outlined/>-->
				<unordered-list-outlined />
				<span>Поля</span>
			</a-menu-item>
			<a-menu-item key="9"
					v-if="roleUser === 'admin'"
					@click="$router.push({ name: 'admin-offers' })"
					:class="$route.name === 'admin-offers' ? 'ant-menu-item-selected': ''"
			>
				<schedule-outlined />
				<span>Офферы</span>
			</a-menu-item>
			<a-menu-item key="11"
					v-if="roleUser === 'admin'"
					@click="$router.push({ name: 'admin-base' })"
					:class="$route.name === 'admin-base' ? 'ant-menu-item-selected': ''"
			>
				<database-outlined />
				<span>Базы лидов</span>
			</a-menu-item>
			<a-menu-item key="12"
					v-if="roleUser === 'admin'"
					@click="$router.push({ name: 'admin-scenario' })"
					:class="$route.name === 'admin-scenario' ? 'ant-menu-item-selected': ''"
			>
				<snippets-outlined />
				<span>Сценарии</span>
			</a-menu-item>
			<a-menu-item key="13"
					v-if="roleUser === 'admin'"
					@click="$router.push({ name: 'admin-scripts-call' })"
					:class="$route.name === 'admin-scripts-call' ? 'ant-menu-item-selected': ''"
			>
				<comment-outlined />
				<span>Скирипты звонков</span>
			</a-menu-item>
			<a-menu-item key="99"
					v-if="roleUser === 'head_admin'"
					@click="$router.push({ name: 'admin-super-admin' })"
					:class="$route.name === 'admin-super-admin' ? 'ant-menu-item-selected': ''"
			>
				<user-outlined />
				<span>Администраторы</span>
			</a-menu-item>
      <a-menu-item key="100"
                   v-if="roleUser === 'admin'"
                   @click="$router.push({ name: 'call_history' })"
                   :class="$route.name === 'call_history' ? 'ant-menu-item-selected': ''"
      >
        <clock-circle-outlined />
        <span>История звонков</span>
      </a-menu-item>
      <a-menu-item key="101"
                   v-if="roleUser === 'admin'"
                   @click="$router.push({ name: 'report-days-operator' })"
                   :class="$route.name === 'report-days-operator' ? 'ant-menu-item-selected': ''"
      >
        <clock-circle-outlined />
        <span>Статистика</span>
      </a-menu-item>
	<a-menu-item key="119"
                   v-if="roleUser === 'head_admin'"
                   @click="$router.push({ name: 'report-days-super' })"
                   :class="$route.name === 'report-days-super' ? 'ant-menu-item-selected': ''"
      >
        <clock-circle-outlined />
        <span>Статистика</span>
      </a-menu-item>
			<a-menu-item
					v-if="roleUser === 'admin'"
					key="102"
					:class="$route.name === 'setting-user' ? 'ant-menu-item-selected': ''"
					@click="$router.push({ name: 'setting-user' })"
			>
				<!--				<laptop-outlined/>-->
				<SettingOutlined />
				<span>Настройки</span>
			</a-menu-item>

			<a-menu-item
					v-if="roleUser === 'admin'"
					key="120"
					:class="$route.name === 'admin-scenario-archive' ? 'ant-menu-item-selected': ''"
					@click="$router.push({ name: 'admin-scenario-archive' })"
			>
				<!--				<laptop-outlined/>-->
				<FileZipOutlined />
				<span>Архив сценариев</span>
			</a-menu-item>

			<a-menu-item
					v-if="roleUser === 'admin'"
					key="121"
					:class="$route.name === 'monitoring-vue' ? 'ant-menu-item-selected': ''"
					@click="$router.push({ name: 'monitoring-vue' })"
			>
				<!--				<laptop-outlined/>-->
				<FileZipOutlined />
				<span>Мониторинг</span>
			</a-menu-item>

			<a-menu-item
					v-if="roleUser === 'operator'"
					key="122"
					:class="$route.name === 'report-foroperator' ? 'ant-menu-item-selected': ''"
					@click="$router.push({ name: 'report-foroperator' })"
			>
				<!--				<laptop-outlined/>-->
				<FileZipOutlined />
				<span>Отчёты</span>
			</a-menu-item>


<!--			<a-sub-menu key="sub2">-->
<!--				<template #title>-->
<!--						<span>-->
<!--							<laptop-outlined/>-->
<!--							Настройки-->
<!--						</span>-->
<!--				</template>-->
<!--				<a-menu-item key="6"-->
<!--						@click="$router.push({ name: 'setting-user' })"-->
<!--						:class="$route.name === 'setting-user' ? 'ant-menu-item-selected': ''"-->
<!--				>Аккаунт-->
<!--				</a-menu-item>-->
<!--				<a-menu-item key="7">Микрофон</a-menu-item>-->
<!--			</a-sub-menu>-->


<!--			<template v-for="(item, i) of menu" :key="i">-->
<!--				<component-->
<!--						:is="item.component"-->
<!--						:keyItem="keyMenuFun('item', item.UnderMenu.length)"-->
<!--						:keySub="keyMenuFun('sub')"-->
<!--						:nameRouter="item.routeName"-->
<!--						:arrSubMenu="item.UnderMenu"-->
<!--						:textBtn="item.textBtn"-->
<!--				/>-->
<!--			</template>-->
		</a-menu>

	</a-layout-sider>


</template>

<script>

import {
	// PieChartOutlined,
	// UserOutlined,
	// LaptopOutlined,
	// LogoutOutlined,
	ClockCircleOutlined,
	UnorderedListOutlined,
	// LineChartOutlined,
	SettingOutlined,
	UserOutlined,
	PhoneOutlined,
	ScheduleOutlined,
	DatabaseOutlined,
	SnippetsOutlined,
	CommentOutlined,
	FileZipOutlined
} from '@ant-design/icons-vue';
// import {ref} from 'vue';
// import itemMenu from "@/components/menu/item-menu";
// import subMenu from "@/components/menu/sub-menu";
import { mapGetters } from "vuex";

export default {
	name: "LeftMenuMain",
	components: {
		// PieChartOutlined,
		// UserOutlined,
		// LaptopOutlined,
		// itemMenu,
		// subMenu,
		// LogoutOutlined,
		ClockCircleOutlined,
		UnorderedListOutlined,
    // LineChartOutlined,
		SettingOutlined,
		UserOutlined,
		PhoneOutlined,
		ScheduleOutlined,
		DatabaseOutlined,
		SnippetsOutlined,
		CommentOutlined,
		FileZipOutlined
	},
	setup() {
		return {
			collapsed:false
			// selectedKeys1: ref(['2']),
			// selectedKeys2: ref(['1']),
			// collapsed: ref(false),
			// openKeys: ref([]),
		};
	},
	data(){
		return {
			// menu: [
			// 	{ component: 'item-menu', textBtn: 'Поток', routeName: 'flow', UnderMenu: [], admin: false, icon: '' },
			// 	{ component: 'item-menu', textBtn: 'История звонков', routeName: 'call_history', UnderMenu: [], admin: false, icon: '' },
			// 	{ component: 'item-menu', textBtn: 'Мои оферы', routeName: 'offers', UnderMenu: [], admin: false, icon: '' },
			//
			// 	{ component: 'sub-menu', textBtn: 'Статистика', routeName: '', UnderMenu: [
			// 			{textBtn: 'Отправки офферов', routeName: 'statistics-offer'},
			// 			{textBtn: 'Звонки', routeName: ''},
			// 		], admin: false, position: 3, icon: '' },
			// 	{ component: 'item-menu', textBtn: 'Настройки', routeName: 'setting-user', UnderMenu: [], admin: false, icon: '' },
			// ],
			// keyMenu: {
			// 	menuItem: 0,
			// 	subMenu: 0
			// },
			openKeys: ['sub1'],
		}
	},
	computed: {
		// sortMenu(){
		// 	let varMenu = this.menu
		// 	return varMenu.sort((a,b) => a.position - b.position)
		// }
		...mapGetters({roleUser: 'user/getRole'})
	},
	methods: {
		keyMenuFun(type, lengthSub){
			let key
			if (type === 'item'){
				key = this.keyMenu.menuItem
				if (lengthSub){
					this.keyMenu.menuItem += lengthSub
				} else {
					this.keyMenu.menuItem++
				}
			} else {
				key = 'sub' + this.keyMenu.subMenu
				this.keyMenu.subMenu++
			}
			return key
		}
	}
}
</script>

<style scoped>
section.ant-layout {
	margin-left: 20px;
}
</style>