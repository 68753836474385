import { createApp } from 'vue'

import App from './App.vue'
import store from '@/store/store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from "@/router/router";
import { http } from "@/axios/http";

import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';

const app = createApp(App)

app.config.globalProperties.$http = new http();

app .use(router)
	.use(store)
    .use(Antd)
    .mount('#app');

