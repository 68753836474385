<template>
	<TitlePage title="Поля офферов" subtitle="" :border="true">
		<template v-slot:title_right>
			<a-button type="primary" @click="popup.addField = true">
				Добавить поле
			</a-button>
		</template>
	</TitlePage>

	<a-table :columns="columns" :data-source="data" :loading="loading.table" :pagination="pagination" size="middle">
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'type'">
				{{ record.type.label }}
			</template>
			<template v-else-if="column.key === 'control'">
				<span>
					<a @click="changeField(record.key)">Изменить</a>
					<a-divider type="vertical" />
					<a-popconfirm title="Удалить поле?" @confirm="deleteField(record.key)">
						<a>Удалить</a>
					</a-popconfirm>
				</span>
			</template>
		</template>
	</a-table>

	<BasicModal title="Добавить поле" :visible="popup.addField" @closePopup="closePopup()" btnOk="Сохранить"
		:callback="callbackPopup">
		<a-form class="number_inform_form" layout="vertical">
			<a-form-item label="Название поля">
				<a-input v-model:value="addNewField.name" />
			</a-form-item>
			<a-form-item label="Слаг поля">
				<a-input v-model:value="addNewField.slag" />
			</a-form-item>

			<a-form-item label="Тип поля">
				<a-select v-model:value="addNewField.select_type.value" show-search placeholder="Выберите тип поля"
					:options="addNewField.select_type.options" @change="clearFieldPreview">
				</a-select>
			</a-form-item>
		</a-form>

		<div
			v-if="addNewField.select_type.value === 'input-tree-select' || addNewField.select_type.value === 'input-select'">
			<div style="margin-bottom: 8px">Укажите поля для выбраного типа</div>
			<DynamicFormItem type-component="field" :type-fields="addNewField.select_type.value" @change="changeSelectItem"
				:options="fieldOptions.options" />
		</div>
		<div v-if="addNewField.select_type.value === 'input-select-region'">
			<div style="margin-bottom: 8px"> Список регионов задан автоматически </div>
			<!--			<DynamicFormItem type-component="field" :type-fields="addNewField.select_type.value" @change="changeSelectItem" :options="fieldOptions.options"/>-->
		</div>
		<div v-if="addNewField.select_type.value === 'input-select-name'">
			<div style="margin-bottom: 8px"> Список имён задан автоматически </div>

			<!--			<DynamicFormItem type-component="field" :type-fields="addNewField.select_type.value" @change="changeSelectItem" :options="fieldOptions.options"/>-->
		</div>
		<div v-if="addNewField.select_type.value === 'input-boolean'">
			<a-form-item label="Укажите название флага для выбора">
				<a-input v-model:value="fieldOptions.options" />
			</a-form-item>
		</div>
		<div v-if="addNewField.select_type.value === 'input-range-number'">
			<div style="margin-bottom: 8px">Укажите диапазон для выбора</div>
			<div>
				Минимальное
				<a-form-item label="Минимальное">
					<a-input-number v-model:value="fieldOptions.options.min" style="width: 100%" />
				</a-form-item>

			</div>
			<div>
				<a-form-item label="Максимальное">
					<a-input-number v-model:value="fieldOptions.options.max" style="width: 100%" />
				</a-form-item>

			</div>
		</div>


		<a-card v-if="addNewField.select_type.value" title="Пример вывода поля в оффере" :bordered="false"
			style="width: 100%">
			<a-form layout="vertical">
				<component :is="addNewField.select_type.value" :options="fieldOptions.options" :value="fieldOptions.value"
					@changInput="changeValue" :name="addNewField.name"></component>
			</a-form>
		</a-card>


	</BasicModal>
	<!--		{{ addNewField }}-->
</template>

<script>
import TitlePage from "@/components/TitlePage";
// import FiltersHeader from "@/components/ui/FiltersHeader";
import BasicModal from "@/components/modal/BasicModal";
import DynamicFormItem from "@/components/ui/DynamicFormItem";

import inputText from "@/components/field/input-text";
import inputNumber from "@/components/field/input-number";
import inputSelect from "@/components/field/input-select";
import inputSelectRegion from "@/components/field/input-select-region";
import inputTreeSelect from "@/components/field/input-tree-select";
import inputBoolean from "@/components/field/input-boolean";
import inputDate from "@/components/field/input-date";
import inputRangeDate from "@/components/field/input-range-date";
import inputRangeNumber from "@/components/field/input-range-number";
import inputSelectName from "@/components/field/input-select-name";

// import inputFieldsComponents from "@/components/field/inputFieldsComponents";

export default {
	name: "FieldsOfferAdmin",
	components: {
		TitlePage,
		// FiltersHeader,
		BasicModal,
		DynamicFormItem,
		inputText,
		inputNumber,
		inputSelect,
		inputSelectRegion,
		inputTreeSelect,
		inputBoolean,
		inputDate,
		inputRangeDate,
		inputRangeNumber,
		inputSelectName
		// inputFieldsComponents
	},
	data() {
		this.$http.post("/offers/questselect",
			{},
			(data) => {
				this.data = data
				this.loading.table = false
			})
		return {
			nameOptions: [],
			pagination: {
				pageSize: 100
			},
			loading: {
				table: true
			},
			fieldOptions: {
				value: '',
				options: []
			},
			addNewField: {
				name: '',
				slag: '',
				select_type: {
					value: '',
					options: [
						{
							value: 'input-text',
							label: 'Текстовое поле',
						},
						{
							value: 'input-number',
							label: 'Цифровое поле',
						},
						{
							value: 'input-select',
							label: 'Выбор из списка',
						},
						{
							value: 'input-tree-select',
							label: 'Выбор нескольких вариантов',
						},
						{
							value: 'input-boolean',
							label: 'Выбор "да" или "нет"',
						},
						{
							value: 'input-date',
							label: 'Выбор даты',
						},
						{
							value: 'input-range-date',
							label: 'Выбор диапазона дат',
						},
						{
							value: 'input-select-region',
							label: 'Выбор региона',
						},
						{
							value: 'input-select-name',
							label: 'Выбор имени',
						},
						// {
						// 	value: 'input-range-number',
						// 	label: 'Цифровой диапазон',
						// },
					]
				},
			},
			dateFormat: 'DD/MM/YYYY',
			popup: {
				addField: false
			},
			columns: [
				{
					title: 'Название поля',
					dataIndex: 'name',
					key: 'name',
				}, {
					title: 'Типа поля',
					dataIndex: 'type',
					key: 'type',
				}, {
					title: 'Управление',
					key: 'control',
				},
			],
			data: [],
			changeKeyField: -1
		}
	},
	methods: {
		callbackPopup() {
			// console.log('callbackPopup')

			if (this.addNewField.name && this.addNewField.select_type.value && !(this.changeKeyField >= 0)) {
				this.data.push(
					{
						key: Date.now(),
						name: this.addNewField.name,
						slag: this.addNewField.slag,
						type: this.addNewField.select_type.options.find(item => item.value === this.addNewField.select_type.value),
						options: this.fieldOptions.options,
					}
				)
			} else if (this.addNewField.name && this.addNewField.select_type.value && this.changeKeyField >= -1) {
				this.data[this.changeKeyField] = {
					key: this.data[this.changeKeyField].key,
					name: this.addNewField.name,
					slag: this.addNewField.slag,
					type: this.addNewField.select_type.options.find(item => item.value === this.addNewField.select_type.value),
					options: this.fieldOptions.options,
				}
			}

			this.changeKeyField = -1
			this.$http.post("/offers/questinsert",
				{
					data: this.data
				},
				() => {
				})
			this.closePopup()
		},
		changeSelectItem(item) {
			this.fieldOptions.options = Object.assign([], item);
			// this.fieldOptions.options = [...item]
		},
		// changeSelectValue(val){
		// 	this.fieldOptions.value = val
		// },
		changeValue(val) {
			this.fieldOptions.value = val
			// console.log(this.fieldOptions.value)
		},
		closePopup() {
			this.popup.addField = false
			this.addNewField.select_type.value = ''
			this.addNewField.name = ''
			this.addNewField.slag = ''
			this.clearFieldPreview()
		},
		clearFieldPreview() {
			if (this.addNewField.select_type.value === 'input-range-number') {
				this.fieldOptions = {
					value: [0, 0],
					options: {
						min: 0,
						max: 100
					}
				}
			} else if (this.addNewField.select_type.value === 'input-boolean') {
				this.fieldOptions = {
					value: false,
					options: ''
				}
			} else if (this.addNewField.select_type.value === 'input-tree-select' || this.addNewField.select_type.value === 'input-select') {
				this.fieldOptions = {
					value: '',
					options: [{
						value: ''
					}]
				}
			}
			else if (this.addNewField.select_type.value === 'input-select-region') {
				let regions = []
				this.$http.post("/bases/regions",
					{
					},
					(data) => {
						data.forEach((item) => {
							if (item !== 'Все регионы') {
								regions.push({ value: item })
							}
						})
						this.fieldOptions = {
							value: '',
							options: regions
						}
					})

			} else if (this.addNewField.select_type.value === 'input-select-name') {
				let names = []
				this.$http.post("/bases/findname",
					{
					},
					(data) => {
						data.forEach((item) => {
							names.push({ value: item })

						})
						this.fieldOptions = {
							value: '',
							options: names
						}
					})

			}

			else {
				this.fieldOptions = {
					value: '',
					options: []
				}
			}
		},
		deleteField(key) {
			this.data = this.data.filter(item => item.key !== key);
			// пока отдельным запросом
			this.$http.post("/offers/questdelete",
				{
					id: key
				},
				() => {
				})
		},
		changeField(key) {
			this.changeKeyField = this.data.findIndex(field => field.key === key)
			let field = this.data[this.changeKeyField]

			this.addNewField.name = field.name
			this.addNewField.slag = field.slag
			this.addNewField.select_type.value = field.type.value
			this.fieldOptions.options = field.options
			this.popup.addField = true
		},


	}

}
</script>

<style scoped>
.profile_operator {
	background: #FFFFFF;
	border-radius: 2px;
	padding: 15px;
}
</style>