<template>
    <div id="setting_tab" class="card-container">
        <a-tabs type="card">
            <a-tab-pane key="1" tab="Статистика по звонкам">
                <TitlePage title="Статистика" subtitle="Отчёт по звонкам" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter"
                        :view-input-filter="{ rangePicker: true }" />
                </TitlePage>
                <!--		============================ TABLE =========================-->
                <a-divider>Статистика по звонкам</a-divider>
                <a-table :columns="all_results_columns" :data-source="all_results_data" :loading="loading.table"
                    :pagination="false" @change="false">
                </a-table>
                <a-divider>Результаты звонков</a-divider>
                <a-table :columns="calls_results_columns" :data-source="calls_results_data" :loading="loading.table"
                    :pagination="false" @change="false">
                </a-table>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import FiltersHeader from "@/components/ui/FiltersHeader";
import dayjs from 'dayjs';
//import moment from "moment/moment";
export default {
    name: "CallHistory",
    components: {
        TitlePage,
        FiltersHeader,
    },
    data() {
        // this.$http.post("/statistics/foroperator",
        //     {
        //     },
        //     (data) => {
        //     console.log(data)
        //         this.all_results_data = data.all_results_data
        //         this.calls_results_data = data.calls_results_data
        //         this.loading.table = false
        //         this.pagination.total = this.sends_data.length
        //     })

        return {

            sms_count: '',
            // numberPhone: '',
            loading: {
                table: true,
            },
            filter: {
                rangeDate: [],
            },
            all_results_columns: [
                {
                    title: 'Количество звонков',
                    dataIndex: 'calls_amount',
                    key: 'calls_amount',
                },
                {
                    title: 'Количество отправок',
                    dataIndex: 'sends_amount',
                    key: 'sends_amount',
                },
                {
                    title: 'Количество отправленных СМС',
                    dataIndex: 'sms_sends_amount',
                    key: 'sms_sends_amount',
                },
                {
                    title: 'Количество одобрений с отправленных СМС',
                    dataIndex: 'sms_profit_amount',
                    key: 'sms_profit_amount',
                },
            ],
            calls_results_columns: [
                {
                    title: 'Количество звонков',
                    dataIndex: 'calls_amount',
                    key: 'calls_amount',
                },
                {
                    title: 'Положительные',
                    dataIndex: 'good',
                    key: 'good',
                },
                {
                    title: 'Негатив',
                    dataIndex: 'bad',
                    key: 'bad',
                },
                {
                    title: 'Робот/автоответчик',
                    dataIndex: 'robot',
                    key: 'robot',
                },
                {
                    title: 'Тишина в звонке',
                    dataIndex: 'silence',
                    key: 'silence',
                },
                {
                    title: 'Не интересно',
                    dataIndex: 'unsuitable',
                    key: 'unsuitable',
                },
                {
                    title: 'Предодобрено',
                    dataIndex: 'pre_approved',
                    key: 'pre_approved',
                },
                {
                    title: 'Отказано',
                    dataIndex: 'refused',
                    key: 'refused',
                },
                {
                    title: 'Не взял трубку',
                    dataIndex: 'didnt_pick_up',
                    key: 'didnt_pick_up',
                },
                {
                    title: 'Сбросил',
                    dataIndex: 'dropped',
                    key: 'dropped',
                },
                {
                    title: 'Отправили смс',
                    dataIndex: 'send_sms',
                    key: 'send_sms',
                },
                {
                    title: 'Дубль',
                    dataIndex: 'double',
                    key: 'double',
                },
                {
                    title: 'Отправлено КП',
                    dataIndex: 'send_kp',
                    key: 'send_kp',
                },
            ],
            all_results_data: [
                {
                    calls_amount: 0,
                    sends_amount: 0,
                    sms_sends_amount: 0,
                    sms_profit_amount: 0,
                }
            ],
            calls_results_data: [
                {
                    calls_amount: 0,
                    good: 0,
                    bad: 0,
                    robot: 0,
                    silence: 0,
                    unsuitable: 0,
                    pre_approved:0,
                    refused:0,
                    didnt_pick_up:0,
                    dropped:0,
                    send_sms:0,
                    double:0,
                    send_kp:0
                }
            ],
        }
    },
    methods: {
        changeFilter(filter) {

            this.loading.table = true
            this.$http.post("/statistics/foroperator",
                {
                    filter: filter
                },
                (data) => {
                    this.all_results_data = data.all_results_data
                    this.calls_results_data = data.calls_results_data
                    this.loading.table = false
                })
        },
        updateStatistics() {
            this.changeFilter(this.filter);
        },
    },
    mounted() {

        const today = dayjs();
        this.filter.rangeDate = [today, today];
        this.changeFilter(this.filter);




        setInterval(this.updateStatistics, 1000 * 120);

    }

}
</script>

<style scoped></style>