<template>
	<TitlePage :title="nameOffers === 'new-offer' ? 'Новый офер' : dataOffer.name" subtitle=""
		:backPage="() => { $router.push({ name: 'admin-offers' }) }" :border="true" :loading="!loading.render">
		<template v-slot:title_right>
			<a-button type="primary" :loading="loading.save" @click="saveOffer()">
				Сохранить
			</a-button>
		</template>
	</TitlePage>

	<div>
		<div>
			<a-card title="Информация" style="border-radius: 2px">
				<a-form class="number_inform_form" layout="vertical" style="max-width: 400px">
					<a-form-item label="Название" :rules="[{ required: true, message: 'Пожалуйста,введите название' }]">
						<a-input v-if="loading.render" v-model:value="dataOffer.name" />
						<a-skeleton-input v-else style="width: 100%" :active="true" />
					</a-form-item>
					<a-form-item label="Описание" :rules="[{ required: true, message: 'Пожалуйста,введите описание' }]">
						<a-textarea v-if="loading.render" v-model:value="dataOffer.description" placeholder="Описание"
							:rows="4" />
						<a-skeleton v-else active :title="false" style="margin-bottom: -14px" />
					</a-form-item>
					<a-form-item label="Тип" :rules="[{ required: true, message: 'Пожалуйста,выберете тип' }]">
						<a-row :gutter="15" type="flex">
							<a-col flex="auto">
								<a-select v-if="loading.render" v-model:value="dataOffer.type" style="width: 100%"
									:options="dataType.map(item => ({ value: item.key, label: item.value }))"></a-select>
								<a-skeleton-input v-else style="width: 100%" :active="true" />
							</a-col>
							<a-col flex="100px">
								<a-button @click="popup.addType = true" type="dashed">Добавить тип</a-button>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="Целевое действие"
						:rules="[{ required: true, message: 'Пожалуйста,введите целевое действие' }]">
						<a-input v-if="loading.render" v-model:value="dataOffer.conversion" />
						<a-skeleton-input v-else style="width: 100%" :active="true" />
					</a-form-item>
					<a-form-item label="Оплата за целевое действие"
						:rules="[{ required: true, message: 'Пожалуйста,введите оплату за целевое действие' }]">
						<a-input v-if="loading.render" v-model:value="dataOffer.cost" />
						<a-skeleton-input v-else style="width: 100%" :active="true" />
					</a-form-item>
					<a-form-item v-if="dataOffer.type !== 4" label="Канал"
						:rules="[{ required: true, message: 'Пожалуйста,введите канал' }]">
						<a-input v-if="loading.render" v-model:value="dataOffer.channel" />
						<a-skeleton-input v-else style="width: 100%" :active="true" />
					</a-form-item>
					<a-form-item v-if="dataOffer.type === 4" label="URL для перехода к оформлению">
						<a-input v-if="loading.render" v-model:value="dataOffer.url" />
						<a-skeleton-input v-else style="width: 100%" :active="true" />
					</a-form-item>
					<a-form-item label="Ссылка для СМС"
						:rules="[{ required: true, message: 'Пожалуйста,введите ссылку для СМС' }]">
						<a-input v-if="loading.render" v-model:value="dataOffer.sms_link" />
						<a-skeleton-input v-else style="width: 100%" :active="true" />
					</a-form-item>
				</a-form>
			</a-card>
		</div>
		<div>
			<a-card title="Поля" style="border-radius: 2px">
				<a-transfer v-model:target-keys="targetKeys" :one-way="true" :data-source="mockData" :list-style="{
					width: '100%',
					height: '400px',
				}" :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1" @change="handleChange"
					:show-search="true">
					<template #render="item">
						<span v-if="loading.transfer && loading.render">
							{{ item.title }}
						</span>
						<a-skeleton v-else :title="false" :paragraph="{ rows: 1 }" />
					</template>
				</a-transfer>

			</a-card>
		</div>

	</div>
	<div>
		<a-card
			v-if="targetKeys_full.filter(item => item.type.value === 'input-number' || item.type.value === 'input-select-region').length > 0"
			title="Критерии" style="border-radius: 2px">
			<a-form class="number_inform_form" layout="vertical" style="max-width: 400px">
				<a-form-item
					v-for="criterion_field in targetKeys_full.filter(item => item.type.value === 'input-number' || item.type.value === 'input-select-region')"
					:key="criterion_field" :label="criterion_field.name">
					<!--					<a-select-->
					<!--							:disabled="criterion_field.type.value!=='input-number'"-->
					<!--							:options="criterion_number_select.map(v => ({ value: v }))"-->
					<!--							v-model:value="criterion_number_select[0]"-->
					<!--							style="width: 30%"-->
					<!--					></a-select>-->
					<a-input :disabled="criterion_field.type.value !== 'input-number'"
						v-model:value="criterion_field.criterion" style="width: auto"></a-input>
					<a-select :disabled="criterion_field.type.value !== 'input-select-region'"
						v-model:value="criterion_field.criterion" mode="multiple" class="select_regions" style="width: 100%"
						placeholder="Выберите регионы" :options="options_regions"></a-select>
				</a-form-item>
				<p>
					<font color="#696969"> Цифровое условие должно быть написано в виде &#60;100 или &gt;100, двойные
						условия будут добавлены позже</font>
				</p>
			</a-form>
		</a-card>
	</div>
	<BasicModal :visible="popup.addType" title="Изменение типов" @closePopup="closePopup" :callback="closePopup">
		<a-table :columns="columnsType" :data-source="dataType" bordered :pagination="false">
			<template #bodyCell="{ column, text, record }">
				<template v-if="column.dataIndex === 'value'">
					<div>
						<!--						{{ editableData + '  ++++   ' + [record.key][column.dataIndex]}}-->
						<a-input v-if="editableData[record.key]" v-model:value="editableData[record.key][column.dataIndex]"
							style="margin: -5px 0" />
						<template v-else>
							{{ text }}
						</template>
					</div>
				</template>
				<template v-else-if="column.dataIndex === 'operation'">
					<div class="editable-row-operations">
						<span v-if="editableData[record.key]">
							<a-typography-link @click="save(record.key)">Сохранить</a-typography-link>
							<!--							<a-popconfirm title="Отменить изменения?" @confirm="cancel(record.key)">-->
							<a-divider type="vertical" />
							<a @click="cancel(record.key)">Отмена</a>
							<!--							</a-popconfirm>-->
						</span>
						<span v-else>
							<a @click="edit(record.key)">Изменить</a>
						</span>
					</div>
				</template>
			</template>
		</a-table>
		<a-button style="margin-top: 5px" type="primary" block @click="add()">Добавить тип оффера</a-button>
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import BasicModal from "@/components/modal/BasicModal";
// import DynamicFormItem from "@/components/ui/DynamicFormItem";
import { notification } from "ant-design-vue";
export default {
	name: "PageOfferAdmin",
	components: {
		TitlePage,
		BasicModal,
	},
	data() {
		this.$http.post("/offers/offerstypes",
			{},
			(data) => {
				this.dataType = data
			})
		return {
			popup: {
				addType: false
			},
			loading: {
				save: false,
				render: false,
				transfer: false
			},
			fieldsOffer: [],
			dataOffer: {
				key: false,
				name: '',
				description: '',
				type: '',
				conversion: '',
				cost: '',
				channel: '-',
				criterios: [],
				sms_link: '-'
			},
			selected_regions: [],
			options_regions: [],
			mockData: [{}, {}, {}, {}, {}, {},],
			targetKeys: [],
			targetKeys_full: [],
			criterion_number_select: ['Больше', 'Меньше'],
			dataFields: [],
			columnsType: [{
				title: 'Тип',
				dataIndex: 'value',
				width: '60%',
			}, {
				title: 'Действия',
				dataIndex: 'operation',
			}],
			dataType: [],
			editableData: {},
			editingKey: '',
		}
	},
	methods: {
		changeSelectItem(item) {
			this.fieldsOffer = Object.assign([], item);
		},

		getMock() {
			this.$http.post("/offers/questselect",
				{},
				(data) => {
					this.dataFields = data
					this.mockData = this.dataFields.map((item) => {
						return {
							key: item.key,
							title: item.name
						}
					})
					this.loading.transfer = true
				})
		},
		handleChange() {
			// console.log(keys, direction, moveKeys);
			this.criterion_load()
		},
		saveOffer() {
			if (!this.dataOffer.name || !this.dataOffer.description || !this.dataOffer.type || !this.dataOffer.conversion || !this.dataOffer.cost || !this.dataOffer.channel || !this.dataOffer.sms_link) {
				notification.info({
					message: 'Заполните все поля!',
					placement: 'topRight'
				});
			} else {

				this.loading.save = true
				// let fields = {}
				// for (let target of this.targetKeys) {
				// 	fields[target] = {}
				// }

				const endSave = () => {
					this.loading.save = false
					this.$router.push({ name: 'admin-offers' })
				}

				if (!this.dataOffer.key) {
					this.dataOffer.key = Date.now()
					this.$http.post("/offers/insert",
						{
							data: {
								...this.dataOffer,
								fields: this.targetKeys_full
							}
						},
						() => {
							endSave()
						})
				} else {
					this.$http.post("/offers/update",
						{
							data: {
								...this.dataOffer,
								fields: this.targetKeys_full
							}
						},
						() => {
							endSave()
						})
				}
			}
		},
		closePopup() {
			this.popup.addType = false
		},
		edit(key) {
			this.editableData[key] = { ...this.dataType.filter(item => key === item.key)[0] }
		},
		save(key) {

			// console.log(this.editableData[key])
			if (this.editableData[key].value.length) {
				Object.assign(this.dataType.filter(item => key === item.key)[0], this.editableData[key]);
				this.$http.post("/offers/changetype",
					{
						key: this.editableData[key].key,
						value: this.editableData[key].value
					},
					() => {
					})
				delete this.editableData[key];
			} else {
				this.cancel(key)
			}
		},
		add() {
			this.dataType.push({
				value: 'Новый тип ' + this.dataType.length,
				key: Date.now()
			})
			this.$http.post("/offers/addtype",
				{
					key: this.dataType[this.dataType.length - 1].key,
					value: this.dataType[this.dataType.length - 1].value
				},
				() => {
				})
			this.edit(this.dataType[this.dataType.length - 1].key)
		},
		cancel(key) {
			delete this.editableData[key];
		},
		criterion_load() {
			// console.log(this.dataOffer)
			this.targetKeys_full = []
			this.dataFields.forEach((field) => {
				this.targetKeys.forEach((field_id) => {
					if (field.key === field_id) {
						field.criterion = ""
						this.targetKeys_full.push(field)
						// console.log(this.dataOffer)
						if (this.dataOffer.criterios.length > 0) {
							this.dataOffer.criterios.forEach((criterion) => {
								if (criterion.id === field_id) {
									if (field.type.value == 'input-select-region') {
										field.criterion = criterion.criterion.split(',')
									}
									else {
										field.criterion = criterion.criterion
									}

								}
							})
						}
					}

				})
			})
			// console.log(this.targetKeys_full)
		},
	},
	mounted() {
		this.$http.post("/bases/regions",
			{
			},
			(data) => {
				data.forEach((item) => {
					if (item !== 'Все регионы') {
						this.options_regions.push({ value: item })
					}
				})
			})
		// this.nameOffers = this.$route.params.id
		this.getMock()
		if (this.nameOffers !== 'new-offer') {
			this.$http.post("/offers/all",
				{
					all: true,
					edit: true
				},
				(data) => {
					this.dataOffer = data.find((offer) => +offer.key === +this.nameOffers);
					this.dataOffer.data_type = +this.dataOffer.data_type
					Object.keys(this.dataOffer.fields).forEach((keyField) => {
						this.targetKeys.push(+keyField)
					})
					// тут заканчивается создание всех данных
					this.criterion_load()
					this.loading.render = true
				})
		} else {
			this.loading.render = true
		}
	},
	computed: {
		nameOffers() {
			return this.$route.params.id
		}
	}
}
</script>

<style scoped>
.offer_inform,
.inform_fields {
	padding: 15px;
}

.offer_inform {}

.inform_fields {}

/*.ant-skeleton-paragraph {*/
/*    margin-bottom: 0;*/
/*}*/
.editable-row-operations a {
	margin-right: 8px;
}

.select_regions {
	margin-top: 2%;
}
</style>