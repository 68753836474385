<template>
	<TitlePage title="Мои офферы" subtitle="Оффера в работе" :border="true"></TitlePage>
	<div>
		<a-row :gutter="10" :style="{ height: '100%' }">
			<a-col :span="17">
				<div v-if="loading.offers" class="">
					<a-card v-for="(card) in 3" :key="card" style="width: 100%; border-radius: 2px; margin-bottom: 15px;">
						<template #title>
							<a-skeleton-input style="width: 220px" :active="true" />
						</template>
						<template #extra>
							<a-skeleton-button style="width: 100px" :active="true" :block="true" />
						</template>
						<a-skeleton active :title="false" />
					</a-card>
				</div>
				<div v-else class="">
					<a-card :title="card.name" style="width: 100%; margin-bottom: 15px" v-for="(card, key) of viewOffers"
						:key="key">
						<template #extra>
							<!--							<img-->
							<!--									alt="example"-->
							<!--									:src="card.images ? card.images : 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'"-->
							<!--									style="max-height: 50px"-->
							<!--							/>-->
						</template>
						<div class="offers_block_info">
							<InformText :params="['Тип', 'Конверсия', 'Оплата', 'Полей']"
								:values="[card.type, card.conversion, card.cost, Object.keys(card.fields).length]" />
							<a-button @click="visiblePopupCardOffer(key)">Необходимые поля</a-button>
						</div>
					</a-card>
				</div>
			</a-col>
			<a-col :span="7">
				<div class="offers_block">
					<div class="left_filter_page">
						<CheckboxGroup v-for="(filter, key) of filterOffersOptions" :key="key" :titleDivider="filter.title"
							:plainOptions="filter.options" @checkedArr="filterOffers" />
						<!--						{{ filterOptions }}-->
					</div>
				</div>
			</a-col>
		</a-row>

		<!--		{{ data_offers }}-->
	</div>
	<BasicModal :visible="visiblePopup.InformOffer" title="Поля оффера" @closePopup="closePopup()">
		<a-table :columns="columns" :data-source="viewOfferFields" :pagination="false"></a-table>
		<!--		{{ viewOfferFields }}-->
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import CheckboxGroup from "@/components/ui/CheckboxGroup";
import BasicModal from "@/components/modal/BasicModal";
import InformText from "@/components/ui/InformText.vue";

import { mapGetters } from "vuex";

export default {
	name: "MyOffers",
	components: {
		TitlePage,
		CheckboxGroup,
		BasicModal,
		InformText,
	},
	data() {
		return {
			loading: {
				offers: true
			},
			// data_offers: [],
			// dataFields: [],
			visiblePopup: {
				InformOffer: false,
			},
			visibleOffer: 0,

			columns: [
				{
					title: 'Поле',
					dataIndex: 'field',
					key: 'field',
				}, {
					title: 'Тип',
					dataIndex: 'type',
					key: 'type',
				},
				// {
				// 	title: 'Требования',
				// 	dataIndex: 'requirements',
				// 	key: 'requirements',
				// },
			],

			filterOffersOptions: {
				type: {
					title: 'Тип лида',
					options: []
				},
			},
			filterOptions: [[]],
			viewOffers: [],
			viewOfferFields: [],
			typeOffers: [],
		}
	},
	computed: {
		...mapGetters({
			data_offers: 'offers/getOffers',
			loadingOffers: 'offers/getLoading',
			dataFields: 'questions/getQuestions',
			loadingQuestions: 'questions/getLoading'
		})
	},
	methods: {
		visiblePopupCardOffer(key) {
			this.visibleOffer = key
			// console.log(this.data_offers[key].fields, '        this.data_offers[key]this.data_offers[key]this.data_offers[key]this.data_offers[key]')
			this.renderOfferFields(this.data_offers[key].fields)
			this.visiblePopup.InformOffer = true
		},
		closePopup() {
			this.visiblePopup.InformOffer = false
		},
		filterOffers(options, type) {
			if (type === 'Тип лида') {
				this.filterOptions[0] = options
			}
			// else if (type === 'Источник') {
			// 	this.filterOptions[1] = options
			// }
			this.renderOffersView()

		},
		renderFiltersOffers() {
			let filter = [
				{
					title: 'Тип лида',
					options: []
				},
			]
			// console.log(this.data_offers, '    this.data_offers');
			this.data_offers.forEach(offer => {
				filter[0].options.push(offer.type)
			})
			filter.forEach((section) => {
				section.options = [...new Set(section.options)]
			})
			this.filterOffersOptions = filter
			this.loading.offers = false
		},
		renderOffersView() {
			let offers = [...this.data_offers]

			if (this.filterOptions[0].length) {
				offers = offers.filter((offer) => this.filterOptions[0].findIndex((value) => value === offer.type) !== -1)
			}
			// if (this.filterOptions[1].length) {
			// 	offers = offers.filter((phone) => this.filterOptions[1].findIndex((value) => value === phone.inform.source) !== -1)
			// }

			this.viewOffers = offers
		},
		renderOfferFields(fields) {
			let res = []
			this.dataFields.forEach((field) => {
				if (fields[field.key]) {
					res.push({
						field: field.name,
						type: field.type.label,
						// requirements: '' // fields[field.key]
					})
				}
			})

			this.viewOfferFields = res
		},
		// renderTypeOffers() {
		// 	this.data_offers.forEach((offer) => {
		// 		let type = this.typeOffers.find((type) => String(type.key) === offer.type)
		// 		if (type) offer.type = type.value
		// 		// this.loading.table = false
		// 	})
		// 	this.renderFiltersOffers()
		// }
	},
	watch: {
		loadingOffers() {
			this.renderOffersView()
		},
		loadingQuestions() {
			this.renderFiltersOffers()
		}
	},
	mounted() {
		// this.$http.post("/offers/all",
		// 	{},
		// 	(data) => {
		// 		this.data_offers = data
		// 		this.renderFiltersOffers()
		// 		this.renderOffersView()
		// 	})
		// this.data_offers = JSON.parse(localStorage.offers)this.$store.getters['feeders/feedersById']
		// this.data_offers = this.$store.getters['offers/getOffers']
		if (this.loadingOffers) {
			// this.renderFiltersOffers()
			this.renderOffersView()
		}
		if (this.loadingQuestions) {
			this.renderFiltersOffers()
		}

		// this.$http.post("/offers/questselect",
		// 	{},
		// 	(data) => {
		// 		this.dataFields = data
		// 	})


		// this.dataFields = JSON.parse(localStorage.questions)
	}
}
</script>

<style scoped>
.offers_block {
	background: #ffffff;
	border-radius: 2px;
}

.left_filter_page {
	padding: 15px 25px;
}

.flow_main__card_body__info,
.offers_block_info {
	display: flex;
	/*justify-content: space-between;*/
	align-items: center;
}

.flow_main__card_body__info>div {
	margin-right: 25px;
}

.offers_block_info {
	justify-content: space-between;
}
</style>