<template>
	<a-skeleton v-if="value.length === 0" active />
	<a-timeline v-else>
		<a-timeline-item v-for="(item, key) of value" :key="key" :color="item?.color">
			<template v-if="key ===  0" #dot>
				<clock-circle-outlined style="font-size: 16px"/>
			</template>
			<p v-for="(text,i) in item.text" :key="i">{{ text }}</p>
		</a-timeline-item>
	</a-timeline>
</template>

<script>
import {ClockCircleOutlined} from '@ant-design/icons-vue';

export default {
	name: "TimeLine",
	props: [ 'value' ],
	components: {
		ClockCircleOutlined
	}
}
</script>

<style scoped>

</style>