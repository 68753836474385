<template>
	<TitlePage :title="`Лиды базы ${idBase}`" subtitle="" :border="true">
		<!--		<template v-slot:title_right>-->
		<!--			<a-button type="primary" @click="popup.addFlow = true" :disabled="!selectedTableRows.length">-->
		<!--				Отправить оператору-->
		<!--			</a-button>-->
		<!--		</template>-->
		<FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
			buttonShow: true,
			numberPhone: true,
			minMax: true,
			sub: true,
		}" @clickButtonFilter="clickButtonFilter" />
	</TitlePage>
	<!--	:row-selection="rowSelection"-->
	<a-table :columns="lids.columns" :data-source="lids.data" :loading="loading.table" :pagination="pagination"
		@change="serverPagination">
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'phone'">
				{{ phoneCustom(record.phone) }}
			</template>
			<template v-else-if="column.key === 'operation'">
				<span>
					<a @click="informPhonePopup(record.phone)">Анкета</a>
				</span>
			</template>
		</template>
	</a-table>

	<!--	<BasicModal-->
	<!--			title="Добавить нового оператора"-->
	<!--			:visible="popup.addFlow"-->
	<!--			@closePopup="closePopup()"-->
	<!--			:btn-ok="'Выйти'"-->
	<!--			:widthPopup="700"-->
	<!--	>-->
	<!--		<a-table :columns="operator.columns" :data-source="operator.data" :pagination="false">-->
	<!--			<template #bodyCell="{ column, record }">-->
	<!--				<template v-if="column.key === 'phone'">-->
	<!--					{{ record.phone }}-->
	<!--				</template>-->
	<!--				<template v-else-if="column.key === 'operation'">-->
	<!--					<span>-->
	<!--						<a-button type="primary">-->
	<!--							Добавить-->
	<!--						</a-button>-->
	<!--					</span>-->
	<!--				</template>-->
	<!--			</template>-->
	<!--		</a-table>-->
	<!--	</BasicModal>-->

	<BasicModal :title="`Вся информация по номеру ${this.inform_phone}`" :visible="popup.inform_lid"
		@closePopup="closePopup()" :btn-ok="'Выйти'" :widthPopup="700">
		<a-spin :spinning="loading.informPhone">
			<a-tabs v-model:activeKey="activeKeyTabInformLid">
				<a-tab-pane key="1" tab="История">
					<TimeLine :value="numberHistory" />
				</a-tab-pane>
				<a-tab-pane key="3" tab="Поля" force-render>
					<a-form class="number_inform_form" layout="vertical">
						<component class="number_inform_form_item" v-for="field of resultFields" :key="field.key"
							:is="field.type.value" :options="field.options" :value="field.value" :name="field.name"
							:disabled="true"></component>
					</a-form>
				</a-tab-pane>
				<a-tab-pane key="4" tab="Офферы">
					<a-row type="flex" :gutter="35">
						<a-col :flex="2">
							<div>
								<a-divider orientation="left">Отправленные оферы</a-divider>
								<OffersCards :offers-arr="sendOffers" :allSend="true"></OffersCards>
							</div>
						</a-col>
						<a-col :flex="3">
							<div>
								<a-divider orientation="left">Действия</a-divider>
								<ActionPhoneList :action-list="actionListPhone" :showStats="true" />
							</div>
						</a-col>
					</a-row>
				</a-tab-pane>
				<a-tab-pane key="5" tab="Записи">
					<a-table :columns="player.columns" :data-source="player.data" :pagination="false">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'date'">
								{{ record.date }}
							</template>
							<template v-if="column.key === 'player'">
								<audio :src="record.player" controls></audio>
							</template>
						</template>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-spin>
	</BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import BasicModal from "@/components/modal/BasicModal";
import FiltersHeader from "@/components/ui/FiltersHeader.vue";
import TimeLine from "@/components/ui/TimeLine.vue";
import OffersCards from "@/components/ui/OffersCards.vue";
import ActionPhoneList from "@/components/ui/ActionPhoneList.vue";

import inputText from "@/components/field/input-text";
import inputNumber from "@/components/field/input-number";
import inputSelect from "@/components/field/input-select";
import inputTreeSelect from "@/components/field/input-tree-select";
import inputBoolean from "@/components/field/input-boolean";
import inputDate from "@/components/field/input-date";
import inputRangeDate from "@/components/field/input-range-date";
import inputRangeNumber from "@/components/field/input-range-number";

import { mapGetters } from "vuex";

import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";


export default {
	name: "LidsAdmin",
	mixins: [formattingPhoneMixin],
	components: {
		TitlePage,
		BasicModal,
		FiltersHeader,
		TimeLine,
		OffersCards,
		ActionPhoneList,

		inputText,
		inputNumber,
		inputSelect,
		inputTreeSelect,
		inputBoolean,
		inputDate,
		inputRangeDate,
		inputRangeNumber,
	},
	data() {
		return {
			idBase: '',
			activeKeyTabInformLid: '1',
			inform_phone: '',
			loading: {
				// TODO тут поставить true и настроить изменения после получения данных
				table: true,
				informPhone: false
			},
			selectedTableRows: [],
			// rowSelection: {
			// 	checkStrictly: false,
			// 	onChange: (selectedRowKeys, selectedRows) => {
			// 		console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
			// 		this.selectedTableRows = selectedRows
			// 	},
			// 	onSelect: (record, selected, selectedRows) => {
			// 		console.log(record, selected, selectedRows);
			// 	},
			// 	onSelectAll: (selected, selectedRows, changeRows) => {
			// 		console.log(selected, selectedRows, changeRows);
			// 	},
			// },
			popup: {
				addFlow: false,
				inform_lid: false
			},
			lids: {
				columns: [
					{
						title: 'Номер',
						dataIndex: 'phone',
						key: 'phone'
					},
					{
						title: 'Дата',
						dataIndex: 'date',
						key: 'date'
					},
					{
						title: 'Sub',
						dataIndex: 'sub',
						key: 'sub'
					},
					{
						title: 'Звонков',
						dataIndex: 'count_call',
						key: 'count_call'
					},
					{
						title: 'Действия',
						dataIndex: 'operation',
						key: 'operation'
					}
				],
				data: []
			},
			filter: {
				text: '',
				rangeNum: {
					min: null,
					max: null
				},
				sub: ''
			},
			// operator: {
			// 	columns: [
			// 		{
			// 			title: 'ФИО',
			// 			dataIndex: 'name',
			// 			key: 'name'
			// 		},
			// 		{
			// 			title: 'Статус',
			// 			dataIndex: 'status',
			// 			key: 'status'
			// 		},
			// 		{
			// 			title: 'Количество в потоке',
			// 			dataIndex: 'count_flow',
			// 			key: 'count_flow'
			// 		},
			//
			// 		{
			// 			title: 'Действия',
			// 			dataIndex: 'operation',
			// 			key: 'operation'
			// 		}
			// 	],
			// 	data: [
			// 		{
			// 			key: 1,
			// 			name: 'ФИО оператора',
			// 			status: 'online',
			// 			count_flow: 5,
			// 		},
			// 		{
			// 			key: 2,
			// 			name: 'Бобрык Черемша',
			// 			status: 'offline',
			// 			count_flow: 43,
			// 		},
			// 		{
			// 			key: 3,
			// 			name: 'Я я-я-я',
			// 			status: 'online',
			// 			count_flow: 0,
			// 		},
			// 	]
			// },
			numberHistory: [],
			resultFields: [],
			actionListPhone: [
				{
					value: 'Отправка на схему',
					day: 20
				},
				{
					value: 'Отправка на схему “Бембежка СМС”',
				},
				{
					value: 'Перезвон 12-12-2022',
				},
			],
			sendOffers: [
				// {
				// 	title: 'CreditPlus',
				// 	subtitle: 'Заявка',
				// 	status: 'success',
				// 	Dropdown: []
				// },
				// {
				// 	title: 'QQQQQ',
				// 	subtitle: 'Заявка',
				// 	status: 'success',
				// 	Dropdown: []
				// },
				// {
				// 	title: 'MMM',
				// 	subtitle: 'Заявка',
				// 	status: 'success',
				// 	Dropdown: []
				// },
			],
			player: {
				columns: [
					{
						title: 'Дата',
						dataIndex: 'date',
						key: 'date'
					},
					{
						title: 'Запись',
						dataIndex: 'player',
						key: 'player'
					}
				],
				data: [
					// {
					// 	"key": 0,
					// 	"phone": "79522062389",
					// 	"date": "2023-01-24 19:01:57",
					// 	"player": "https://storage.yandexcloud.net/k8srecords/ney8o6g1xi.mp3",
					// }
				]
			},
			pagination: {
				total: 500,
				pageSize: 100
			},
		}
	},
	computed: {
		...mapGetters({
			// dataOffers: 'offers/getOffers',
			dataFields: 'questions/getQuestions',
		})
	},
	methods: {
		clickButtonFilter() {
			// тут запрос с фильтрами, сами фильтры обновляются при изменении, это можно изменить и слать по клику
			this.loading.table = true
			this.$http.post("/clients/select",
				{
					base: this.idBase,
					limit: this.pagination.pageSize,
					offset: ((this.pagination.current - 1) * this.pagination.pageSize),
					filter: this.filter
				},
				(data) => {
					this.lids.data = data.lids
					this.pagination.total = data.count
					this.loading.table = false
				})
		},
		informPhonePopup(phone) {
			this.inform_phone = phone
			this.loading.informPhone = true
			// тут получаем все нужные данные по лиду

			// this.$http.post("/offers/questselect",
			// 	{},
			// 	(data) => {
			// 		this.dataFields = data
			// 		console.log(this.dataFields, 'this.dataFields this.dataFields this.dataFields')
			// 		this.$http.post("/clients/anketa",
			// 			{
			// 				number: this.inform_phone
			// 			},
			// 			(anketa) => {
			// 				this.phoneFields = anketa
			// 				console.log(this.phoneFields, ' this.phoneFields this.phoneFields this.phoneFields')
			// 				this.renderFields()
			// 			})
			// 	})
			this.$http.post("/clients/anketa",
				{
					number: this.inform_phone
				},
				(anketa) => {
					this.phoneFields = anketa
					this.renderFields()
				})
			this.$http.post("/clients/history",
				{
					number: this.inform_phone
				},
				(data) => {
					this.numberHistory = data
					this.loading.informPhone = false
				})
			this.$http.post("/bases/clientdata",
				{
					number: this.inform_phone
				},
				(data) => {
					this.player.data = data.records
					this.sendOffers = data.sendOffers
					this.actionListPhone = data.actions
				})

			//this.numberHistory = []
			this.popup.inform_lid = true
		},
		renderFields() {
			let fields = []

			this.dataFields.forEach((field) => {
				let value = this.phoneFields[field.key]
				if (value) {
					fields.push({
						key: field.key,
						value: value,
						name: field.name,
						type: field.type,
						options: field.options
					})
				}
			})
			this.resultFields = fields
		},
		serverPagination(pagination) {
			// тут короче серверна пагинация, я просто перенес с прошлой

			this.idBase = this.$route.params.id
			this.loading.table = true
			this.$http.post("/clients/select",
				{
					base: this.idBase,
					limit: pagination.pageSize,
					offset: ((pagination.current - 1) * pagination.pageSize)
				},
				(data) => {
					this.lids.data = data.lids
					this.pagination.total = data.count
					this.pagination.pageSize = pagination.pageSize
					this.loading.table = false
				})
		},
		addFlowAdmin() {
			// this.$http.post("/operators/register",
			// 	{
			// 		data: this.addNewOperator
			// 	},
			// 	() => {
			// 		this.getAllOperators()
			// 	})
			this.closePopup()
		},
		changeFilter(filter) {
			this.filter = filter
		},
		closePopup() {
			this.numberHistory = []
			this.resultFields = []
			this.sendOffers = []
			this.player.data = []

			this.popup.addFlow = false
			this.popup.inform_lid = false
		},
	},
	mounted() {
		this.idBase = this.$route.params.id
		// в title вывести нормальное название вместо ид (потом прикручу заглушку на времям загрузки)
		// запрос лидов базы по ид
		this.$http.post("/clients/select",
			{
				limit: 100,
				offset: 0,
				base: this.idBase
			},
			(data) => {
				this.lids.data = data.lids
				this.pagination.total = data.count
				this.loading.table = false
			})
		// this.lids.data = [
		// 	{
		// 		key: 1,
		// 		phone: '123123',
		// 		date: '12/12/2012',
		// 		count_call: 2,
		// 		sub: 'я саб'
		// 	},
		// 	{
		// 		key: 2,
		// 		phone: '123123',
		// 		date: '12/12/2012',
		// 		count_call: 0,
		// 		sub: 'кек'
		// 	},
		// 	{
		// 		key: 3,
		// 		phone: '123123',
		// 		date: '12/12/2012',
		// 		count_call: 10,
		// 		sub: 'я саб'
		// 	},
		// ]
	}
}
</script>

<style scoped></style>