<template>
	<a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
		<a-range-picker @change="changInput" v-model:value="valueInput" style="width: 100%" :style="styleDisplay"/>
	</a-form-item>
</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";
import dayjs from "dayjs";
import moment from 'moment';

export default {
	name: "input-range-date",
	props: ['value', 'name', 'id', 'display'],
	mixins: [ChangInputMixins],
	data() {
		return {
			valueInput: [],
			dateFormat: 'DD-MM-YYYY',
		}
	},
	methods: {
		// changInput() {
		// 	this.$emit('changInput', this.valueInput)
		// }
	},
	mounted() {
		if (typeof(this.value) === typeof([])) {
			this.value.forEach((date) => {
				let formatDate = moment(date).format('DD-MM-YYYY')
				this.valueInput.push(dayjs(formatDate, this.dateFormat))
			})
		}
	},
}
</script>

<style scoped>

</style>