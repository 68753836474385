<template>
	<template v-if="!viewProfile">
		<TitlePage title="Cтаршие операторы" subtitle="This is power!" :border="true">
			<template v-slot:title_right>
				<a-button type="primary" @click="popup.addOperator = true">
					Добавить администратора
				</a-button>
			</template>
			<!--			<FiltersHeader :filter-value="filter" @changeFilter="changeFilter"/>-->
		</TitlePage>


		<a-table :columns="columns" :data-source="data" :loading="loading.table">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'name'">
					{{ record.name }}
				</template>
				<template v-else-if="column.key === 'control'">
						<span>
							
							<a @click="renderProfile(record.id)">Профиль</a>
						</span>
				</template>
			</template>
		</a-table>
	</template>

	<template v-else>
		<TitlePage :title="dataProfile.name" subtitle="" :backPage="() => {this.viewProfile = false}"
				:border="true"></TitlePage>
		<div class="profile_operator">
			<a-form class="number_inform_form" layout="vertical" style="max-width: 400px">
				<a-form-item label="ФИО">
					<a-input v-model:value="dataProfile.name"/>
				</a-form-item>
				<a-form-item label="Login">
					<a-input v-model:value="dataProfile.profile.login"/>
				</a-form-item>
				<a-form-item label="Pass">
					<a-input v-model:value="dataProfile.profile.pass"/>
				</a-form-item>
				<a-form-item label="Service">
					<a-input v-model:value="dataProfile.profile.service"/>
				</a-form-item>
        <a-form-item label="Api token">
          <a-input v-model:value="dataProfile.profile.apitoken" disabled/>
        </a-form-item>
		<a-button type="primary" @click="saveProfile">Сохранить</a-button>
			</a-form>
		</div>
	</template>

	<BasicModal
			title="Добавить нового оператора"
			:visible="popup.addOperator"
			@closePopup="closePopup()"
			:callback="createAdmin"
			:btn-ok="'Добавить'"
	>
		<a-form class="number_inform_form" layout="vertical">
			<a-form-item label="ФИО">
				<a-input v-model:value="addNewAdmin.name"/>
			</a-form-item>

			<a-form-item label="Логин">
				<a-input v-model:value="addNewAdmin.profile.login"/>
			</a-form-item>
			<a-form-item label="Пароль">
				<a-input v-model:value="addNewAdmin.profile.pass"/>
			</a-form-item>
			<a-form-item label="Сервис">
					<a-input v-model:value="addNewAdmin.profile.service"/>
				</a-form-item>

		</a-form>
	</BasicModal>


</template>

<script>
import TitlePage from "@/components/TitlePage";
// import FiltersHeader from "@/components/ui/FiltersHeader";
import BasicModal from "@/components/modal/BasicModal";
import { notification } from 'ant-design-vue';
export default {
	name: "AdminSuperAdmin",
	components: {
		TitlePage,
		BasicModal,
	},
	data() {
		return {
			loading: {
				table: true
			},
			viewProfile: false,
			dataProfile: {},
			addNewAdmin: {
				name: '',
				profile: {
					login: '',
					pass: '',
          apitoken:'',
	service:''
				}
			},
			dateFormat: 'DD/MM/YYYY',
			popup: {
				addOperator: false
			},

			columns: [
				{
					title: 'ФИО',
					dataIndex: 'name',
					key: 'name',
				},
				// {
				// 	title: 'Активность',
				// 	dataIndex: 'active',
				// 	key: 'active',
				// },
				{
					title: 'Управление',
					key: 'control',
				},
			],
			data: [],
		}
	},
	methods: {

		saveProfile(){
			// let name = this.dataProfile.name
			// let login = this.dataProfile.login
			// let pass= this.dataProfile.profile.pass
			
			this.$http.post("/operators/saveAdminProfile",
			{
				data: this.dataProfile
			},
			(data)=>{
				if(data === "Done"){
					notification.open({
                message: 'Изменения сохранены',
                description: '',
                duration: 2,
                placement: 'topRight'
              });
			} else if(data ==="PassDone"){
				notification.open({
                message: 'Пароль успешно изменен',
                description: '',
                duration: 2,
                placement: 'topRight'
				});
			} else if(data ==='False'){
				notification.open({
                message: 'Пароль должен содержать 7 или более символов символов',
                description: '',
                duration: 2,
                placement: 'topRight',
				style: {
        backgroundColor: 'rgb(224, 55, 55)'
				}
				})
			}
			}
			)
			
		},
		// changeFilter(filter) {
		// 	this.filter = filter
		// },
		renderProfile(id) {
			// получаем данные выбраного профиля по ид, пока импровизация
			this.dataProfile = this.data.find(item => item.id === id)
			this.viewProfile = true
		},
		createAdmin() {
			this.$http.post("/operators/mainregister",
				{
					data: this.addNewAdmin
				},
				() => {
					this.getAllAdmin()
				})
			this.closePopup()
			
		},
		getAllAdmin(){
			this.$http.post("/operators/mainall",
			{},
			(data) => {
				this.data = data
				this.loading.table = false
			})
			this.data = [
				{
					id: 123123,
					name: 'admin1',
					profile: {
						login: 'qweqwe',
						pass: 'asdasd'
					}
				},
				{
					id: 123343,
					name: 'admin2',
					profile: {
						login: 'asdasd',
						pass: 'asdasd'
					}
				},
				{
					id: 124334,
					name: 'admin3',
					profile: {
						login: 'zxczxc',
						pass: 'asdasd'
					}
				}
			]
			this.loading.table = false
		},
		closePopup() {
			this.popup.addOperator = false
		},
	},
	mounted() {
		this.getAllAdmin()
	}
}
</script>

<style scoped>
.profile_operator {
    background: #FFFFFF;
    border-radius: 2px;
    padding: 15px;
}
</style>