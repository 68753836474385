<template>
	<HeaderLayout>
		<a-layout>
			<LeftMenuMain />
			<a-layout :style="{ padding: 0 }">

				<a-layout-content
					:style="{ background: '#F0F2F5', padding: '10px', margin: 0, minHeight: '280px', height: '100%' }">

					<router-view v-if="rightsCheck"></router-view>
					<div v-else>
						Недостаточно прав, войдите как администратор
					</div>

				</a-layout-content>
			</a-layout>
		</a-layout>
	</HeaderLayout>


	<!--	</a-layout>-->
</template>
<script>
import LeftMenuMain from "@/components/LeftMenuMain";
import { defineComponent } from 'vue';

import HeaderLayout from "@/layouts/HeaderLayout";
import { mapGetters } from "vuex";

export default defineComponent({
	name: "MainLayout",
	components: {
		LeftMenuMain,
		HeaderLayout
	},
	computed: {
		...mapGetters({ roleUser: 'user/getRole' }),
		rightsCheck() {
			// console.log(this.$route.meta.role, '           this.$route.meta.role')
			if (this.$route.meta.role === 'admin') {
				return this.roleUser === 'admin'
			} else {
				// console.log(true, '          true')
				return true
			}
		}
	}
});
</script>
<style>
#components-layout-demo-top-side-2 .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
	float: right;
	margin: 16px 0 16px 24px;
}

.site-layout-background {
	background: #fff;
}
</style>