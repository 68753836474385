<template>
    <div id="setting_tab" class="card-container">
        <a-tabs type="card">
            <a-tab-pane key="9" tab="Общая сводка">
                <TitlePage title="Общая сводка" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,

                        //тестовый 
                    }" />
                </TitlePage>
                <!--		============================ TABLE =========================-->
                <a-divider>МФО</a-divider>
                <a-table :columns="generalColumns" :data-source="mfo" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table>
                <a-divider>Итого МФО</a-divider>
                <a-table :columns="generalColumns" :data-source="general_mfo" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table>
                <a-divider>БФЛ</a-divider>
                <a-table :columns="generalColumns" :data-source="bfl" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table>
                <a-divider>Залоги</a-divider>
                <a-table :columns="generalColumns" :data-source="zalogi" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table>
                <a-divider>Банки</a-divider>
                <a-table :columns="generalColumns" :data-source="bank" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table>
                <a-divider>Итого</a-divider>
                <a-table :columns="generalColumns" :data-source="general_stats" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table>

                <!-- <a-table :columns="generalColumns" :data-source="general" :loading="loading.table" :pagination="false"
                    size="small" @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table> -->
                <!-- <a-divider>Итого</a-divider>
                <a-table :columns="generalColumns2" :data-source="general2" :loading="loading.table" :pagination="false"
                    @change="false">
                    <a-column title="Название" dataIndex="name" key="name"></a-column>
                    <a-column title="Значение" dataIndex="value" key="value"></a-column>
                </a-table> -->
            </a-tab-pane>
            <a-tab-pane key="1" tab="Статистика по звонкам">

                <TitlePage title="Статистика" subtitle="Отчёт по звонкам" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        scenario: true,
                        base: true,
                        region: true,


                    }" />

                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        channels: true,
                        datatypes: true
                    }" />

                </TitlePage>
                <!-- <div v-if="calls_data && calls_data.length > 1">
                    <span>Показать график </span>
                    <a-switch v-model:checked="checked" @change="toggleChart" style="margin-bottom:4px" />
                </div> -->
                <!-- <div class="chart" v-if="dataLoaded === true">
                    <chartBar :chartData="ChartData" />
                </div> -->
                <div class="mainChart">
                    <div class="chartCalls">
                        <chartCalls1 :chartData="ChartData" />
                    </div>
                    <div class="chartCalls2">
                        <chartCalls2 :chartData="ChartData3" />
                    </div>
                </div>
                <!--		============================ TABLE =========================-->
                <a-divider>Суммарная статистика</a-divider>
                <a-table :columns="columns" :data-source="summaryStatistics" :loading="loading.table" :pagination="false"
                    @change="false">
                </a-table>
                <a-divider>Статистика по дням(отображается при выборе диапазона дат)</a-divider>
                <a-table v-if="calls_data && calls_data.length > 1" :columns="columnsForDays" :data-source="calls_data"
                    :loading="loading.table" :pagination="false" row-key="day" @change="false">
                    <template v-slot="{ text, record }">
                        <a-table-row-group v-if="record.calls_amount !== undefined">
                            <a-table-row :record="record">
                                <a-table-cell :colspan="columnsForDays.length">{{ record.day }}</a-table-cell>
                            </a-table-row>
                        </a-table-row-group>
                        <a-table-row :record="record" v-else>

                            <a-table-cell>{{ text }}</a-table-cell>
                        </a-table-row>
                    </template>
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="10" tab="Пропущенные">
                <TitlePage title="Статистика" subtitle="Отчёт по пропущенным звонкам" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,


                    }" />
                </TitlePage>
                <!--		============================ TABLE =========================-->

                <a-table :columns="missed_calls" :data-source="missed_calls_data" :loading="loading.table"
                    :pagination="false" @change="false">
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Детализация отправок">
                <TitlePage title="Статистика" subtitle="Отчёт по отправкам" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        offers: true,
                        base: true,
                        region: true,

                    }" />
                </TitlePage>

                <!--		============================ TABLE =========================-->
                <a-table :columns="offers_columns" :data-source="sends_data" :loading="loading.table" size="small"
                    :pagination="true" @change="false">
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Статистика по СМС">
                <TitlePage title="Статистика" :subtitle="statsforsms" :border="true">

                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        singleoperator: true,
                        base: true,
                        offers: true

                    }" />

                </TitlePage>

                <div class="smsChart">
                    <chartsms v-if="sms_sum_data2 && sms_sum_data2.length > 1" :chartData="ChartSms" />
                </div>
                <a-divider>Отчёт по конверсии</a-divider>
                <a-table :columns="sms_stats_columns" :data-source="sms_sum_data" :loading="loading.table" size="small"
                    :pagination="paginationSmsStats" @change="false">
                </a-table>
                <a-divider>Отчет по конверсии за отдельные даты(отображается при выборе диапазона дат)</a-divider>
                <a-table v-if="sms_sum_data2 && sms_sum_data2.length > 1" :columns="sms_stats_columns2"
                    :data-source="sms_sum_data2" :loading="loading.table" size="small" :pagination="paginationSmsStats"
                    @change="false">
                </a-table>
                <!--		============================ TABLE =========================-->



            </a-tab-pane>

            <a-tab-pane key="4" tab="Детализация  СМС">
                <TitlePage title="Статистика" :subtitle="statsforsms" :border="true">

                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                    }" />
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        singleoperator: true,
                        offers: true

                    }" />

                </TitlePage>
                <a-divider>Отчёт по конверсии</a-divider>
                <a-table :columns="smscolumns_conversion" :data-source="sms_profit" :loading="loading.table" size="small"
                    :pagination="true" @change="false">
                </a-table>
                <!--		============================ TABLE =========================-->
                <a-divider>Отчёт по СМС</a-divider>
                <a-table :columns="smscolumns" :data-source="sms_data" :loading="loading.table" :pagination="true"
                    size="small" @change="false">
                </a-table>



            </a-tab-pane>

            <a-tab-pane key="5" tab="Статистика по операторам">
                <TitlePage title="Статистика" subtitle="Отчёт по звонкам" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,
                        singleoperator: true,
                        base: true

                    }" />
                </TitlePage>
                <!--		============================ TABLE =========================-->
                <a-divider>Статистика по звонкам</a-divider>
                <a-table :columns="all_results_columns" :data-source="all_results_data" :loading="loading.table"
                    :pagination="false" @change="false">
                </a-table>
                <a-divider>Результаты звонков</a-divider>

                <a-table :columns="calls_results_columns" :data-source="calls_results_data" :loading="loading.table"
                    :pagination="false" @change="false">
                </a-table>
                <div class="pieGrapth">
                    <chart3 :chartData="ChartData2" />
                </div>
            </a-tab-pane>

            <a-tab-pane key="6" tab="Статистика по приходу сабов">
                <TitlePage title="Статистика по приходу сабов" :border="true">
                    <FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
                        rangePicker: true,


                    }" />
                </TitlePage>
                <!--		============================ TABLE =========================-->

                <a-table :columns="inserts_buffer" :data-source="insertData" :loading="loading.table" :pagination="false"
                    @change="false">
                </a-table>

            </a-tab-pane>
        </a-tabs>



        <!--		============================ TABLE =========================-->



    </div>
</template>

<script>
import chart3 from "@/components/ui/charts/ChartYandex3";
import chartsms from "@/components/ui/charts/ChartSms";
import TitlePage from "@/components/TitlePage";
import FiltersHeader from "@/components/ui/FiltersHeader";
import dayjs from 'dayjs';
// import chartBar from "@/components/ui/ChartBar";
import chartCalls1 from "@/components/ui/charts/ChartCalls";
import chartCalls2 from "@/components/ui/charts/ChartCalls2";

//import moment from "moment/moment";
export default {
    name: "CallHistory",
    components: {
        TitlePage,
        FiltersHeader,
        chart3,
        chartsms,
        chartCalls1,
        chartCalls2
    },

    data() {


        // this.$http.post("/statistics/all",
        //     {
        //     },
        //     (data) => {
        //     console.log(data)
        //         this.calls_data = data.calls_statistics
        //         this.sends_data = data.offers_send
        //         this.loading.table = false
        //         this.pagination.total = this.sends_data.length
        //     })


        return {
            statsforsms: '',
            dataLoaded: false,
            cost: '111',
            ChartData: {

                datasets: [
                    {
                        label: 'Цена звонков',
                        data: [], // данные для столбцов
                        borderWidth: 1,
                        borderColor: 'rgba(255, 99, 132, 1)',
                    },
                ],
            },

            ChartData2: {
                labels: ['Положительный%', 'Робот%', 'Негатив%', 'Тишина%', 'Не интересно%','Предодобрено%','Отказано%','Не взял трубку%','Сбросил%','Отправили смс%','Отправили КП%'],
                datasets: [
                    {

                        data: [],
                        borderWidth: 1,
                        fill: true,
                        backgroundColor: ['rgb(127, 255, 0)', 'rgb(128, 128, 128)', 'rgb(165, 42, 42)', 'rgb(65, 105, 225)', 'rgb(218, 165, 32)'],
                    },
                ],
            },
            ChartData3: {

                datasets: [
                    {
                        label: 'Себестоимость отправки',
                        data: [], // данные для столбцов
                        borderWidth: 1,
                        borderColor: 'rgba(0, 0, 128)',
                    },
                ],
            },
            ChartSms: {

                datasets: [
                    {
                        label: 'Доходы',
                        data: [], // данные для доходов
                        borderWidth: 1,
                        borderColor: 'rgba(75, 192, 192, 1)',
                    },
                    {
                        label: 'Расходы',
                        data: [], // данные для расходов
                        borderWidth: 1,
                        borderColor: 'rgba(255, 99, 132, 1)',
                    },
                ],
            },


            combined_calls_data: [],
            sms_count: '',
            // numberPhone: '',
            loading: {
                table: true,
            },
            pagination: {
                current: 1,
                total: 50,
                pageSize: 1
            },
            paginationSmsConversion: {
                total: 1,
                pageSize: 10
            },
            paginationSmsStats: {
                total: 1,
                pageSize: 10
            },
            filter: {
                rangeDate: [],
                offers: {
                    value: [],
                    options: [
                        { value: '5', title: '5' },
                    ]
                },
                scenario: {
                    value: [],
                    options: [
                        { value: '5', title: '5' },
                    ]
                },
                base: {
                    value: [],
                    options: [
                        { value: '1', title: '112' },
                    ]
                },
                region: {
                    value: [],
                    options: [
                        { value: '1', title: '112' },
                    ]
                },
                singleoperator: {
                    value: [],
                    options: [
                        { value: '', title: '' }
                    ]
                },
                channels: {
                    value: [],
                    options: [
                        { value: '1', title: '1' }
                    ]
                },
                datatypes: {
                    value: [],
                    options: [
                        { value: '', title: '' }
                    ]
                }
            },
            columns: [
                {
                    width: '200px',
                    customRender: () => 'За выбранный период',
                },
                {
                    title: 'Количество звонков',
                    dataIndex: 'total_calls_amount',
                    key: 'total_calls_amount',
                },
                {
                    title: 'Количество звонков с оператором',
                    dataIndex: 'total_calls_operator_amount',
                    key: 'total_calls_operator_amount',
                },
                {
                    title: 'Процент дозвона до оператора',
                    dataIndex: 'totalPercentToOperator',
                    key: 'totalPercentToOperator',
                },
                {
                    title: 'Количество секунд',
                    dataIndex: 'total_seconds_amount',
                    key: 'total_seconds_amount',
                },
                {
                    title: 'Среднее время звонка оператора, секунды',
                    dataIndex: 'total_avg_operator_time_call',
                    key: 'total_avg_operator_time_call',
                },
                {
                    title: 'Цена звонков, руб',
                    dataIndex: 'total_calls_cost',
                    key: 'total_calls_cost',
                },
                {
                    title: 'Количество отправок',
                    dataIndex: 'total_sends_amount',
                    key: 'total_sends_amount',
                },
                {
                    title: 'Цена робота, руб',
                    dataIndex: 'total_robot_cost',
                    key: 'total_robot_cost',
                },
                {
                    title: 'Себестоимость отправки, руб',
                    dataIndex: 'total_full_cost',
                    key: 'total_full_cost',
                },
            ],
            columnsForDays: [
                {
                    title: 'Дата',
                    dataIndex: 'calls_date',
                    key: 'calls_date',
                    width: '200px'
                },
                {
                    title: 'Количество звонков',
                    dataIndex: 'calls_amount',
                    key: 'calls_amount',
                },
                {
                    title: 'Количество звонков с оператором',
                    dataIndex: 'calls_operator_amount',
                    key: 'calls_operator_amount',
                },
                {
                    title: 'Процент дозвона до оператора',
                    dataIndex: 'percent_to_operator',
                    key: 'percent_to_operator',
                },
                {
                    title: 'Количество секунд',
                    dataIndex: 'seconds_amount',
                    key: 'seconds_amount',
                },
                {
                    title: 'Среднее время звонка оператора, секунды',
                    dataIndex: 'avg_operator_time_call',
                    key: 'avg_operator_time_call',
                },
                {
                    title: 'Цена звонков, руб',
                    dataIndex: 'calls_cost',
                    key: 'calls_cost',
                },
                {
                    title: 'Количество отправок',
                    dataIndex: 'sends_amount',
                    key: 'sends_amount',
                },
                {
                    title: 'Цена робота, руб',
                    dataIndex: 'robot_cost',
                    key: 'robot_cost',
                },
                {
                    title: 'Себестоимость отправки, руб',
                    dataIndex: 'full_cost',
                    key: 'full_cost',
                },
            ],
            offers_columns: [
                {
                    title: 'Дата отправки',
                    dataIndex: 'date_create',
                    key: 'date_create',
                },
                {
                    title: 'Оператор',
                    dataIndex: 'operator_id',
                    key: 'operator_id',
                },
                {
                    title: 'Название оффера',
                    dataIndex: 'offer_id',
                    key: 'offer_id',
                },
                {
                    title: 'Номер',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: 'Статус',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: 'Ошибка',
                    dataIndex: 'error',
                    key: 'error',
                },
            ],
            smscolumns: [
                {
                    title: 'Номер',
                    dataIndex: 'phone',
                    key: 'phone'
                },
                {
                    title: 'Статус',
                    dataIndex: 'status',
                    key: 'status'

                },
                {
                    title: 'Ошибка',
                    dataIndex: 'error',
                    key: 'error'

                },
                {
                    title: 'Альфа',
                    dataIndex: 'alpha',
                    key: 'alpha'
                },
                {
                    title: 'Текст',
                    dataIndex: 'text',
                    key: 'text'
                },
                {
                    title: 'Сотовый оператор',
                    dataIndex: 'operator',
                    key: 'operator'
                },
                {
                    title: 'Оператор',
                    dataIndex: 'name',
                    key: 'name'
                },

            ],
            smscolumns_conversion: [
                {
                    title: 'Номер',
                    dataIndex: 'phone',
                    key: 'phone'
                },

                {
                    title: 'Альфа',
                    dataIndex: 'alpha',
                    key: 'alpha'
                },
                {
                    title: 'Текст',
                    dataIndex: 'text',
                    key: 'text'
                },
                {
                    title: 'Сотовый оператор',
                    dataIndex: 'operator',
                    key: 'operator'
                },
                {
                    title: 'Оператор',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: 'Конверсия',
                    dataIndex: 'profit',
                    key: 'profit'
                },

            ],
            sms_stats_columns: [
                {
                    width: '200px',
                    customRender: () => 'За выбранный период',
                },
                {
                    title: 'Количество смс',
                    dataIndex: 'count',
                    key: 'count'
                },

                {
                    title: 'Количество конверсий',
                    dataIndex: 'sms_profit_count',
                    key: 'sms_profit_count'
                },
                {
                    title: 'Процент успешных смс',
                    dataIndex: 'percent',
                    key: 'percent'
                },
                {
                    title: 'Расход на смс',
                    dataIndex: 'smsCost',
                    key: 'smsCost'
                },
                {
                    title: 'Доход с смс',
                    dataIndex: 'profit',
                    key: 'profit'
                },


            ],
            sms_stats_columns2: [
                {
                    title: 'Дата',
                    dataIndex: 'date',
                    key: 'date'
                },
                {
                    title: 'Количество смс',
                    dataIndex: 'smsCount',
                    key: 'smsCount'
                },

                {
                    title: 'Количество конверсий',
                    dataIndex: 'count',
                    key: 'count'
                },
                {
                    title: 'Процент успешных смс',
                    dataIndex: 'successRate',
                    key: 'successRate'
                },
                {
                    title: 'Расход на смс',
                    dataIndex: 'smsCost',
                    key: 'smsCost'
                },
                {
                    title: 'Доход с смс',
                    dataIndex: 'totalProfit',
                    key: 'totalProfit'
                },


            ],
            inserts_buffer: [
                {
                    title: 'Саб',
                    dataIndex: 'sub',
                    key: 'sub'
                },
                {
                    title: 'Количество новых номеров',
                    dataIndex: 'count',
                    key: 'count'
                },
            ],
            missed_calls: [
                {
                    title: 'Дата',
                    dataIndex: 'date',
                    key: 'date'
                },
                {
                    title: 'Количество пропущенных звонков',
                    dataIndex: 'count',
                    key: 'count'
                },
            ],
            calls_data: [
                {
                    calls_amount: 0,
                    calls_operator_amount: 0,
                    seconds_amount: 0,
                    avg_operator_time_call: 0,
                    calls_cost: 0,
                    sends_amount: 0,
                    robot_cost: 0,
                    full_cost: 0,
                }
            ],
            summaryStatistics: [
                {
                    total_calls_amount: 0,
                    total_calls_operator_amount: 0,
                    total_seconds_amount: 0,
                    total_avg_operator_time_call: 0,
                    total_calls_cost: 0,
                    total_sends_amount: 0,
                    total_robot_cost: 0,
                    total_full_cost: 0,
                }
            ],
            sends_data: [
                {
                    date_create: '',
                    operator_id: 0,
                    offer_id: '0',
                    phone: '900',
                    status: '',
                    error: '',
                }
            ],
            sms_sum_data: [
                {
                    count: 1,
                    profit: 1,
                    percentProfit: 1,
                    cost_sms: 1,
                    income: 1,
                    income2: 1
                }
            ],
            sms_sum_data2: [
                {
                    count: 1,
                    profit: 1,
                    percentProfit: 1,
                    cost_sms: 1,
                    income: 1,
                    income2: 1
                }
            ],
            sms_data: [
                {
                    phone: '',
                    status: '',
                    alpha: '',
                    text: '',
                    operator: '',
                    name: ''
                }
            ],
            all_results_columns: [
                {
                    title: 'Количество звонков',
                    dataIndex: 'calls_amount',
                    key: 'calls_amount',
                },
                {
                    title: 'Количество отправок',
                    dataIndex: 'sends_amount',
                    key: 'sends_amount',
                },
                {
                    title: 'Количество отправленных СМС',
                    dataIndex: 'sms_sends_amount',
                    key: 'sms_sends_amount',
                },
                {
                    title: 'Количество одобрений с отправленных СМС',
                    dataIndex: 'sms_profit_amount',
                    key: 'sms_profit_amount',
                },
            ],
            calls_results_columns: [
                {
                    title: 'Количество звонков',
                    dataIndex: 'calls_amount',
                    key: 'calls_amount',
                },
                {
                    title: 'Положительные',
                    dataIndex: 'good',
                    key: 'good',
                },
                {
                    title: 'Негатив',
                    dataIndex: 'bad',
                    key: 'bad',
                },
                {
                    title: 'Робот/автоответчик',
                    dataIndex: 'robot',
                    key: 'robot',
                },
                {
                    title: 'Тишина в звонке',
                    dataIndex: 'silence',
                    key: 'silence',
                },
                {
                    title: 'Не интересно',
                    dataIndex: 'unsuitable',
                    key: 'unsuitable',
                },
                {
                    title: 'Предодобрено',
                    dataIndex: 'pre_approved',
                    key: 'pre_approved',
                },
                {
                    title: 'Отказано',
                    dataIndex: 'refused',
                    key: 'refused',
                },
                {
                    title: 'Не взял трубку',
                    dataIndex: 'didnt_pick_up',
                    key: 'didnt_pick_up',
                },
                {
                    title: 'Сбросил',
                    dataIndex: 'dropped',
                    key: 'dropped',
                },
                {
                    title: 'Отправили смс',
                    dataIndex: 'send_sms',
                    key: 'send_sms',
                },
                {
                    title: 'Дубль',
                    dataIndex: 'double',
                    key: 'double',
                },
                {
                    title: 'Отправлено КП',
                    dataIndex: 'send_kp',
                    key: 'send_kp',
                },
            ],
            generalColumns: [
                {
                    title: 'Название',
                    dataIndex: 'name',
                    key: 'name',
                    width: '50%',
                },
                {
                    title: 'Значение',
                    dataIndex: 'value',
                    key: 'value',
                    width: '50%',
                },
            ],
            generalColumns2: [
                {
                    title: 'Название',
                    dataIndex: 'name',
                    key: 'name',
                    width: '50%',
                },
                {
                    title: 'Значение',
                    dataIndex: 'value',
                    key: 'value',
                    width: '50%',
                },
            ],
            all_results_data: [
                {
                    calls_amount: 0,
                    sends_amount: 0,
                    sms_sends_amount: 0,
                    sms_profit_amount: 0,
                }
            ],
            calls_results_data: [
                {
                    calls_amount: 0,
                    good: 0,
                    bad: 0,
                    robot: 0,
                    silence: 0,
                    unsuitable: 0,
                    pre_approved:0,
                    refused:0,
                    didnt_pick_up:0,
                    dropped:0,
                    send_sms:0,
                    double:0,
                    send_kp:0
                }
            ],
            sms_profit: [
                {
                    phone: 0,
                    alpha: 0,
                    text: 0,
                    operator: 0,
                    name: 0,
                    profit: 0
                }
            ],
            insertData: [
                {
                    sub: 0,
                    count: 0
                }
            ],
            general: [
                { name: 'Расход на телефонию, руб', value: 0 },
                { name: 'Расход на СМС, руб', value: 0 },
                { name: 'Доход с СМС, руб', value: 0 },
                { name: 'Лидов передано, кол-во', value: 0 },
            ],
            general2: [
                { name: 'Расход, руб', value: 0 },
                { name: 'Доход, руб', value: 0 },
                { name: 'Сальдо, руб', value: 0 },

            ],
            mfo: [
                { name: 'Отправлено СМС', value: 0 },
                { name: 'Конверсий с СМС', value: 0 },
                { name: 'Расход на телефонию, руб', value: 0 },
                { name: 'Расход на СМС, руб', value: 0 },
                { name: 'Доход с СМС, руб', value: 0 },

            ],
            general_mfo: [
                { name: 'Расход, руб', value: 0 },
                { name: 'Доход, руб', value: 0 },
                { name: 'Сальдо, руб', value: 0 },
            ],
            bfl: [
                { name: 'Отправлено лидов', value: 0 },
                { name: 'Расход на телефонию, руб', value: 0 },
                { name: 'Доход с отправок (усреднённо), руб', value: 0 },
                { name: 'Сальдо, руб', value: 0 },
            ],
            zalogi: [
                { name: 'Отправлено лидов', value: 0 },
                { name: 'Расход на телефонию, руб', value: 0 },
                { name: 'Доход с отправок (усреднённо), руб', value: 0 },
                { name: 'Сальдо, руб', value: 0 },
            ],
            bank: [
                { name: 'Расход на телефонию, руб', value: 0 },
                { name: 'Доход', value: '-' },
                { name: 'Сальдо, руб', value: '-' },
            ],
            general_stats: [
                { name: 'Расход общий', value: 0 },
                { name: 'Доход общий (усреднённо), руб', value: '-' },
                { name: 'Сальдо, руб', value: '-' },
            ],

            missed_calls_data: [
                {
                    count: 0
                }
            ]

        }
    },
    methods: {

        async changeFilter(filter) {

            this.loading.table = true
            this.$http.post("/statistics/all",
                {
                    filter: filter
                },
                (data) => {
                    console.log(data, 1)
                    this.calls_data = data.calls_statistics
                    this.summaryStatistics = [data.summaryStatistics]
                    this.sends_data = data.offers_send

                    // this.ChartData.datasets[0].data = [data.calls_statistics[0].calls_cost]
                    // console.log(data.calls_statistics[0].calls_cost)
                    const callsCostValues = this.calls_data.map(item => item.calls_cost);
                    const callsDateValues = this.calls_data.map(item => item.calls_date);
                    this.ChartData.labels = callsDateValues;
                    // обновление данных для графика
                    this.ChartData.datasets[0].data = callsCostValues;

                    const fullCost = this.calls_data.map(item => item.full_cost)
                    this.ChartData3.datasets[0].data = fullCost
                    this.ChartData3.labels = callsDateValues

                    this.general[0].value = data.summaryStatistics.total_calls_cost;
                    this.general[3].value = data.summaryStatistics.total_sends_amount


                    this.filter.singleoperator.options.splice(0, this.filter.singleoperator.options.length);
                    data.operators.forEach((item) => {
                        this.filter.singleoperator.options.push({ value: item.id, title: item.name });
                    });

                    this.pagination.total = this.sends_data.length

                    this.dataLoaded = false;
                })

            this.$http.post("/statistics/smsstats",
                {
                    filter: filter
                },
                (data) => {

                    this.sms_profit = data.sms_profit

                    // this.calls_data = data.calls_statistics
                    this.sends_data = data.offers_send
                    this.sms_data = data.sms_data
                    this.sms_count = data.sms_data.length
                    this.loading.table = false
                    this.pagination.total = this.sms_data.length
                    this.statsforsms = "Всего смс: " + data.sms_data.length + ", Всего конверсий: " + data.sms_profit.length + ", Доход по конверсиям: " + data.totalProfit;


                })

            this.$http.post("/statistics/foroperator",
                {
                    filter: filter
                },
                (data) => {

                    this.all_results_data = data.all_results_data
                    console.log( data.calls_results_data,'sdfghjidfghjkdfghjik')
                    this.calls_results_data = data.calls_results_data
                    this.ChartData2.datasets[0].data[0] = data.percentCalls[0].goodPercent
                    this.ChartData2.datasets[0].data[1] = data.percentCalls[0].robotPercent
                    this.ChartData2.datasets[0].data[2] = data.percentCalls[0].badPercent
                    this.ChartData2.datasets[0].data[4] = data.percentCalls[0].unsuitablePercent
                    this.ChartData2.datasets[0].data[3] = data.percentCalls[0].silencePercent
                    this.ChartData2.datasets[0].data[5] = data.percentCalls[0].pre_approvedPercent
                    this.ChartData2.datasets[0].data[6] = data.percentCalls[0].refusedPercent
                    this.ChartData2.datasets[0].data[7] = data.percentCalls[0].didnt_pick_upPercent
                    this.ChartData2.datasets[0].data[8] = data.percentCalls[0].droppedPercent
                    this.ChartData2.datasets[0].data[9] = data.percentCalls[0].send_smsPercent
                    this.ChartData2.datasets[0].data[10] = data.percentCalls[0].doublePercent
                    this.ChartData2.datasets[0].data[11] = data.percentCalls[0].send_kp_percent
                    this.loading.table = false
                })
            this.$http.post("/statistics/summarySms",
                {
                    filter: filter
                },
                (data) => {

                    this.general[1].value = data.DataSms.sum_stat[0].smsCost
                    this.general[2].value = data.DataSms.sum_stat[0].profit
                    this.general2[1].value = data.DataSms.sum_stat[0].profit
                    const dailyStats = data.DataSms.dailyStats.sort((a, b) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        return dateA - dateB;
                    });

                    const costValues = dailyStats.map(item => item.smsCost);
                    const income = dailyStats.map(item => item.totalProfit);
                    const date = dailyStats.map(item => item.date);
                    this.ChartSms.datasets[1].data = costValues;
                    this.ChartSms.datasets[0].data = income;
                    this.ChartSms.labels = date;

                    this.sms_sum_data = data.DataSms.sum_stat
                    this.sms_sum_data2 = data.DataSms.dailyStats
                    this.loading.table = false

                    const value1 = parseFloat(this.general[0].value); // Расход на телефонию, руб
                    const value2 = parseFloat(this.general[1].value); // Расход на СМС, руб

                    const sum = value1 + value2;

                    const roundedSum = sum.toFixed(2);

                    this.general2[0].value = roundedSum.toString(); // Расход, руб (general2)
                })

            this.$http.post("/statistics/insertBuffer",
                {
                    filter: filter
                },
                (data) => {
                    this.insertData = data.insertData.inserts

                })


            this.$http.post("/statistics/missedcalls",
                {
                    filter: filter
                },
                (data) => {
                    this.missed_calls_data = data

                })

            this.$http.post("/statistics/generalStats",
                {
                    filter: filter
                },
                (data) => {

                    this.mfo[0].value = data.general_stats[0].sms_count[0].count
                    this.mfo[1].value = data.general_stats[0].profit_count
                    this.mfo[2].value = data.general_stats[0].call_cost
                    this.mfo[3].value = data.general_stats[0].sms_cost
                    this.mfo[4].value = data.general_stats[0].profit
                    this.general_mfo[0].value = data.general_stats[0].total_cost_mfo
                    this.general_mfo[1].value = data.general_stats[0].profit
                    this.general_mfo[2].value = data.general_stats[0].saldo
                    this.bfl[0].value = data.general_stats[0].lids_bfl
                    this.bfl[1].value = data.general_stats[0].cost_call_bfl
                    this.bfl[2].value = data.general_stats[0].cost_lids_bfl
                    this.bfl[3].value = data.general_stats[0].saldo_bfl
                    this.zalogi[0].value = data.general_stats[0].lids_count_zalogi
                    this.zalogi[1].value = data.general_stats[0].call_cost_zalogi
                    this.zalogi[2].value = data.general_stats[0].cost_lids_zalogi
                    this.zalogi[3].value = data.general_stats[0].saldo_zalogi
                    this.bank[0].value = data.general_stats[0].cost_calls_bank
                    this.general_stats[0].value = data.general_stats[0].total_cost
                    this.general_stats[1].value = data.general_stats[0].total_income
                    this.general_stats[2].value = data.general_stats[0].total_saldo


                })



        },
        updateStatistics() {
            this.changeFilter(this.filter);
        },

    },
    mounted() {

        const today = dayjs();
        this.filter.rangeDate = [today, today];
        this.changeFilter(this.filter);
        this.filter.base.singleoperator = []
        this.filter.base.options = []
        this.filter.base.scenario = []
        this.filter.region.options = []
        this.$http.post("/scenarios/allstats",
            {
            },
            (data) => {
                this.filter.scenario.options = data
            })
        this.$http.post("/bases/regions",
            {
            },
            (data) => {
                data.forEach((item) => {
                    if (item !== 'Все регионы') {
                        this.filter.region.options.push({ value: item, title: item })
                    }
                })
            })
        this.$http.post("/bases/all",
            {
            },
            (data) => {
                data.bases.forEach((item) => {
                    this.filter.base.options.push({ value: item.id, title: item.name })
                })
            })
        this.$http.post("statistics/smsstats",
            {
                filter: this.filter
            },
            (data) => {

                this.sms_count = data.sms_data.length

                this.sms_data = data.sms_data.map(item => ({
                    phone: item.phone,
                    status: item.status,
                    alpha: item.alpha,
                    text: item.text,
                    operator: item.operator,
                    operator_id: item.operator_id,
                    name: item.name

                }));

                // const uniqueOperators = new Set();
                // this.sms_data.forEach(item => {
                //     uniqueOperators.add(item.operator_id);
                // });
                // this.filter.singleoperator.options = Array.from(uniqueOperators).map(operator_id => ({
                //     value: operator_id,
                //     title: this.sms_data.find(item => item.operator_id === operator_id).name
                // }));

            });

        this.$http.post("/statistics/allChanels",
            {},
            (data) => {
                this.filter.channels.options = data.map(item => ({
                    value: item.channel,
                    title: item.channel
                }));
            })

        this.$http.post("/statistics/offersAll",
            {},
            (data) => {

                this.filter.offers.options = data.map(item => ({
                    value: item.id,
                    title: item.name
                }));

            })



        setInterval(this.updateStatistics, 1000 * 120);

        this.$http.post("/statistics/datatypesStat",
            {

            },
            (data) => {

                this.filter.datatypes.options = data.map(item => ({
                    value: item.value,
                    title: item.title
                }))
            }
        )
    }

}
</script>

<style scoped>
.pieGrapth {
    margin: 0 auto;
    width: 400px;
}

.smsChart {
    width: 900px;
    margin: 0 auto
}

.mainChart {
    display: flex;
    width: 1800px;
}

.chartCalls {

    width: 800px;
}

.chartCalls2 {
    margin-left: 50px;
    width: 800px;
}
</style>