<template>

	<a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
		<a-checkbox :disabled="disabled" class="checkbox"
				:style="styleDisplay" @change="changInput"
				v-model:checked="valueInput">{{ optionsSelect }}
		</a-checkbox>
	</a-form-item>

</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
	name: "input-boolean",
	props: ['value', 'options', 'name', 'id', 'display', 'disabled'],
	mixins: [ChangInputMixins],
	data() {
		return {
			valueInput: false,
			optionsSelect: ''
		}
	},
	methods: {},
	mounted() {
		this.valueInput = Boolean(this.value)
		this.optionsSelect = this.options
	},
	watch: {
		options(val) {
			this.optionsSelect = val
		}
	}
}
</script>

<style scoped>
.checkbox {
    padding: 4px 11px 4px 13px;
    width: 100%;
    border-radius: 2px;
}
</style>