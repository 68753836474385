<template>
	<a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
		<a-input :disabled="disabled" @blur="changInput" v-model:value="valueInput" placeholder="Basic usage" :style="styleDisplay"/>
	</a-form-item>

</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
	name: "input-text",
	props: ['value', 'name', 'id', 'display', 'disabled'],
	mixins: [ ChangInputMixins] ,
	data() {
		return {
			valueInput: ''
		}
	},
	methods: {
		// changInput() {
		// 	this.$emit('changInput', this.valueInput)
		// }
	},
	mounted() {
		this.valueInput = this.value
	},

}
</script>

<style scoped>

</style>