<template>
    <a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
        <a-select
            @change="changInput"
            v-model:value="valueInput"
            mode="multiple"
            style="width: 100%"
            placeholder="Выберите регион"
            :max-tag-text-length="10"
            :options="options"
            :disabled="disabled"
            
            
        ></a-select>
    </a-form-item>
</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
    name: "input-tree-select",
    props: ['value', 'options', 'name', 'id', 'display', 'disabled'],
    mixins:[ ChangInputMixins ],
    data() {
        return {
            valueInput: [],
            optionsSelect: []
        }
    },
    methods: {
        // changInput() {
        // 	this.$emit('changInput', this.valueSelect)
        // }
    },
    mounted() {
        this.valueInput = this.value ? [...this.value] : []
        this.optionsSelect = [...this.options]
    },
    watch: {
        options(val) {
            this.optionsSelect = [...val]
        }
    }
}
</script>

<style scoped>
.ant-select-selection-item {
  max-width: 200px; /* Установите желаемую максимальную ширину */
  overflow: hidden;
  text-overflow: ellipsis; /* Обрезать текст, если он слишком длинный */
  white-space: nowrap; /* Предотвратить перенос текста */
}
</style>