<template>
	<a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
		<a-input-number
				v-model:value="valueInput"
				style="width: 100%"
				@blur="changInput"
				:disabled="disabled"
				:style="styleDisplay"
		/>
	</a-form-item>

</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
	name: "input-number",
	props: ['value', 'name', 'id', 'display', 'disabled'],
	mixins: [ ChangInputMixins] ,
	data() {
		return {
			valueInput: ''
		}
	},
	methods: {
		// changInput() {
		// 	this.$emit('changInput', this.valueInput)
		// }
	},
	mounted() {
		this.valueInput = parseInt(this.value)
	},
}
</script>

<style scoped>

</style>