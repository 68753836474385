<template class="min-height">
	<a-config-provider :locale="locale">
		<a-spin tip="Подключение к серверу..." :spinning="isLogin ? loadingConnect : false">
			<component :is="layout">
				<router-view />
			</component>
		</a-spin>
	</a-config-provider>


	<audio id="remoteAudio" controls class="audioSib">
		<p>Your browser doesn't support HTML5 audio.</p>
	</audio>
</template>


<script>
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import HeaderLayout from "@/layouts/HeaderLayout";
import { mapGetters, mapActions, mapState } from "vuex";
import RegistrationPage from "@/views/LoginPage";

import ruRU from 'ant-design-vue/es/locale/ru_RU';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

export default {
	name: 'App',
	components: {
		MainLayout,
		EmptyLayout,
		RegistrationPage,
		HeaderLayout
	},
	data() {
		return {
			locale: ruRU,
		}
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || 'main') + '-layout'
		},
		// ...mapGetters( 'user', ['user/isLogin']),
		...mapGetters({ isLogin: `user/isLogin`, loadingConnect: 'call/getLoadingConnects' }),
		...mapState({
			isLoginState: state => state.user.isLogin
		}),
	},
	methods: {
		...mapActions({
			loginToken: 'user/loginToken',
			logoutUser: 'user/logoutUser',
			testAction: 'user/testAction',
			connectCall: 'call/connectSipJs'
		}),
		redirect() {
			if (!this.isLoginState) {
				console.log('redirect')
				this.$router.push('/login')
			} else {
				// this.connectCall()
			}
		},
	},
	beforeMount() {
		if (localStorage.token) {
			this.loginToken(localStorage.token)
			this.testAction('yes token')
		} else {
			// this.$store.commit('user/logoutUser')
			this.logoutUser()
			this.testAction('no token')
		}
	},
	mounted() {
		this.redirect()
		this.$store.dispatch('offers/loadingOffers')
		this.$store.dispatch('questions/loadingQuestions')
	},
}
</script>

<style>
/*@import "@/assets/style.css";*/

/*#app {*/
/*    font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*    text-align: center;*/
/*    color: #2c3e50;*/
/*}*/

.ant-btn:hover,
.ant-btn:focus {
	color: #2b2d33 !important;
	/*border-color: #fff129;*/
	/*background: #fff;*/
}

.audioSib {
	opacity: 0;
	position: absolute;
	bottom: 0;
	right: 0;
	pointer-events: none;
}

.dop_color_text {
	color: rgba(0, 0, 0, 0.45);
}

.green_color_text {
	color: #52C41A;
}

.red_color_text {
	color: #FF4D4F;
}

.min-height {
	min-height: 100vh;
}

/*body {*/
/*    font-family: 'Roboto';*/
/*    */
/*}*/

body {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-variant: tabular-nums;
	line-height: 1.5715;
	/*background-color: #fff;*/
	font-feature-settings: 'tnum';
	font-style: normal;
	font-weight: 400;
	background-color: #F0F2F5 !important;
}

.bg_white_container {
	background: #ffffff;
	border-radius: 2px;
}

.fz16 {
	font-size: 16px;
}

.fz24 {
	font-size: 24px;
}

.fz12 {
	font-size: 12px;
}

.fixed {
	position: fixed;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
