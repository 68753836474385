import {http} from '@/axios/http'

let $http = new http();


export const OffersModule = {
	namespaced: true,

	state: () => ({
		offers: [],
		loading: false,
	}),
	getters: {
		getOffers(state){
			return state.offers
		},
		getLoading(state){
			return state.loading
		}
	},
	mutations: {
		setOffers(state, offers) {
			// console.log(offers,'    loadingOffers    ===============================')
			state.loading = true
			state.offers = offers
		}
	},
	actions: {
		async loadingOffers({commit}) {
			$http.post("/offers/all",
				{
					// можно прогружать все оффера или только активные
					//all: true,
					active_only: true
				},
				(data) => {
					// let offers = JSON.stringify(data)
					// localStorage.offers = offers
					if (data && data !== 'error') {
						commit("setOffers", data)
					}
				})
		},
	},
}