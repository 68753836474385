<template>
	<div>
		<a-modal :visible="visible" :title="title" @ok="handleOk" @cancel="handleOk" :width="widthPopup">
			<template #footer>
				<a-button :disabled="disableBtn" key="back" @click="handleOk">{{ btnCansel ? btnCansel : "Закрыть"
				}}</a-button>
				<a-button key="submit" type="primary" @click="callbackFun">{{ btnOk ? btnOk : 'Ok' }}</a-button>
				<!--				:loading="handleOk"-->
			</template>
			<slot></slot>
		</a-modal>
	</div>
</template>
<script>

export default {

	props: [
		'visible', 'title', 'widthPopup', 'btnOk', 'btnCansel', 'callback', 'disableBtn'
	],

	methods: {
		handleOk() {
			// console.log(e);
			// visible.value = false;
			this.$emit('closePopup')
		},
		callbackFun() {
			// console.log('callback popup')
			if (this.callback) {
				this.callback()
			} else {
				this.handleOk()
			}

		}
	}
};
</script>