<template>
  <TitlePage
    title="История"
    subtitle="Детализация совершенных звонков"
    :border="true"
  >
    <FiltersHeader
      :filter-value="filter"
      @changeFilter="changeFilter"
      :view-input-filter="{
        buttonShow: false,
        rangePicker: true,
        numberPhone: true,
        oneSelect: false,
        treeSelect: false,
        shorthand: true,
      }"
    />
    <FiltersHeader
      :filter-value="filter"
      @changeFilter="changeFilter"
      :view-input-filter="{
        singleoperator: true,
      }"
    />
  </TitlePage>

  <!--		============================ TABLE =========================-->
  <a-table
    :columns="columns"
    :data-source="data"
    :loading="loading.table"
    :pagination="pagination"
    size="small"
    @change="serverPagination"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'phone'">
        {{ phoneCustom(record.phone) }}
      </template>
      <template v-if="column.key === 'player'">
        <audio :src="record.player" controls></audio>
        <p>
          <a :href="record.player">Открыть аудио в новой вкладке </a>
        </p>
      </template>
      <template v-if="column.key === 'record_time'">
        {{ record.record_time }}
      </template>
      <template v-if="column.key === 'shorthand'">
        {{ record.shorthand }}
      </template>
      <template v-else-if="column.key === 'tags'">
        <span>
          <a-tag
            v-for="tag in record.tags"
            :key="tag"
            :color="
              tag === 'loser'
                ? 'volcano'
                : tag.length > 5
                ? 'geekblue'
                : 'green'
            "
          >
            {{ tag.toLowerCase() }}
          </a-tag>
        </span>
      </template>
      <template v-else-if="column.key === 'action'">
        <span>
          <a @click="informPhonePopup(record.phone)">Анкета</a>
        </span>
      </template>
    </template>
  </a-table>

  <BasicModal
    :title="`Вся информация по номеру ${this.inform_phone}`"
    :visible="popup.inform_lid"
    @closePopup="closePopup()"
    :btn-ok="'Выйти'"
    :widthPopup="700"
  >
    <a-spin :spinning="loading.informPhone">
      <a-tabs v-model:activeKey="activeKeyTabInformLid">
        <a-tab-pane key="1" tab="История">
          <TimeLine :value="numberHistory" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Поля" force-render>
          <a-form class="number_inform_form" layout="vertical">
            <component
              class="number_inform_form_item"
              v-for="field of resultFields"
              :key="field.key"
              :is="field.type.value"
              :options="field.options"
              :value="field.value"
              :name="field.name"
              :disabled="true"
            ></component>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="4" tab="Офферы">
          <a-row type="flex" :gutter="35">
            <a-col :flex="2">
              <div>
                <a-divider orientation="left">Отправленные оферы</a-divider>
                <OffersCards
                  :offers-arr="sendOffers"
                  :allSend="true"
                ></OffersCards>
              </div>
            </a-col>
            <a-col :flex="3">
              <div>
                <a-divider orientation="left">Действия</a-divider>
                <ActionPhoneList
                  :action-list="actionListPhone"
                  :showStats="true"
                />
              </div>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="5" tab="Записи">
          <a-table
            :columns="player.columns"
            :data-source="player.data"
            :pagination="pagination"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'date'">
                {{ record.date }}
              </template>
              <template v-if="column.key === 'player'">
                <audio :src="record.player" controls></audio>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </BasicModal>
</template>

<script>
import TitlePage from "@/components/TitlePage";
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import BasicModal from "@/components/modal/BasicModal";
import ActionPhoneList from "@/components/ui/ActionPhoneList";
import OffersCards from "@/components/ui/OffersCards";
import FiltersHeader from "@/components/ui/FiltersHeader";
import { mapGetters } from "vuex";
import TimeLine from "@/components/ui/TimeLine.vue";
import inputText from "@/components/field/input-text.vue";
import inputNumber from "@/components/field/input-number.vue";
import inputSelect from "@/components/field/input-select.vue";
import inputTreeSelect from "@/components/field/input-tree-select.vue";
import inputBoolean from "@/components/field/input-boolean.vue";
import inputDate from "@/components/field/input-date.vue";
import inputRangeDate from "@/components/field/input-range-date.vue";
import inputRangeNumber from "@/components/field/input-range-number.vue";

export default {
  name: "CallHistory",
  components: {
    TitlePage,
    BasicModal,
    FiltersHeader,
    TimeLine,
    OffersCards,
    ActionPhoneList,

    inputText,
    inputNumber,
    inputSelect,
    inputTreeSelect,
    inputBoolean,
    inputDate,
    inputRangeDate,
    inputRangeNumber,
  },
  mixins: [formattingPhoneMixin],
  data() {
    this.$http.post(
      "/calls/all",
      {
        limit: 100,
        offset: 0,
      },
      (data) => {
        console.log(data, 1291239);
        this.filter.singleoperator.options.splice(
          0,
          this.filter.singleoperator.options.length
        );
        data.opsData.forEach((item) => {
          this.filter.singleoperator.options.push({
            value: item.id,
            title: item.name,
          });
        });
        this.data = data.data;
        this.pagination.total = data.count;
        this.loading.table = false;
      }
    );
    this.$http.post("/offers/adminselect", {}, (data) => {
      this.filter.multiselect.options = data;
    });
    return {
      // numberPhone: '',
      activeKeyTabInformLid: "1",
      inform_phone: "",
      pagination: {
        current: 1,
        total: 500,
        pageSize: 100,
      },
      loading: {
        table: true,
        informPhone: false,
      },
      popup: {
        inform_lid: false,
      },
      numberHistory: [],
      player: {
        columns: [
          {
            title: "Дата",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Запись",
            dataIndex: "player",
            key: "player",
          },
        ],
        data: [
          // {
          // 	"key": 0,
          // 	"phone": "79522062389",
          // 	"date": "2023-01-24 19:01:57",
          // 	"player": "https://storage.yandexcloud.net/k8srecords/ney8o6g1xi.mp3",
          // }
        ],
      },
      sendOffers: [
        // TODO брать из баз
        {
          title: "CreditPlus",
          subtitle: "Заявка",
          status: "success",
          Dropdown: [],
        },
        {
          title: "QQQQQ",
          subtitle: "Заявка",
          status: "success",
          Dropdown: [],
        },
        {
          title: "MMM",
          subtitle: "Заявка",
          status: "success",
          Dropdown: [],
        },
      ],
      historyListPhone: [],
      resultFields: [],
      actionListPhone: [
        {
          value: "Отправка на схему",
          day: 20,
        },
        {
          value: "Отправка на схему",
        },
        {
          value: "Перезвон 12-12-2022",
        },
      ],

      filter: {
        rangeDate: [],
        text: "",
        singleoperator: {
          value: [],
          options: [{ value: "", title: "" }],
        },
        // select: {
        // 	value: '',
        // 	options: [
        // 		{value: '1'},
        // 		{value: '2'},
        // 		{value: '3'},
        // 		{value: '4'},
        // 	]
        // },
        multiselect: {
          value: [],
          options: [
            { value: "1", title: "112" },
            { value: "w", title: "2" },
            { value: "3", title: "3" },
            { value: "4", title: "4" },
            { value: "5", title: "5" },
          ],
        },
      },
      columns: [
        {
          title: "Номер",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Дата",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Запись",
          dataIndex: "player",
          key: "player",
        },
        {
          title: "Время звонка",
          dataIndex: "record_time",
          key: "record_time",
        },
        {
          title: "Расшифровка звонка",
          dataIndex: "shorthand",
          key: "shorthand",
        },
        // {
        // 	title: 'Отправленны оферы',
        // 	key: 'tags',
        // 	dataIndex: 'tags',
        // },
        {
          title: "Информация по номеру",
          key: "action",
        },
      ],
      data: [],

      indexVisibleData: 0,
      visibleDataHistoryPopup: {},
      visiblePopupHistory: false,
    };
  },
  computed: {
    ...mapGetters({
      // dataOffers: 'offers/getOffers',
      dataFields: "questions/getQuestions",
    }),
  },
  methods: {
    serverPagination(pagination) {
      //console.log(pagination.current)
      //console.log(pagination.pageSize)
      this.loading.table = true;
      this.$http.post(
        "/calls/all",
        {
          limit: pagination.pageSize,
          offset: (pagination.current - 1) * pagination.pageSize,
        },
        (data) => {
          this.data = data.data;
          this.pagination.total = data.count;
          this.pagination.pageSize = pagination.pageSize;
          this.loading.table = false;
        }
      );
    },
    // visiblePopupHistoryPhone(phone, date) {
    // 	// тут запрос по номеру и запись в дату
    // 	// добавил дату звонка чтоб уникальный результат получать
    //   console.log(date)
    // 	this.$http.post("/calls/actions",
    // 		{
    // 			phoneB: phone,
    // 		},
    // 		(data) => {
    // 			// this.actionListPhone = data.data[0].data_json
    //       this.historyListPhone = data.history
    // 			// this.sendOffers = data.data[0].sendOffers
    //
    // 		})
    //
    // 	this.visibleDataHistoryPopup.phone = phone
    // 	this.visiblePopupHistory = true
    //
    // },
    closePopup() {
      // this.visiblePopupHistory = false
      this.numberHistory = [];
      this.resultFields = [];
      this.sendOffers = [];
      this.player.data = [];

      this.popup.inform_lid = false;
    },
    changeFilter(filter) {
      if (!this.pagination.current) {
        this.pagination.current = 1;
      }
      this.loading.table = true;
      this.filter = filter;

      this.$http.post(
        "/calls/all",
        {
          limit: this.pagination.pageSize,
          offset: (this.pagination.current - 1) * this.pagination.pageSize,
          filter: filter,
        },
        (data) => {
          console.log(data, 17);
          this.data = data.data;
          this.pagination.total = data.count;
          this.loading.table = false;
        }
      );
    },
    informPhonePopup(phone) {
      this.inform_phone = phone;
      this.loading.informPhone = true;
      // тут получаем все нужные данные по лиду

      this.$http.post(
        "/clients/anketa",
        {
          number: this.inform_phone,
        },
        (anketa) => {
          this.phoneFields = anketa;
          this.renderFields();
        }
      );
      this.$http.post(
        "/clients/history",
        {
          number: this.inform_phone,
        },
        (data) => {
          console.log(12312312312313123);
          this.numberHistory = data;
          this.loading.informPhone = false;
          console.log(this.numberHistory);
        }
      );
      this.$http.post(
        "/bases/clientdata",
        {
          number: this.inform_phone,
        },
        (data) => {
          this.player.data = data.records;
          this.sendOffers = data.sendOffers;
          this.actionListPhone = data.actions;
        }
      );

      //this.numberHistory = []
      this.popup.inform_lid = true;
    },
    renderFields() {
      let fields = [];
      this.dataFields.forEach((field) => {
        let value = this.phoneFields[field.key];
        if (value) {
          fields.push({
            key: field.key,
            value: value,
            name: field.name,
            type: field.type,
            options: field.options,
          });
        }
      });
      this.resultFields = fields;
    },
  },
};
</script>

<style scoped>
.results_call {
  background: #ffffff;
  border-radius: 2px;
  margin-top: 15px;
  padding: 15px;
}

.results_call_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  margin-bottom: 35px;
}

.end_call_outgoing {
  margin: 50px 60px;
}

.tab_card {
  border: 1px solid #f0f0f0;
  padding: 15px 20px;
  width: calc(100% - 15px);
  /*width: 100%;*/
  display: flex;
  gap: 30px;
  align-items: end;
  margin-bottom: 5px;
}

.tab_card_title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}

.tab_card_subtitle {
  font-size: 14px;
  color: rgba(70, 70, 70, 0.85);
}
</style>
