<template>
    <div>
        <canvas id="myChart3" width="600" height="300"></canvas>
    </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue';
import Chart from 'chart.js/auto';

export default {
    props: {
        chartData: {
            type: Array,
            required: true,

        },
    },
    setup(props) {
        let chart = reactive({});

        const chartData = computed(() => {
            return props.chartData;
        });

        onMounted(() => {
            const ctx = document.getElementById('myChart3');
            chart = new Chart(ctx, {
                type: 'bar',
                data: chartData.value,
                options: {
                    scales: { x: { display: false } },
                    elements: {

                        line: {
                            borderColor: 'lime',
                            borderWidth: 2
                        }
                    }
                }
            });

            setInterval(() => {
                chart.update()
            }, 1000 * 2);
        });

        // console.log(props.chartData.datasets.data, ',kf=======================')
        // watch(() => props.chartData.datasets.data, () => {
        //     // chart.value.update();
        //     console.log('Блаблабла')
        // });


        return {
            chart,
        };
    },
};
</script>
