import { SimpleUser } from "/node_modules/sip.js/lib/platform/web/index.js";
import { notification } from "ant-design-vue";
import { http } from '@/axios/http'
import router from "@/router/router";

let $http = new http();

export const SipJsModule = {
	namespaced: true,

	state: () => ({
		SimpleUser: null,
		myWs: null,
		callStarted: false,
		incomingPhone: [],
		incomingScenario: '',
		//call_id:'0',
		loading: {
			SimpleUser: false,
			myWs: false
		},
		dataCall: {
			record: '',
			time: '',
		},
		ping: 0,
		awaiting: 1,
		waiting_timer: 0,
		online: 0
	}),
	getters: {
		getIncomingPhone(state) {
			return state.incomingPhone.slice(0, 1)
		},
		getLengthIncomingPhone(state) {
			return state.incomingPhone.length
		},
		getLoadingConnects(store) {
			return !store.loading.SimpleUser || !store.loading.myWs
		},
		getDataCall(state) {
			return state.dataCall
		},
		getStatusCall(state) {
			return state.callStarted
		},
	},
	mutations: {
		setSimpleUser(state, SimpleUser) {
			state.SimpleUser = SimpleUser
		},
		connectSocket(state, WebSocket) {
			state.myWs = WebSocket
		},
		setCallStarted(state, val) {
			console.log("Val on setCallStarted:")
			console.log(val)
			state.callStarted = val
		},
		setComingPhone(state, param) {
			// TODO тут в будущем настроить поиск номера и удаление его с массива при статусе фолс
			if (param.status_phone) {
				const audio = new Audio('https://storage.yandexcloud.net/ivrrecord/uvedomlenie11.mp3')
				if (state.incomingPhone.length === 0 && state.callStarted === false) {
					audio.play()
				}
				state.incomingPhone.push(param.phone)
				state.incomingScenario = param.scenario
				console.log(param, 99090)
				console.log("!!!!!!!scenario: " + state.incomingScenario)
			} else {
				state.incomingPhone = state.incomingPhone.filter((number) => number !== param.phone)
			}
		},
		loadingAdmin(state) {
			console.log(' Пропускаем загрузку ===============================================')
			setTimeout(() => {
				state.loading.myWs = true
				state.loading.SimpleUser = true
			}, 10)
		}
	},
	actions: {
		async startCall({ commit, state, rootGetters }, phone) {
			commit("setCallStarted", true)
			//state.call_id = Date.now()
			if (!state.SimpleUser) {
				alert('Что-то пошло не так')
			}
			else {
				console.log('Call_start on: sip:' + phone + '-' + rootGetters['user/getIdUser'] + '@127.0.0.1')
				let call_start = await state.SimpleUser.call('sip:' + phone + '-' + rootGetters['user/getIdUser'] + '@127.0.0.1');
				//let call_start = await state.SimpleUser.call('sip:' + phone + '-' + rootGetters['user/getIdUser'] + '-' + state.call_id + '@127.0.0.1');
				console.log("Result of state.SimpleUser.call:")
				console.log(call_start)
			}

		},
		async stopCall({ commit, state }) {
			if (state.callStarted) {
				// const audio = new Audio('https://storage.yandexcloud.net/ivrrecord/prj_20938623_e7ef20e311fc800ba4fc542a350a1169_1684497683.mp3')
				// audio.play()
				await state.SimpleUser.hangup();
				commit("setCallStarted", false)
			}
		},
		closeConnect({ state }) {
			// TODO тут нужно сделать нормаольный дисконект
			if (state.myWs && state.SimpleUser) {
				state.myWs.close();
				state.myWs = null
				state.SimpleUser = null;
			}
		},

		async socketConnect({ commit, dispatch, state, rootGetters }) {
			// const myWs = new WebSocket('ws://158.160.32.159:1815/operator-' + rootGetters['user/getIdUser']);
			//const myWs = new WebSocket('wss://ws.finbro.tech/operator-' + rootGetters['user/getIdUser']);
			const myWs = new WebSocket('wss://asteroid.finbro.tech/wss/operator-' + rootGetters['user/getIdUser']);
			commit('connectSocket', myWs)
			myWs.onopen = function () {
				console.log("connect")
				dispatch('socketGetOnline')
				state.loading.myWs = true

				setInterval(() => {
					dispatch('socketGetOnline')
				}, 1000 * 1);
			};

			let time_start = 0
			let time_stop = 0
			setTimeout(() => {
				state.myWs.send(JSON.stringify({ action: 'ping', data: '' }));
			}, 1000 * 3);

			myWs.onmessage = function (message) {
				let data = JSON.parse(message.data)
				if (data.action === 'status') {
					// dispatch('user/changeOnline', data.data === 'online', { root: true })
				}
				if (data.action === 'draw_call') {
					console.log("????????????!! DATA:")
					console.log(data)
					commit('setComingPhone', { phone: data.data, scenario: data.fields.scenario, status_phone: true })
					if (state.online === 1) {
						router.push(`/call/${data.data}`)
					}
				}
				if (data.action === 'clear_call') {
					commit('setComingPhone', { phone: data.data, status_phone: false })
				}
				if (data.action === 'end_call') {
					notification.open({
						message: 'Звонок завершён',
						description: '',
						duration: 1,
						placement: 'bottomLeft'
					});
					state.callStarted = false
					state.dataCall.record = data.record
					state.dataCall.time = data.time
					commit("setCallStarted", false)
				}
				if (data.action === 'pong') {
					time_stop = Date.now()
					if (time_start > 0 && time_stop > 0) {
						state.ping = time_stop - time_start
					}
					if (state.ping > 5000) {
						notification.open({
							message: 'Плохое соединение с сервером',
							description: 'Пинг - ' + state.ping + ' мс',
							duration: 5,
							placement: 'bottomLeft'
						});
					}
					state.myWs.send(JSON.stringify({ action: 'ping', data: '' }));
					time_start = Date.now()
				}
			};

			myWs.onclose = function (e) {
				notification.open({
					message: 'Сокет закрылся',
					description: '',
					duration: 3,
					placement: 'bottomLeft'
				});
				console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
				location.reload()
				// setTimeout(function() {
				// 	dispatch('socketConnect')
				// }, 1000);
			};
			myWs.onerror = function (err) {
				console.error('Socket encountered error: ', err.message, 'Closing socket');
				myWs.close();
			};
		},

		socketGetOnline({ state, rootGetters }) {
			if (rootGetters['user/getReceiveCallsUser'] && state.awaiting === 1) {
				console.log(' you online ++++++++++++++')
				state.myWs.send(JSON.stringify({ action: 'status', data: 'online', service: rootGetters['user/getServiceUser'] }));
				state.online = 1
				if (state.waiting_timer === 0) {
					state.waiting_timer = Date.now()
				}
			} else {
				console.log(' you offline --------------')
				state.myWs.send(JSON.stringify({ action: 'status', data: 'offline', service: rootGetters['user/getServiceUser'] }));
				state.online = 0
				if (state.waiting_timer > 0) {
					let real_waiting = (Date.now() - state.waiting_timer) / 1000
					state.waiting_timer = 0
					try {
						$http.post("/operators/waitingtimeupdate",
							{
								waiting_time: real_waiting
							},
							() => {
							})
					}
					catch (err) {
						console.error(err);
					}
				}
			}
		},
		async connectSipJs({ commit, state, dispatch }) {
			function getAudioElement(id) {
				const el = document.getElementById(id);
				if (!(el instanceof HTMLAudioElement)) {
					throw new Error(`Element "${id}" not found or not an audio element.`);
				}
				return el;
			}

			dispatch('socketConnect')

			// Helper function to wait
			async function wait(ms) {
				return new Promise((resolve) => {
					setTimeout(resolve, ms);
				});
			}

			// Main function Stun
			async function main() {

				// const server = "ws://localhost:8088/ws";
				//const server = "wss://wrtc.finbro.tech/";
				const server = "wss://asteroid.finbro.tech/ws/";

				// const aor = "sip:1060@127.0.0.1";
				const aor = "sip:callcenter@158.160.42.156";
				const authorizationUsername = 'callcenter';
				const authorizationPassword = 'tZcufzKBTTQ';

				const options = {
					aor,
					media: {
						remote: {
							audio: getAudioElement("remoteAudio")
						}
					},
					userAgentOptions: {
						sessionDescriptionHandlerFactoryOptions: {
							iceGatheringTimeout: 1000, // the smallest allowed value - 500
							peerConnectionConfiguration: {
								bundlePolicy: "balanced",
								certificates: undefined,
								iceCandidatePoolSize: 0,
								iceServers: [{ urls: "stun:stun.finbro.tech:3478" }],
								iceTransportPolicy: "all",
								rtcpMuxPolicy: "require"
							}
						},
						authorizationPassword,
						authorizationUsername,
					}
				};

				// Construct a SimpleUser instance
				let simpleUserNew = new SimpleUser(server, options);

				// Supply delegate to handle inbound calls (optional)
				simpleUserNew.delegate = {
					onCallReceived: async () => {
						await simpleUserNew.answer();
					}
				};

				// Connect to server
				await simpleUserNew.connect();

				// Register to receive inbound calls (optional)
				await simpleUserNew.register();

				// Wait some number of milliseconds
				await wait(5000);
				// simpleUser = simpleUserNew
				// console.log(simpleUserNew, '            === simpleUserNew  ===   simpleUserNew   ===   simpleUserNew ===')

				commit("setSimpleUser", simpleUserNew)

			}

			// Run it
			main()
				.then(() => {
					console.log(`Success`)
					state.loading.SimpleUser = true
				})
				.catch((error) => console.error(`Failure`, error));
		},
	},
}