<template>
    <a-form-item :label="name" has-feedback :validate-status="display ? display : ''">
        <a-auto-complete
            @change="changInput"
            v-model:value="valueInput"
            
            style="width: 100%"
            placeholder="Выберите имя"
            :max-tag-text-length="25"
            :allow-clear="true"
            :options="options"
            :disabled="disabled"
            :filter-option="customFilter"
        >
    </a-auto-complete>
    </a-form-item>
</template>

<script>
import ChangInputMixins from "@/mixins/changInputMixins";

export default {
    name: "input-tree-select",
    props: ['value', 'options', 'name', 'id', 'display', 'disabled'],
    mixins:[ ChangInputMixins ],
    data() {
        return {
            valueInput: [],
            optionsSelect: []
        }
    },
    methods: {
        customFilter(input, option) {
      // Фильтрация по первым символам
      const inputValue = input.toLowerCase();
      const optionValue = option.value.toLowerCase();
      return optionValue.startsWith(inputValue);
    },
        // changInput() {
        // 	this.$emit('changInput', this.valueSelect)
        // }
    },
    mounted() {
        this.valueInput = this.value ? [...this.value] : []
        this.optionsSelect = [...this.options]
    },
    watch: {
        options(val) {
            this.optionsSelect = [...val]
        }
    }
}
</script>

<style scoped>

</style>