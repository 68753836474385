<template>
	<div>
<!--		<template v-for="section of filter" :key="section">-->
			<a-divider orientation="left">{{ titleDivider }}</a-divider>
			<div class="flow_options__command_checkboxes">
				<a-checkbox-group
						:style="{ display: 'flex', flexDirection: 'column', gap: '10px'}"
						v-model:value="checkedListLocal"
						:options="plainOptions"
						@change="changeCheckbox()"
				/>
			</div>
<!--		</template>-->
	</div>

	<!--	не использовать в разработке-->
</template>

<script>
export default {

	name: "CheckboxGroup",
	props: [
		'titleDivider', 'plainOptions', 'checkedList'
	],
	data() {
		return {
			// plainOptions: ['Apple', 'Pear', 'Orange'],
			checkedListLocal: [],
		}
	},
	watch: {},
	methods: {
		changeCheckbox(){
			// console.log(this.checkedListLocal)
			this.$emit('checkedArr', this.checkedListLocal, this.titleDivider)
		},
	},
	mounted() {
		if (this.checkedList) {
			this.checkedListLocal = this.checkedList
		}
	}
}
</script>

<style scoped>
.flow_options__command_checkboxes {
    display: flex;
    flex-direction: column;
}
</style>