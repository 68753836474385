import { createStore } from "vuex"

import { UserModule } from "@/store/UserModule";
import { OffersModule } from "@/store/OffersModule";
import { QuestionsModule } from "@/store/QuestionsModule";
import { SipJsModule } from "@/store/SipJsModule";

export default createStore({

	modules: {
		user: UserModule,
		offers: OffersModule,
		questions: QuestionsModule,
		call: SipJsModule,
	}

})

