<template>
    <div id="setting_tab" class="card-container">
        <a-tabs type="card">
            <a-tab-pane key="1" tab="Общий мониторинг">
                <a target="_blank"
                    href="https://monitoring.cloud.yandex.ru/folders/b1grp3u93181d11hrrf6/dashboards/fbej9ftp5g9cvf130haa?from=now-30m&to=now">
                    <b>Переход на страницу Яндекс.Мониторинг <br>
                        https://monitoring.cloud.yandex.ru/folders/b1grp3u93181d11hrrf6/dashboards/fbej9ftp5g9cvf130haa?from=now-30m&to=now
                    </b>
                </a>
            </a-tab-pane>
        </a-tabs>
        <div class="graph">

            <a-radio-group v-model="selectedTimeFrame">
                <a-button @click="setTimeFrame2('30m')">30м</a-button>
                <a-button @click="setTimeFrame2('1h')">1ч</a-button>
                <a-button @click="setTimeFrame2('2h')">2ч</a-button>
                <a-button @click="setTimeFrame2('24h')">24ч</a-button>
                <!-- Добавьте другие интервалы времени, если необходимо -->
            </a-radio-group>
            <div class="mainChart2">
                <div class="third">
                    <chart5 :chartData="ChartData3" />
                </div>
                <div class="fourth">
                    <chart6 :chartData="ChartData4" />
                </div>
            </div>
            <div class="mainChart3">
                <div class="five">
                    <chart7 :chartData="ChartData5" />
                </div>
                <div class="six">
                    <chart8 :chartData="ChartData6" />
                </div>
            </div>
        </div>
        <a-radio-group v-model="selectedTimeFrame">
            <a-button @click="setTimeFrame('today')">Сегодня</a-button>
            <a-button @click="setTimeFrame('yesterday')">Вчера</a-button>
            <a-button @click="setTimeFrame('last3days')">3 дня</a-button>
            <a-button @click="setTimeFrame('7days')">7 дней</a-button>
            <a-button @click="setTimeFrame('30days')">30 дней</a-button>
        </a-radio-group>
        <div class="sms">
            <div class="mainChart">
                <div class="first">
                    <chart1 :chartData="ChartData" />
                </div>
                <div class="sec">
                    <chart2 :chartData="ChartData2" />
                </div>
            </div>



        </div>
    </div>
</template>

<script>
import chart1 from "@/components/ui/charts/ChartYandex1";
import chart2 from "@/components/ui/charts/ChartYandex2";
import chart5 from "@/components/ui/charts/ChartYandex4";
import chart6 from "@/components/ui/charts/ChartYandex5";
import chart7 from "@/components/ui/charts/ChartYandex6";
import chart8 from "@/components/ui/charts/ChartYandex7";
export default {
    name: "MonitoringVue",
    components: {
        chart1,
        chart2,
        chart5,
        chart6,
        chart7,
        chart8
    },
    data() {
        return {
            calls_data: [],
            selectedTimeFrame: 'today',
            selectedTimeFrame2: '30m',
            chartDataLoaded: false,
            formattedDate: 'today',
            ChartData: {

                datasets: [
                    {
                        label: 'Расходы на смс',
                        data: [],
                        borderWidth: 1,
                        fill: true
                    },
                ],

            },
            ChartData2: {

                datasets: [
                    {
                        label: 'Расходы на телефонию',
                        data: [],
                        borderWidth: 1,
                        fill: true,
                    },
                ],

            },
            ChartData3: {
                labels: [],
                datasets: [
                    {
                        label: 'Скорость звонков',
                        data: [],
                        borderWidth: 1,
                        fill: true
                    },
                ],

            },
            ChartData4: {
                labels: [],
                datasets: [
                    {
                        label: 'Конверсия робота',
                        data: [],
                        borderWidth: 1,
                        fill: true
                    },
                ],

            },
            ChartData5: {
                labels: [],
                datasets: [
                    {
                        label: 'Среднее время ожидания звонка оператором',
                        data: [],
                        borderWidth: 1,
                        fill: true
                    },
                ],

            },
            ChartData6: {
                labels: [],
                datasets: [
                    {
                        label: 'Свободных операторов',
                        data: [],
                        borderWidth: 1,
                        fill: true
                    },
                ],

            },


            today: null,
        };
    },
    methods: {
        monitoring() {

            this.$http.post("/chart/monitoring",
                {
                    timeFrame: this.selectedTimeFrame2
                }, (data) => {

                    const call_data = data.monitor[0].call_speed;
                    call_data.sort((a, b) => new Date(a.date_create) - new Date(b.date_create));
                    const call_speed_title = call_data.map(item => item.date_create);
                    const call_speed_value = call_data.map(item => item.value)
                    this.ChartData3.labels = call_speed_title
                    this.ChartData3.datasets[0].data = call_speed_value

                    const conver_data = data.monitor[0].conversion_actual;
                    conver_data.sort((a, b) => new Date(a.date_create) - new Date(b.date_create));
                    const conversion_actual_title = conver_data.map(item => item.date_create)
                    const conversion_actual_value = conver_data.map(item => item.value)
                    this.ChartData4.labels = conversion_actual_title
                    this.ChartData4.datasets[0].data = conversion_actual_value

                    const waiting_data = data.monitor[0].waiting_time;
                    waiting_data.sort((a, b) => new Date(a.date_create) - new Date(b.date_create));
                    const waiting_time_titie = waiting_data.map(item => item.date_create)
                    const waiting_time_value = waiting_data.map(item => item.value)
                    this.ChartData5.labels = waiting_time_titie
                    this.ChartData5.datasets[0].data = waiting_time_value

                    const free_ops_data = data.monitor[0].free_ops
                    free_ops_data.sort((a, b) => new Date(a.date_create) - new Date(b.date_create));
                    const free_ops_title = free_ops_data.map(item => item.date_create)
                    const free_ops_value = free_ops_data.map(item => item.value)
                    this.ChartData6.labels = free_ops_title
                    this.ChartData6.datasets[0].data = free_ops_value


                })
        },

        setTimeFrame(timeFrame) {
            this.selectedTimeFrame = timeFrame;
            this.forChart();

        },
        setTimeFrame2(timeFrame) {
            this.selectedTimeFrame2 = timeFrame;

            this.monitoring();
        },

        forChart() {
            let currentDate = new Date()
            let secondDate = new Date()

            if (this.selectedTimeFrame === 'yesterday') {
                currentDate.setDate(currentDate.getDate() - 1);
                secondDate = currentDate
            } else if (this.selectedTimeFrame === 'last3days') {
                currentDate.setDate(currentDate.getDate() - 3);
                secondDate = new Date()

            } else if (this.selectedTimeFrame === '7days') {
                currentDate.setDate(currentDate.getDate() - 7);
                secondDate = new Date()
            } else if (this.selectedTimeFrame === '30days') {
                currentDate.setDate(currentDate.getDate() - 30);
                secondDate = new Date()
            }

            let formattedDate = secondDate.toISOString();
            let firstDate = currentDate.toISOString();


            this.$http.post("/chart/chartStats", {
                filter: {
                    rangeDate: [firstDate, formattedDate]
                }
            }, (data) => {
                const smsLabels = data.data_chart[0].sms_Cost;
                const hoursOfDay = smsLabels.map(item => item.hour_of_day);
                const smsCount = smsLabels.map(item => item.cost) //стоимость смс
                this.ChartData.datasets[0].data = smsCount
                this.ChartData.labels = hoursOfDay;
                const call_cost_label = data.data_chart[0].call_cost;
                const calls_labels = call_cost_label.map(item => item.hour_of_day) //лэйблы для звонков
                const calls_cost = call_cost_label.map(item => item.sum) // стоимость звонков
                this.ChartData2.datasets[0].data = calls_cost
                this.ChartData2.labels = calls_labels
            });

        },


    },

    mounted() {


        this.monitoring()

        this.forChart();
        setInterval(() => {
            this.forChart();
            this.monitoring();
        }, 1000 * 2);
        this.selectedTimeFrame = 'today';
    }
}
</script>

<style scoped>
.graph {

    max-width: 2000px;
}

.sms {

    width: 800px;

}

.mainChart {
    display: flex;
    width: 1800px;
}

.first {
    width: 800px;
    height: 500px;
}

.sec {
    width: 800px;
    height: 500px;
}

.mainChart2 {
    display: flex;
    width: 1800px;
}

.third {
    max-width: 800px;
    height: 500px;
}

.fourth {
    width: 800px;
    height: 500px;
}

.mainChart3 {
    display: flex;
    width: 400px;
}

.five {
    width: 800px;
    height: 500px;
}

.six {
    width: 800px;
    height: 500px;
}
</style>
