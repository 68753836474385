<template>
	<a-row :gutter="16" style="margin-top: 20px;">
		<a-col v-if="viewInputFilter.rangePicker" :span="spanColumn">
			<a-range-picker v-model:value="filter.rangeDate" style="width: 100%" @change="changeFilter" />
		</a-col>
		<a-col v-if="viewInputFilter.numberPhone" :span="spanColumn">
			<a-input v-model:value="filter.text" style="width: 100%" @change="changeFilter"
				placeholder="Фильтр по номеру"></a-input>
		</a-col>
		<a-col v-if="viewInputFilter.shorthand" :span="spanColumn">
			<a-input v-model:value="filter.shorthand" style="width: 100%" @change="changeFilter"
				placeholder="Фильтр по расшифровке"></a-input>
		</a-col>
		<a-col v-if="viewInputFilter.oneSelect" :span="spanColumn">
			<a-select v-model:value="filter.select.value" :options="filter.select.options" style="width: 100%"
				@change="changeFilter"></a-select>
		</a-col>
		<a-col v-if="viewInputFilter.treeSelect" :span="spanColumn">
			<a-tree-select v-model:value="filter.multiselect.value" style="width: 100%"
				:tree-data="filter.multiselect.options" tree-checkable allow-clear @change="changeFilter"
				placeholder="Фильтр по офферам" />
		</a-col>
		<a-col v-if="viewInputFilter.offers" :span="spanColumn">
			<a-tree-select v-model:value="filter.offers.value" style="width: 100%" :tree-data="filter.offers.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по офферам"
				:filterTreeNode="filterForOffer" />
		</a-col>
		<a-col v-if="viewInputFilter.category" :span="spanColumn">
			<a-tree-select v-model:value="filter.category.value" style="width: 100%" :tree-data="filter.category.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по категориям" />
		</a-col>
		<a-col v-if="viewInputFilter.scenario" :span="spanColumn">
			<a-tree-select v-model:value="filter.scenario.value" style="width: 100%" :tree-data="filter.scenario.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по сценариям" />
		</a-col>
		<a-col v-if="viewInputFilter.operators" :span="spanColumn">
			<a-tree-select v-model:value="filter.operators.value" style="width: 100%" :tree-data="filter.operators.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по старшим операторам" />
		</a-col>
		<a-col v-if="viewInputFilter.channels" :span="spanColumn">
			<a-tree-select v-model:value="filter.channels.value" style="width: 100%" :tree-data="filter.channels.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по каналам" />
		</a-col>
		<a-col v-if="viewInputFilter.datatypes" :span="spanColumn">
			<a-tree-select v-model:value="filter.datatypes.value" style="width: 100%" :tree-data="filter.datatypes.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по категориям" />
		</a-col>
		<a-col v-if="viewInputFilter.base" :span="spanColumn">
			<a-tree-select v-model:value="filter.base.value" style="width: 100%" :tree-data="filter.base.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по базам" />
		</a-col>
		<a-col v-if="viewInputFilter.region" :span="spanColumn">
			<a-tree-select v-model:value="filter.region.value" style="width: 100%" :tree-data="filter.region.options"
				tree-checkable allow-clear @change="changeFilter" placeholder="Фильтр по регионам" />
		</a-col>
		<a-col v-if="viewInputFilter.singleoperator" :span="spanColumn">
			<a-tree-select v-model:value="filter.singleoperator.value" style="width: 100%"
				:tree-data="filter.singleoperator.options" tree-checkable allow-clear @change="changeFilter"
				placeholder="Фильтр по оператору" />
		</a-col>
		<a-col v-if="viewInputFilter.sub" :span="spanColumn">
			<a-input v-model:value="filter.sub" style="width: 100%" @change="changeFilter"
				placeholder="Фильтр по Sub"></a-input>
		</a-col>
		<a-col v-if="viewInputFilter.minMax" :span="spanColumn">
			<a-input-group compact>
				<a-input style="width: calc(100% - 230px); border-right: 0; pointer-events: none; background-color: #fff"
					placeholder="Количество звонков" disabled />
				<a-input-number v-model:value="filter.rangeNum.min"
					style="width: 100px; text-align: center; border-left: 0; border-right: 0;" placeholder="Минимум"
					@change="changeFilter" :min="0" />
				<a-input style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff" placeholder="~"
					disabled />
				<a-input-number v-model:value="filter.rangeNum.max" style="width: 100px; text-align: center; border-left: 0"
					placeholder="Максимум" @change="changeFilter" :min="0" />
			</a-input-group>
		</a-col>
		<a-col v-if="viewInputFilter.buttonShow" :span="3">
			<a-button block @click="btnFilter">Применить</a-button>
		</a-col>
	</a-row>
	<!--	<a-button style="margin-left: 20px" v-if="viewInputFilter.buttonShow">Применить</a-button>-->
	<!--	{{ filter }}-->
</template>

<script>

// viewInputFilter: {
// buttonShow: false,
// 	rangePicker: false,
// 	numberPhone: false,
// 	oneSelect: false,
// 	treeSelect: false,
// }
export default {
	name: "FiltersHeader",
	props: ['filterValue', 'viewInputFilter'],
	data() {
		return {
			filter: {
				rangeDate: '',
				text: '',
				shorthand: '',
				select: {},
				scenario: {},
				region: {},
				datatypes: {},
				multiselect: {},
				category: {},
				operators: {},
				singleoperator: {},
				base: {},
				channels: {},
				offers: {},
				rangeNum: {
					min: null,
					max: null
				},
				sub: ''
			}
		}
	},
	computed: {
		spanColumn() {
			if (this.viewInputFilter.buttonShow) {
				return 7
			} else {
				return 8
			}
		}
	},
	methods: {
		//поиск по вервым буквам в фильтре по офферам
		filterForOffer(input, option) {
			return option.title.toLowerCase().startsWith(input.toLowerCase());
		},
		btnFilter() {
			this.$emit('clickButtonFilter')
		},
		changeFilter() {

			this.$emit('changeFilter', this.filter)
		}
	},
	watch: {
		filterValue(val) {
			this.filter = val
		},
	},
	mounted() {
		this.filter = this.filterValue
	}
}
</script>

<style scoped></style>