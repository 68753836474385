<template>
	<a-layout>
		<a-layout-header class="header" :style="{ position: 'fixed', zIndex: 10, width: '100%' }">
			<div class="logo">ФинБро.Коллцентр</div>


			<div class="header_left">
				<div v-if="role === 'operator'" :style="{ color: ping >= 1000 ? 'red' : ping >= 500 ? 'orange' : 'white' }"
					style="margin-right: 50px">
					Пинг: {{ ping }} мс
				</div>
				<div v-if="role !== 'admin' && role !== 'head_admin'" style="margin-right: 50px">
					Баланс: {{ balance }}₽ (ТЕСТОВЫЙ ПОДСЧЁТ)
				</div>
				<div v-if="role !== 'admin' && role !== 'head_admin'" style="margin-right: 50px">
					<a-switch checkedChildren="&#10004;" unCheckedChildren="&#10060;" style="margin-right: 10px"
						class="flow_options__checked_btn" @change="changeReceiveCalls(!receiveCalls)"
						:checked="receiveCalls" />
					Принимать входящие
				</div>
				<div v-if="role !== 'admin' && role !== 'head_admin'" style="margin-right: 50px">
					<a-switch checkedChildren="&#10004;" unCheckedChildren="&#10060;" style="margin-right: 10px"
						class="flow_options__checked_btn" @change="changeOnline(!online)" :checked="online" />
					Онлайн
				</div>
				<div :style="{ paddingTop: '4px' }">
					<!-- <a-popover trigger="click" placement="bottom">
						<template #content>
							<p>Content</p>
							<p>Content</p>
						</template>
						<template #title>
							<span>Title</span>
						</template>
						<a-badge :count="0">
							<BellOutlined :style="{ color: '#ffffff', fontSize: '22px' }" />
						</a-badge>
					</a-popover> -->
				</div>
				<a-popover trigger="click" placement="bottom">
					<template #content>
						<a-button @click="logoutUser()">Выход</a-button>
					</template>
					<div class="header_left_avatar">
						<a-avatar size="small" style="color: #f56a00; background-color: #fde3cf">{{
							iconName
						}}
						</a-avatar>
						<span>{{ userName }}</span>
					</div>
				</a-popover>
			</div>

		</a-layout-header>

		<div :style="{ marginTop: '64px' }">
			<slot></slot>
		</div>

	</a-layout>
</template>

<script>
// import { BellOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from "vuex";

export default {
	name: "HeaderLayout",
	components: {
		// BellOutlined
	},
	data() {
		return {
			balance: 0,
			ping: this.$store.state.call.ping
		}
	},
	methods: {
		...mapActions({
			logoutUser: 'user/logoutUser',
			changeReceiveCalls: 'user/oppositeReceiveCalls',
			changeOnline: 'user/changeOnline'
		}),
		balanceLoad() {
			this.$http.post("/operators/balance",
				{
				},
				(data) => {
					this.balance = data

				})
		},
	},
	computed: {
		...mapGetters({ userName: 'user/userName', receiveCalls: 'user/getReceiveCallsUser', online: 'user/getOnline', role: 'user/getRole' }),
		iconName() {
			if (typeof this.userName === 'string') {
				return this.userName[0]
			}
			return ''
		},
	},
	mounted() {
		let sum_ping = 0
		setInterval(() => {
			if (this.$store.state.user.user.role === 'operator') {
				this.ping = this.$store.state.call.ping
				sum_ping = sum_ping + this.ping
			}
		}, 1000)
		setInterval(() => {
			if (this.$store.state.user.user.role === 'operator') {
				if (sum_ping < 5) {
					location.reload()
				}
				sum_ping = 0
			}
		}, 1000 * 20)
		this.balanceLoad()
		setInterval(() => {
			this.balanceLoad()
		}, 1000 * 60 * 5)

	}
}
</script>

<style scoped>
#components-layout-demo-top-side-2 .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
	float: right;
	margin: 16px 0 16px 24px;
}

.site-layout-background {
	background: #fff;
}

.header {
	display: flex;
	justify-content: space-between;
	/*padding-right: 20px;*/
}

.header_left {
	color: #FFFFFF;
	display: flex;
	align-items: center;
}

.header_left_avatar {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.header_left_avatar>span {
	margin-left: 8px;
}

.logo {
	font-weight: 700;
	font-size: 18px;
	color: #FFFFFF;
}
</style>