<template>

	<a-form
			ref="formRef"
			name="dynamic_form_item"
	>
		<template v-if="typeComponent === 'field'">
			<a-form-item
					v-for="(domain, index) in domains"
					:key="index"
					:name="['domains', index, 'value']"
			>
				<template style="display: flex">
					<a-input
							v-model:value="domain.value"
							placeholder="Укажите поле для выбора"

							@blur="changeVal()"
					/>
					<MinusCircleOutlined
							style="margin-left: 8px"
							v-if="domains.length > 1"
							class="dynamic-delete-button"
							:disabled="domains.length === 1"
							@click="removeDomain(domain)"
					/>
				</template>
			</a-form-item>
		</template>

		<a-form-item>
			<a-form-item class="dynamic_form_btn">
				<a-button type="dashed" @click="addDomain">
					<PlusOutlined/>
					Добавить поле
				</a-button>
			</a-form-item>
		</a-form-item>
	</a-form>

</template>

<script>
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons-vue';

export default {
	name: "DynamicFormItem",
	props: ['options', 'typeComponent', 'typeFields'],
	components: {
		MinusCircleOutlined,
		PlusOutlined,
	},
	setup() {

	},
	data() {
		return {
			domains: [],
			// optionsFieldsForOffer: []
		}
	},
	methods: {
		changeVal() {
			this.$emit('change', [...this.domains])
		},
		addDomain() {
			if (this.domains.length === 0 || this.domains[this.domains.length - 1].value) {
				this.domains.push({
					value: ''
				});
			}
		},
		removeDomain(item) {
			let index = this.domains.indexOf(item);
			if (index !== -1) {
				this.domains.splice(index, 1);
			}
		}
	},
	mounted() {
		this.domains = this.options
	},
	watch: {
		typeFields() {
			this.domains = this.options
		}
	}
}
</script>

<style scoped>
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.dynamic_form_btn {
    display: flex;
    justify-content: center;
}

</style>