export default {
	methods: {
		changInput(){
			this.$emit('changInput', this.valueInput, this.id)
		}
	},
	computed: {
		styleDisplay() {
			return this.display === 'error' ? {border: '1px solid #ff4d4f'} : this.display === 'success' ? {border: '1px solid #6aff39'} : {}
		}
	},
}