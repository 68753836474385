<template>
	<div v-if="!visible || styleView || showStats" class="action_list_phone">
		<!-- <div v-for="(action, key) of actionList" :key="key">
			<div class="action_list_phone_card">
				<div>
					{{ action.value }}
				</div>
				<div v-if="!showStats">
					<a @click="changeAction(key)">Правка</a>
					<a-divider type="vertical" />
					<a-popconfirm title="Удалить?" placement="topLeft" ok-text="Да" cancel-text="Нет"
						@confirm="deleteAction(key)">
						<a>Удалить</a>
					</a-popconfirm>
				</div>
			</div>
		</div> -->
	</div>
	<div v-if="visible || styleView" class="add_action_list">
		<div>
			<div>Действие</div>
			<a-select ref="select" v-model:value="addAction.value" style="width: 200px" :options="addListAction">
			</a-select>
		</div>
		<div>
			<div>Через дней</div>
			<a-input-number :disabled="true" :min="1" :max="100000" v-model:value="addAction.day" />
		</div>
		<div class="add_action_list__btn">
			<a-button :disabled="true" @click="closeAddAction()" type="link">Отмена</a-button>
			<a-button :disabled="true" type="primary" @click="addActionList()">Сохранить</a-button>
		</div>
	</div>
</template>

<script>
export default {
	name: "ActionPhoneList",
	props: ['visible', 'actionList', 'addListAction', 'styleView', 'showStats'],
	data() {
		return {
			addAction: {
				value: '',
				day: 0
			},
			actionListCopy: [],
			activeAction: 0,
		}
	},
	methods: {
		closeAddAction() {
			this.$emit('close')
			this.addAction = {
				value: '',
				day: 0
			}
			this.activeAction = this.actionListCopy.length
		},
		openAddAction() {
			this.$emit('open')
		},

		saveAction() {
			this.$emit('save', [...this.actionListCopy])
			this.closeAddAction()
		},
		addActionList() {
			if (this.activeAction > this.actionListCopy.length - 1) {
				this.actionListCopy.push(this.addAction)
			} else {
				this.actionListCopy[this.activeAction] = this.addAction
			}
			this.activeAction = this.actionListCopy.length
			this.saveAction()
		},
		changeAction(idx) {
			this.addAction = { ...this.actionListCopy[idx] }
			this.activeAction = idx
			this.openAddAction()
		},
		deleteAction(idx) {


			this.actionListCopy.splice(idx, 1);
			this.saveAction()
		},

	},
	mounted() {
		this.actionListCopy = this.actionList
		this.activeAction = this.actionList.length
	},
	watch: {
		actionList(newVal) {
			if (Array.isArray(newVal)) {
				this.actionListCopy = newVal
				this.activeAction = newVal.length
			}
		}
	}
}
</script>

<style scoped>
.action_list_phone {
	background: #FFFFFF;
	border-radius: 2px;
	margin-top: 15px;
}

.action_list_phone_card {
	display: flex;
	justify-content: space-between;
	padding: 12px 10px 12px 15px;
	box-shadow: inset 0 -1px 0 #F0F0F0;
}

.add_action_list {
	padding: 25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.add_action_list__btn {
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}
</style>