<template>
	<TitlePage title="Архив сценариев"  :border="true">
		
	
	</TitlePage>
	<a-table :columns="scenario.columns" :data-source="scenario.data" :loading="loading.scenario" :pagination="pagination" size="middle">
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'phone'">
				{{ record.phone }}
			</template>
			<template v-else-if="column.key === 'operation'">
				<span>
					
					<!-- <a v-if="record.active === 0" @click="returnScenario(record.id)">Вернуть</a> -->
					<a-popconfirm
							title="Вернуть сценарий?"
							@confirm="returnScenario(record.id)"
							>
							<a>Вернуть</a>
						</a-popconfirm>
					<a-divider type="vertical"/>
					
					<a-popconfirm
							title="Удалить сценарий?"
							@confirm="deleteScenario(record.id)"
					>
						<a>Удалить</a>
					</a-popconfirm>
				</span>
			</template>
		</template>
	</a-table>
	
</template>

<script>
import TitlePage from "@/components/TitlePage";


// import { notification } from 'ant-design-vue';


export default {
	name: "ScenarioAdmin",
	components: {
		TitlePage,
		
	},
	
	data() {
		
		this.$http.post("/scenarios/foreverDelete",
			{},
			(data) => {
				this.scenario.data = data
				this.loading.scenario = false
				//console.log(this.scenario.data, ' this.scenario.data this.scenario.data this.scenario.data')
			})
		
		return {
			pagination: {
				pageSize: 100
			},
			changeIdx: -1,
			popup: {
				newScenario: false
			},
			loading: {
				scenario: true
			},
			// validPopup: {
			// 	scenario: false
			// },
			newScenario: {
				name: '',
				type: '',
				category:'',
				priority:'',
				options: {
					scenarioRobot: '',
          baseRobot: '',
					scriptCall: '',
					operator: [],
					offers: [],
          region: [],
          call_start:'9',
          call_end:'18'
          // api:''
				},
				base: [],
			},
			filter: {
				category: {
					value: [],
					options: [
						{value: '1', title: '112'},
						{value: 'w', title: '2'},
						{value: '3', title: '3'},
						{value: '4', title: '4'},
						{value: '5', title: '5'},
					]
				}
			},
			
			scenario: {
				columns: [
					{
						title: 'Название',
						dataIndex: 'name',
						key: 'name'
					},
				
					{
						title: 'Действия',
						dataIndex: 'operation',
						key: 'operation',
						width: 300
					}
				],
				data: []
			},
    
		}
	},
	methods: {
        returnScenario(idScenario){
            this.$http.post("/scenarios/return",
				{
					id: idScenario
				},
				(res) => {
					if (res === 'Done') {
						this.scenario.data.splice(this.scenario.data.findIndex((scenario) => scenario.id === idScenario), 1)
						
					}
				})
        },

		deleteScenario(idScenario) {
			//console.log(idScenario)
			this.$http.post("/scenarios/fullDelete",
				{
					id: idScenario
				},
				(res) => {
					if (res === 'Done') {
						this.scenario.data.splice(this.scenario.data.findIndex((scenario) => scenario.id === idScenario), 1)
					}
				})
		},
		
    funLoadingScenarios() {
      this.$http.post("/scenarios/foreverDelete",
          {},
          (data) => {
            this.scenario.data = data
						
            this.scenario_stats_counter()
						if (this.filter.category.value.length>0) {
							let newScens = []
							this.scenario.data.forEach((scenario)=>{
								this.filter.category.value.forEach((item)=>{
									if (item===scenario.category) {
										newScens.push(scenario)
									}
								})
							})
							this.scenario.data = newScens
						}
          })
    },
	scenario_stats_counter() {
      let send = 0
			let sum = 0
      this.scenario.data.forEach((scenario)=> {
				sum = sum + scenario.call_status
        scenario.options.offers.forEach((item)=> {
          send+=item.send
        })
      })
      let limit = 0
      this.scenario.data.forEach((scenario)=> {
        scenario.options.offers.forEach((item)=> {
          limit+=item.limit
        })
      })
      let regions = ''
      this.scenario.data.filter(item => item.active === 1).forEach((scenario)=> {
        if (scenario.options.region !== '') {
          if (regions === '') {
            regions = regions+scenario.options.region
          }
          else {
            regions = regions + ','+scenario.options.region
          }
        }
      })
			let regions_arr = regions.split(',')
			let uniqueRegions = [...new Set(regions_arr)];
			let final_regions = []
			let final_regions_text = ''
			uniqueRegions.forEach((region)=> {
				if (region !== '') {
					final_regions.push(region)
					if (final_regions_text === '') {
						final_regions_text = final_regions_text + region
					}
					else {
						final_regions_text = final_regions_text + ','+ region
					}

				}
			})
      if (final_regions.length===0) {
				final_regions_text = 'Нет активных регионов'
			}
      this.scenario_stats="Сценарии: "+this.scenario.data.filter(item => item.active === 1).length+"/"+this.scenario.data.length+", План: "+send+"/"+limit+", Активных регионов: "+final_regions.length+", Обзвон: "+sum+" звонков/мин"
			this.scenario_stats_regions="Активные регионы: "+final_regions_text

		},
		changeFilter(filter) {
			this.filter = filter
			this.funLoadingScenarios()
		},
	},
	mounted() {
    this.funLoadingScenarios()
    this.$store.dispatch('offers/loadingOffers')
    setInterval(() => {
      this.funLoadingScenarios()
    }, 1000 * 60)
	},
}
</script>

<style scoped>
.startall_button{
  display:flex;
  max-width: 8%;
  min-height: 2%;
  margin-left: auto;
  margin-right: 25%;
  margin-bottom: 20px;
}
.startall_button_item {
  flex: 1 1 auto;
  margin: 0 5px;
	max-width: 11%;
}
</style>