<template>
	<TitlePage title="Сценарии" :subtitle="scenario_stats" :border="true">
		<template v-slot:title_right>
			<a-button class='startall_button_item' type="primary" @click="start_all">
				Запустить всё
			</a-button>
			<a-button class='startall_button_item' type="primary" @click="stop_all">
				Остановить всё
			</a-button>
			<a-button class='startall_button_item' type="primary" @click="null_all">
				Обнулить лимиты
			</a-button>
			<a-button class='startall_button_item' type="primary" @click="popup.newScenario = true">
				Создать сценарий
			</a-button>
		</template>
		<FiltersHeader :filter-value="filter" @changeFilter="changeFilter" :view-input-filter="{
			category: true
		}" />
	</TitlePage>
	<a-table :columns="scenario.columns" :data-source="scenario.data" :loading="loading.scenario" :pagination="pagination"
		size="middle">
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'phone'">
				{{ record.phone }}
			</template>
			<template v-else-if="column.key === 'priority'">
				<span>
					<a-tag v-if="record.priority === priorities[0].value" color="red">Очень низкий</a-tag>
					<a-tag v-if="record.priority === priorities[1].value" color="orange">Низкий</a-tag>
					<a-tag v-if="record.priority === priorities[2].value" color="green">Стандартный</a-tag>
					<a-tag v-if="record.priority === priorities[3].value" color="cyan">Высокий</a-tag>
					<a-tag v-if="record.priority === priorities[4].value" color="purple">Очень высокий</a-tag>
				</span>
			</template>
			<template v-else-if="column.key === 'active'">
				<span>
					<a-tag :color="record.active ? 'green' : 'red'">
						{{ record.active ? 'Запущен' : 'Остановлен' }}
					</a-tag>
				</span>
			</template>
			<template v-else-if="column.key === 'call_status'">
				<span>
					{{ record.call_status }}
				</span>
			</template>
			<template v-else-if="column.key === 'numbers_amount'">
				<span>
					{{ record.numbers_amount }}
				</span>
			</template>
			<template v-else-if="column.key === 'base'">
				<span>
					<a-tag v-for="base in record.base" :key="base" :color="'green'">
						{{ base.toLowerCase().substring(0, 10) }}...
					</a-tag>
				</span>
			</template>
			<template v-else-if="column.key === 'operator'">
				<span>
					{{ record.options.operator.map(item => allOperators.find(v => v.id === item).name).join(', ') }}
				</span>
			</template>
			<template v-else-if="column.key === 'offers_send'">
				{{ offersLimitTable(record.options.offers) }}
			</template>

			<template v-else-if="column.key === 'offers'">
				<a-dropdown>
					<template #overlay>
						<a-menu>
							<a-menu-item v-for="(offer, id) of record.options.offers" :key="id">
								{{ allOffers.find(item => +item.key === +offer.id).name }} - {{ offer.limit }}
							</a-menu-item>
						</a-menu>
					</template>
					<a>
						{{ record.options.offers.length }}
						<down-outlined />
					</a>
				</a-dropdown>
			</template>
			<template v-else-if="column.key === 'operation'">
				<span>
					<a v-if="record.active === 1" @click="startScenario(record.id)">Остановить</a>
					<a v-if="record.active === 0" @click="startScenario(record.id)">Запустить</a>
					<a-divider type="vertical" />
					<a @click="changeScenario(record.id)">Редактировать</a>
					<a-divider type="vertical" />
					<a-popconfirm title="Удалить сценарий?" @confirm="deleteScenario(record.id)">
						<a>Удалить</a>
					</a-popconfirm>
				</span>
			</template>
		</template>
	</a-table>
	<p>
		<font color="#696969">{{ scenario_stats_regions }}</font>
	</p>


	<BasicModal title="Сценарий" :visible="popup.newScenario" @closePopup="closePopup()" :btn-ok="'Сохранить'"
		:callback="callbackPopup">
		<a-form layout="vertical">
			<a-form-item label="Название">
				<a-input v-model:value="newScenario.name" />
			</a-form-item>
			<a-form-item label="Тип сценария">
				<a-select v-model:value="newScenario.type" :options="typeScenario.map(v => ({ value: v }))"></a-select>
			</a-form-item>
			<a-form-item label="Категория">
				<a-select v-model:value="newScenario.category"
					:options="filter.category.options.map(v => ({ value: v.value, label: v.title }))"></a-select>
			</a-form-item>
			<a-form-item label="Приоритет">
				<a-select v-model:value="newScenario.priority" :options="priorities"></a-select>
			</a-form-item>
			<!--			<a-form-item v-if="newScenario.type === typeScenario[0]" label="">-->

			<!--			</a-form-item>-->
			<a-form-item v-if="newScenario.type !== typeScenario[2]" label="Выбор оператора">
				<a-select v-model:value="newScenario.options.operator"
					:options="allOperators.map(item => ({ value: item.id, label: item.name }))" mode="multiple"></a-select>
				<a-button @click="selectAllOperators" style="margin-top:10px">Выбрать всех операторов</a-button>
			</a-form-item>
			<!--			<a-form-item v-if="newScenario.type === typeScenario[1]" label="Выбор сценария робота">-->
			<!--				<a-select-->
			<!--						v-model:value="newScenario.options.scenarioRobot"-->
			<!--						:options="scenarioRobot.map(item => ({ value: item.id, label: item.name }))"-->
			<!--				></a-select>-->
			<!--			</a-form-item>-->
			<!--      <a-form-item v-if="newScenario.type === typeScenario[1]" label="Выбор базы в CRMCalls">-->
			<!--        <a-select-->
			<!--            v-model:value="newScenario.options.baseRobot"-->
			<!--            :options="baseRobot.map(item => ({ value: item.id, label: item.name }))"-->
			<!--        ></a-select>-->
			<!--      </a-form-item>-->
			<!--      <a-form-item v-if="newScenario.type === typeScenario[1]" label="Api для заливки базы в CRMCalls">-->
			<!--        <a-input-->
			<!--            v-model:value="newScenario.options.api"-->
			<!--        ></a-input>-->
			<!--      </a-form-item>-->
			<a-form-item v-if="newScenario.type !== typeScenario[2]"
				label="Выбор скрипта звонка (текст для оператора во время звонка)">
				<a-select v-model:value="newScenario.options.scriptCall"
					:options="scriptCall.map(v => ({ value: v }))"></a-select>
			</a-form-item>
			<a-form-item label="Подключение базы номеров">
				<a-select mode="multiple" v-model:value="newScenario.base"
					:options="baseLids.map(v => ({ value: v }))"></a-select>
			</a-form-item>
			<a-form-item v-if="newScenario.type === typeScenario[1] || newScenario.type === typeScenario[2]"
				label="Ссылка на сценарий робота">
				<a-input v-model:value="newScenario.options.scenarioRobot" />
			</a-form-item>
			<a-form-item label="Регион">
				<a-select v-model:value="newScenario.options.region" mode="multiple"
					:options="regions.map(v => ({ value: v }))"></a-select>
			</a-form-item>
			<a-form-item>
				<!--				<dunamicFormOffers/>-->
				<div
					style="display: flex; margin-bottom: 8px; width: 100%; gap: 15px; align-items: center; padding-right: 23px;">
					<span style="width: 106%">Время начала обзвона</span>
					<span style="width: 94%">Время конца обзвона</span>
				</div>
				<div style="display: flex; margin-bottom: 8px; width: 100%; gap: 15px; align-items: center">
					<a-input-number v-model:value="newScenario.options.call_start" style="width: 100%" :min="0" :max="24" />
					<a-input-number v-model:value="newScenario.options.call_end" style="width: 100%" :min="0" :max="24" />
				</div>
			</a-form-item>
			<a-form-item>
				<!--				<dunamicFormOffers/>-->
				<div
					style="display: flex; margin-bottom: 8px; width: 100%; gap: 15px; align-items: center; padding-right: 23px;">
					<span style="width: 200%">Офферы</span>
					<span style="width: 100%">Лимиты</span>
					<span style="width: 100%">Отправлено</span>
				</div>
				<div v-for="(offer, index) in newScenario.options.offers" :key="index"
					style="display: flex; margin-bottom: 8px; width: 100%; gap: 15px; align-items: center">
					<a-select v-model:value="offer.id" style="width: 200%"
						:options="allOffers.map(item => ({ label: item.name, value: item.key }))"></a-select>
					<a-input-number v-model:value="offer.limit" style="width: 100%" placeholder="Установите лимит" />
					<a-input-number v-model:value="offer.send" style="width: 100%" placeholder="Отправленно" />

					<MinusCircleOutlined @click="removeUser(offer)" />
				</div>
				<a-form-item>
					<a-button type="dashed" block @click="addUser">
						<PlusOutlined />
						Добавить Оффер
					</a-button>
				</a-form-item>
			</a-form-item>
		</a-form>
	</BasicModal>
	<!--	<pre>{{ scenario.data }}</pre>-->
</template>

<script>
import TitlePage from "@/components/TitlePage";
import BasicModal from "@/components/modal/BasicModal";
import FiltersHeader from "@/components/ui/FiltersHeader";
import { notification } from 'ant-design-vue';

import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons-vue';

import { mapGetters } from "vuex";

export default {
	name: "ScenarioAdmin",
	components: {
		TitlePage,
		BasicModal,
		FiltersHeader,
		// InboxOutlined,
		MinusCircleOutlined,
		PlusOutlined,
		DownOutlined,
	},
	computed: {
		...mapGetters({ allOffers: 'offers/getOffers' }),
	},
	data() {
		this.$http.post("/offers/offerstypes",
			{},
			(data) => {
				let options = []
				data.forEach((item) => {
					options.push({
						value: item.key,
						title: item.value
					})
				})
				this.filter.category.options = options
			})
		// this.$http.noTokenPost("https://dev.crmcalls.ru/api/script/list",
		// 	{
		// 		"token": "4OxQjDV6oNaUIHzP"
		// 	},
		// 	(data) => {
		// 	if (data!=='error') {
		// 		this.scenarioRobotData = data
		// 		data.forEach((item) => {
		// 			this.scenarioRobot.push({name:item.name,id:item.guid})
		// 		})
		// 	}
		// 	})
		// this.$http.noTokenPost("https://dev.crmcalls.ru/api/base/shortlist",
		//     {
		//       "token": "4OxQjDV6oNaUIHzP"
		//     },
		//     (data) => {
		// 			if (data!=='error') {
		// 				this.baseRobotData = data
		// 				data.forEach((item) => {
		// 					this.baseRobot.push({name:item.name,id:item.guid})
		// 				})
		// 			}
		//     })
		this.$http.post("/operators/all",
			{},
			(data) => {
				this.allOperators = data
			})
		this.$http.post("/scenarios/all",
			{},
			(data) => {

				this.scenario.data = data
				this.loading.scenario = false
				//console.log(this.scenario.data, ' this.scenario.data this.scenario.data this.scenario.data')
			})
		this.$http.post("/bases/all",
			{},
			(data) => {
				this.baseLids = data.baseNames
			})
		this.$http.post("/scenarios/types",
			{},
			(data) => {
				this.typeScenario = data
			})
		this.$http.post("/scenarios/texts",
			{},
			(data) => {
				this.scriptCall = data.textNames
			})
		this.$http.post("/bases/regions",
			{
			},
			(data) => {
				this.regions = data
			})
		return {
			calls_data: '',
			pagination: {
				pageSize: 100
			},
			changeIdx: -1,
			popup: {
				newScenario: false
			},
			loading: {
				scenario: true,
				calls_data: true
			},
			// validPopup: {
			// 	scenario: false
			// },
			newScenario: {
				name: '',
				type: '',
				category: '',
				priority: '',
				options: {
					scenarioRobot: '',
					baseRobot: '',
					scriptCall: '',
					operator: [],
					offers: [],
					region: [],
					call_start: '9',
					call_end: '18'
					// api:''
				},
				base: [],
			},
			filter: {
				category: {
					value: [],
					options: [
						{ value: '1', title: '112' },
						{ value: 'w', title: '2' },
						{ value: '3', title: '3' },
						{ value: '4', title: '4' },
						{ value: '5', title: '5' },
					]
				}
			},
			priorities: [
				{ value: 0.5, label: 'Очень низкий' },
				{ value: 0.75, label: 'Низкий' },
				{ value: 1, label: 'Стандартный' },
				{ value: 2, label: 'Высокий' },
				{ value: 3, label: 'Очень высокий' },
			],
			scenario_stats: 'Загрузка статистики. . .',
			scenario_stats_regions: 'Загрузка активных регионов. . .',
			scenario: {
				columns: [
					{
						title: 'Название',
						dataIndex: 'name',
						key: 'name'
					},
					// {
					// 	title: 'Дата создания',
					// 	dataIndex: 'date',
					// 	key: 'date'
					// },
					{
						title: 'Приоритет',
						dataIndex: 'priority',
						key: 'priority'
					},
					{
						title: 'Состояние',
						dataIndex: 'active',
						key: 'active',
					},
					//   {
					//     title: 'Звонков/мин',
					//     dataIndex: 'call_status',
					//     key: 'call_status'
					//   },
					{
						title: 'Остаток номеров',
						dataIndex: 'numbers_amount',
						key: 'numbers_amount'
					},
					{
						title: 'База',
						dataIndex: 'base',
						key: 'base'
					},
					{
						title: 'Кол-во операторов',
						dataIndex: 'operatorCountInfo',
						key: 'operatorCountInfo',
					},
					{
						title: 'Статус',
						dataIndex: 'offers',
						key: 'offers_send'
					},
					{
						title: 'Офферы',
						dataIndex: 'offers',
						key: 'offers'
					},
					{
						title: 'Действия',
						dataIndex: 'operation',
						key: 'operation',
						width: 300
					}
				],
				data: []
			},
			regions: ['Австралия', 'Армения'],
			typeScenario: ['На оператора', 'Через робота'],
			scenarioRobot: [],
			scenarioRobotData: [],
			baseRobot: [],
			baseRobotData: [],
			baseLids: ['подводная-база', 'холодная', 'теплая'],
			scriptCall: ['Название скрипта', 'Скрипт для холодных', 'Скрипт для теплых'],
			allOperators: [
				{ name: 'qwe', id: '123' },
				{ name: 'qrty', id: '1223' },
				{ name: 'tyu', id: '1523' },
			]
		}
	},
	methods: {
		selectAllOperators() {
			this.newScenario.options.operator = this.allOperators.map(item => item.id);
		},
		scenarioStopped(scenario_name) {
			const audio = new Audio('https://storage.yandexcloud.net/ivrrecord/notification_scenario.mp3')
			audio.play()
			notification.open({
				message: 'Сценарий остановлен',
				description: 'Cценарий ' + scenario_name + ' отключен из-за отсутствия номеров',
				duration: 0,
				placement: 'bottomRight'
			});
		},
		scenarioActiveNow() {
			notification.open({
				message: 'Ошибка',
				description: 'Нельзя редактировать активный сценарий. Пожалуйста, выключите сценарий для редактирования.',
				duration: 3,
				placement: 'bottomRight'
			});
		},
		scenarioEmpty() {
			notification.open({
				message: 'Ошибка',
				description: 'Нельзя активировать сценарий, если к нему не прикреплен оффер или лимиты по ним закончились',
				duration: 3,
				placement: 'bottomRight'
			});
		},
		scenarioNotPhones() {
			notification.open({
				message: 'Ошибка',
				description: 'Нет доступных номеров для обзвона по этому сценарию',
				duration: 3,
				placement: 'bottomRight'
			});
		},

		offersLimitTable(offer) {
			let sumLimit = offer.reduce((sum, val) => sum + +val.limit, 0)
			let sumSend = offer.reduce((sum, val) => sum + +val.send, 0)
			return `${sumSend} / ${sumLimit}`
		},
		startScenario(id) {
			this.loading.scenario = true
			this.$http.post("/scenarios/startstop",
				{
					scenario: this.scenario.data.find((scenario) => scenario.id === id)
				},
				(res) => {
					if (res === 1 || res === 0) {
						this.scenario.data.forEach((scenario,) => {
							if (scenario.id == id) {
								scenario.active = res
							}
						})
					} if (res === "Error") {
						this.scenarioEmpty()
					}
					if (res === "empty") {
						this.scenarioNotPhones()
					}
					this.loading.scenario = false
				})
			this.scenario_stats_counter()
		},
		closePopup() {
			// console.log('закрыть попап')
			this.newScenario.name = ''
			this.newScenario.type = ''
			this.newScenario.category = ''
			this.newScenario.priority = ''
			this.newScenario.options.scenarioRobot = ''
			this.newScenario.options.baseRobot = ''
			this.newScenario.options.scriptCall = ''
			this.newScenario.options.operator = []
			this.newScenario.options.offers = []
			this.newScenario.options.region = []
			this.newScenario.options.call_start = '9'
			this.newScenario.options.call_end = '18'
			// this.newScenario.options.api = ''
			this.newScenario.base = []
			this.changeIdx = -1
			this.popup.newScenario = false
		},
		deleteScenario(idScenario) {
			//console.log(idScenario)
			this.$http.post("/scenarios/delete",
				{
					id: idScenario
				},
				(res) => {
					if (res === 'Done') {
						this.scenario.data.splice(this.scenario.data.findIndex((scenario) => scenario.id === idScenario), 1)
					}
				})
		},
		changeSelectItem(val) {
			this.newScenario.base = val
		},
		changeScenario(id) {
			this.changeIdx = this.scenario.data.findIndex(field => field.id === id)
			if (this.scenario.data[this.changeIdx].active == 1) {
				this.scenarioActiveNow()
			}
			else {
				let field = this.scenario.data[this.changeIdx]

				this.newScenario.name = field.name
				this.newScenario.type = field.type
				this.newScenario.category = field.category
				this.newScenario.priority = field.priority
				this.newScenario.options.scenarioRobot = field.options.scenarioRobot
				this.newScenario.options.baseRobot = field.options.baseRobot
				this.newScenario.options.scriptCall = field.options.scriptCall
				this.newScenario.options.operator = field.options.operator
				this.newScenario.options.offers = field.options.offers
				this.newScenario.options.region = field.options.region
				this.newScenario.options.call_start = field.options.call_start
				this.newScenario.options.call_end = field.options.call_end
				// this.newScenario.options.api = field.options.api
				this.newScenario.base = field.base

				this.popup.newScenario = true
			}
		},
		callbackPopup() {
			// console.log(this.changeIdx)
			// console.log(this.newScenario)
			// console.log('callbackPopup')
			if (this.newScenario.name && this.newScenario.type && this.newScenario.base) {
				let dataChange = {
					id: !(this.changeIdx >= 0) ? Date.now() : this.scenario.data[this.changeIdx].id,
					date: !(this.changeIdx >= 0) ? new Date().toLocaleDateString('ru-RU', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					}) : this.scenario.data[this.changeIdx].date,
					name: this.newScenario.name,
					type: this.newScenario.type,
					category: this.newScenario.category,
					priority: this.newScenario.priority,
					operator: this.newScenario.options.operator,
					options: {
						scenarioRobot: this.newScenario.options.scenarioRobot,
						baseRobot: this.newScenario.options.baseRobot,
						scriptCall: this.newScenario.options.scriptCall,
						operator: this.newScenario.options.operator,
						offers: this.newScenario.options.offers,
						region: this.newScenario.options.region,
						call_start: this.newScenario.options.call_start,
						call_end: this.newScenario.options.call_end,
						// api: this.newScenario.options.api
					},
					active: 0,
					base: [...this.newScenario.base]
				}

				if (this.newScenario.name && this.newScenario.type && !(this.changeIdx >= 0)) {
					this.scenario.data.push(dataChange)
					// тут изменяем
				} else if (this.newScenario.name && this.newScenario.type && this.changeIdx >= -1) {
					this.scenario.data[this.changeIdx] = dataChange
					// тут сохраняем новый
				}
				this.changeIdx = -1
				// let api
				// this.scenarioRobotData.forEach((item) => {
				// 	if (item.name == dataChange.options.scenarioRobot) {
				// 		api = item.link
				// 	}
				// })
				this.$http.post("/scenarios/insert",
					{
						data: dataChange,
						//api: api
					},
					() => {
					})
				this.closePopup()
			}
		},
		removeUser(item) {
			let index = this.newScenario.options.offers.indexOf(item);
			if (index !== -1) {
				this.newScenario.options.offers.splice(index, 1);
			}
		},
		addUser() {
			this.newScenario.options.offers.push({
				id: '',
				limit: 10,
				send: 0,
			})
		},
		funLoadingScenarios() {
			this.$http.post("/scenarios/all",
				{},
				(data) => {

					this.scenario.data = data
					this.scenario.data.forEach((scenario) => {
						//console.log(scenario)
						if (scenario.stopped === 1) {
							this.scenarioStopped(scenario.name)
							this.$http.post("/scenarios/notification",
								{
									id: scenario.id
								},
								() => { })
						}
					})
					this.scenario_stats_counter()
					if (this.filter.category.value.length > 0) {
						let newScens = []
						this.scenario.data.forEach((scenario) => {
							this.filter.category.value.forEach((item) => {
								if (item === scenario.category) {
									newScens.push(scenario)
								}
							})
						})
						this.scenario.data = newScens
					}
				})
		},
		start_all() {
			this.$http.post("/scenarios/startall",
				{},
				() => {
					this.funLoadingScenarios()
				})
		},
		stop_all() {
			this.$http.post("/scenarios/stopall",
				{},
				() => {
					this.funLoadingScenarios()
				})
		},
		null_all() {
			this.$http.post("/scenarios/nullall",
				{},
				() => {
					this.funLoadingScenarios()
				})
		},
		scenario_stats_counter() {

			let send = 0
			let sum = 0
			this.scenario.data.forEach((scenario) => {
				sum = sum + scenario.call_status
				scenario.options.offers.forEach((item) => {
					send += item.send
				})
			})
			let limit = 0
			this.scenario.data.forEach((scenario) => {
				scenario.options.offers.forEach((item) => {
					limit += item.limit
				})
			})
			let regions = ''
			this.scenario.data.filter(item => item.active === 1).forEach((scenario) => {
				if (scenario.options.region !== '') {
					if (regions === '') {
						regions = regions + scenario.options.region
					}
					else {
						regions = regions + ',' + scenario.options.region
					}
				}
			})
			let regions_arr = regions.split(',')
			let uniqueRegions = [...new Set(regions_arr)];
			let final_regions = []
			let final_regions_text = ''
			uniqueRegions.forEach((region) => {
				if (region !== '') {
					final_regions.push(region)
					if (final_regions_text === '') {
						final_regions_text = final_regions_text + region
					}
					else {
						final_regions_text = final_regions_text + ',' + region
					}

				}
			})
			if (final_regions.length === 0) {
				final_regions_text = 'Нет активных регионов'
			}
			this.scenario_stats = "Сценарии: " + this.scenario.data.filter(item => item.active === 1).length + "/" + this.scenario.data.length + ", План: " + send + "/" + limit + ", Очередь звонков: " + this.calls_data + ", Обзвон: " + sum + " звонков/мин"
			this.scenario_stats_regions = "Активные регионы: " + final_regions_text

		},
		changeFilter(filter) {
			this.filter = filter
			this.funLoadingScenarios()
		},
		callsQueue() {
			this.$http.post("/scenarios/callsQueue", {},
				(data) => {
					if (data) {
						this.calls_data = data;

					} else if (!data) {
						this.calls_data = 0
					}
					this.scenario_stats_counter(this.calls_data);
				}
			)
		}
	},
	mounted() {
		this.funLoadingScenarios()
		this.$store.dispatch('offers/loadingOffers')
		this.callsQueue()
		setInterval(() => {
			this.callsQueue()
			this.funLoadingScenarios()
		}, 1000 * 60)
	},
}
</script>

<style scoped>
.startall_button {
	display: flex;
	max-width: 8%;
	min-height: 2%;
	margin-left: auto;
	margin-right: 25%;
	margin-bottom: 20px;
}

.startall_button_item {
	flex: 1 1 auto;
	margin: 0 5px;
	max-width: 11%;
}
</style>